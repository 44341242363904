interface CppLimits {
	min?: number
	max?: number
}

function isCppOutOfLimits(cpp: number, cppLimits: CppLimits): boolean {
	if (cppLimits && typeof cppLimits.min === 'number' && typeof cppLimits.max === 'number') {
		return cpp < cppLimits.min || cpp > cppLimits.max
	}

	return false
}

export { isCppOutOfLimits }
