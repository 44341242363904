import { AppHeaderConfigPayload, useAppHeader } from 'admin-portal-shared-services'
import { useEffect } from 'react'
import { SetAppHeaderOptionalButtonsProps } from './useSetAppHeaderOptionalButtons.types'

function useSetAppHeaderOptionalButtons({
	primaryButton,
	secondaryButton,
	hasFeatureToggle,
}: SetAppHeaderOptionalButtonsProps): void {
	const [, setAppHeaderConfig] = useAppHeader()

	useEffect(() => {
		if (hasFeatureToggle) {
			const newAppHeaderConfig: AppHeaderConfigPayload = {
				optionalButtons: [primaryButton, secondaryButton].filter(
					(button) => button !== undefined,
				) as unknown as AppHeaderConfigPayload['optionalButtons'],
			}

			setAppHeaderConfig(newAppHeaderConfig)

			const optionalButtonsCleanUp = (): void =>
				setAppHeaderConfig({
					...newAppHeaderConfig,
					optionalButtons: [],
				})

			return optionalButtonsCleanUp
		}
		return () => {}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hasFeatureToggle])
}

export { useSetAppHeaderOptionalButtons }
