export function getApiHost(): string | undefined {
	return ''
}

export function getMockApiHost(): string {
	return 'https://b2b-webapp-mobile-gateway-gb-dev.azurewebsites.net'
}

export function setPortalConfig(): void {
	localStorage.setItem(
		'portal-config',
		JSON.stringify({
			ENV: 'dev',
		}),
	)
}
