import { useAuthorization } from '@/hooks'
import { sortRoutes } from '@/utils/routesUtil'
import { useIsToggleRedeemableItemsEnabled } from '@/utils/featureToggle/featureToggleHelper'
import { RouteInstance } from '../../domains/route/RouteInstance'
import { useGetRoutes } from '../../routes/routes'
import useCurrentZone from '../zone/useCurrentZone'
import { removeRoutesIfNecessary } from './authorizedRoutesHelper'
import { useGetMultiplesFeatureToggles } from '../toggle/useGetMultiplesFeatureToggles'

const useAuthorizedRoutes = (): Array<RouteInstance> => {
	const { isFeatureAuthorized } = useAuthorization()
	const selectedZone = useCurrentZone()
	const isToggleRedeemableItemsEnabled = useIsToggleRedeemableItemsEnabled()
	const { routes } = useGetRoutes()

	const routesFeaturesList = sortRoutes(Object.values(routes)).map((el) => el.authorizationFeature.featureName)

	const { featuresListByToggleName, togglesReady } = useGetMultiplesFeatureToggles(routesFeaturesList)

	if (!selectedZone || !togglesReady) {
		return []
	}
	const authorizedRoutes = sortRoutes(Object.values(routes)).filter((route) => {
		const isEnabled = featuresListByToggleName[route.authorizationFeature.featureName]

		return isFeatureAuthorized(isEnabled, {
			feature: route.authorizationFeature.feature,
			permissionLevel: route.authorizationFeature.permissionLevel,
		})
	})

	return removeRoutesIfNecessary({ list: authorizedRoutes, isToggleRedeemableItemsEnabled, routes })
}

export default useAuthorizedRoutes
