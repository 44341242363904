import { ButtonInteraction, ScreenName } from '@/domains'
import { useFeatureToggleV2 } from '@/hooks'
import { useLoadCombosSettings } from '@/hooks/combo/useLoadCombosSettings'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { ComboEvents, PageLevelEvents, PageLevelStore } from '@/stores'
import * as FeatureToggle from '@/utils/featureToggle'
import { useStoreMap } from 'effector-react'
import React, { useEffect, useState } from 'react'
import CombosListView from './CombosListView'

const CombosList: React.FC = () => {
	const [isStoreReady, setIsStoreReady] = useState(false)
	const userInfo = useGetUserInfo()
	const { loadCombosSettings, togglesReady } = useLoadCombosSettings()
	const editAndRemoveMultiplesCombosIsEnabled = useFeatureToggleV2(FeatureToggle.DEV_EDIT_AND_REMOVE_MULTIPLES_COMBOS)

	const handleAddComboButton = (): void => {
		AnalyticsService.events.buttonClicked({
			button_label: null,
			button_name: ButtonInteraction.AddDtCombo,
			screen_name: ScreenName.DTComboManagement,
			...userInfo,
		})
		PageLevelEvents.hideNotification()
	}

	const { notificationRoute } = useStoreMap({
		store: PageLevelStore,
		keys: [],
		fn: (state) => ({
			notificationRoute: state.notificationRoute,
		}),
	})

	useEffect(() => {
		if (!isStoreReady) {
			AnalyticsService.page(ScreenName.DTComboManagement, userInfo)
			ComboEvents.resetForCombo()
			setIsStoreReady(true)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loadCombosSettings, isStoreReady])

	useEffect(() => {
		if (isStoreReady && togglesReady) {
			loadCombosSettings()
			if (notificationRoute !== '/membership/combos') {
				PageLevelEvents.hideNotification()
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isStoreReady, togglesReady])

	return isStoreReady ? (
		<CombosListView
			handleAddComboButton={handleAddComboButton}
			isEnabledEditAndRemoveMultiplesCombos={editAndRemoveMultiplesCombosIsEnabled!}
		/>
	) : null
}

export default CombosList
