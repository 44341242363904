import { Challenge, ChallengeFiles } from '@/domains'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import ImageDescription from '@/domains/analytics/ImageDescription'
import ScreenName from '@/domains/analytics/ScreenName'
import ChallengeMode from '@/domains/enums/ChallengeMode'
import * as FileManagementService from '@/services/file/FileManagementService'
import * as ChallengeService from '@/services/challenge/ChallengeService'
import { Account } from '@/stores/challengeCreation'
import { hasElements } from '@/utils/array'
import { UserDataResult } from '@/hooks/getUserInfo/useGetUserInfo'
import { OptionalTranslate } from '@/domains/Challenge'
import * as GlobalUseCase from '../global/GlobalUseCase'
import * as ChallengeIdUseCase from './ChallengeIdUseCase'

type ExecuteChallenge = {
	challenge: Challenge
	files: ChallengeFiles
	analyticsProps: UserDataResult
	initialChallenge?: Challenge
	accountsIndividualTarget?: Array<Account>
}

type ExecuteResult = Promise<Challenge | null>

type UpsertChallenge = {
	challenge: Challenge
	initialChallenge?: Challenge
	accountsIndividualTarget?: Array<Account>
}

type UpdateImgParams = {
	challenge: Challenge
	files: ChallengeFiles
	analyticsProps: UserDataResult
	initialChallenge?: Challenge
}

type UploadImgItem = {
	imageKey: 'image' | 'squareImage' | 'goodPhotoSample' | 'badPhotoSample'
	file: File | undefined
	description: string
}

const trackImageUploadedEvent = (
	isEditing: boolean,
	url: string,
	imageDescription: string,
	imageFile: File,
	executionMethod: string,
	challengeId: string,
): void => {
	if (!isEditing) {
		AnalyticsService.events.imageUploaded({
			image_url: url,
			image_description: imageDescription,
			image_name: imageFile.name,
			image_type: imageFile.type,
			image_size: imageFile.size.toString(),
			execution_method: executionMethod,
			challenge_id: challengeId || null,
		})
	}
}

const trackImageUploadErrorEvent = (
	analyticsProps: UserDataResult,
	isEditing: boolean,
	imageDescription: string,
	error: unknown,
): void => {
	if (!isEditing) {
		AnalyticsService.events.error({
			failure_reason: `${imageDescription} Upload ${error}`,
			screen_name: ScreenName.CreateChallenge3rdStep,
			form_name: null,
			...analyticsProps,
		})
	}
}

const trackChallengeCreatedErrorEvent = (isEditing: boolean, error: unknown, analyticsProps: UserDataResult): void => {
	if (!isEditing) {
		AnalyticsService.events.error({
			failure_reason: `Challenge Creation ${error}`,
			screen_name: ScreenName.CreateChallenge3rdStep,
			form_name: null,
			...analyticsProps,
		})
	}
}

const handleFileUpload = async (
	file: File | undefined,
	updateUrl: (url?: string) => void,
	imageDescription: string,
	executionMethod: string,
	challengeID: string,
	isEditing: boolean,
	analyticsProps: UserDataResult,
): Promise<boolean> => {
	const hasFiles = !!file

	if (!hasFiles) {
		return true
	}
	const imageFile = file
	if (imageFile.size === 0) {
		return true
	}
	try {
		const { url } = await FileManagementService.upload(imageFile)
		updateUrl(url)
		trackImageUploadedEvent(isEditing, url, imageDescription, imageFile, executionMethod, challengeID)
		return true
	} catch (error: unknown) {
		trackImageUploadErrorEvent(analyticsProps, isEditing, imageDescription, error)
		return Promise.reject(new Error('File upload failed'))
	}
}

const deleteChallenge = (id: string): Promise<boolean> =>
	ChallengeService.deleteChallenge(id)
		.then(() => true)
		.catch(() => false)

const deleteOldChallengeIfIdChanged = async (challenge: Challenge, initialChallenge?: Challenge): Promise<boolean> => {
	const challengeIdChanged = initialChallenge?.id && initialChallenge.id !== challenge.id
	if (!challengeIdChanged) {
		return true
	}
	return deleteChallenge(initialChallenge.id)
}

const throwFailExecute = async (): Promise<null> => {
	GlobalUseCase.loadDone()
	return Promise.reject(new Error('Execution failed'))
}

const defineChallengeId = (challenge: Challenge, initialChallenge?: Challenge): Challenge => {
	const id = (challenge.id || initialChallenge?.id) ?? ChallengeIdUseCase.generateId()
	return {
		...challenge,
		id,
	}
}

const copyImgAttributes = (challenge: Challenge, initialChallenge: Challenge): Challenge => {
	const challengeCopy = { ...challenge }
	challengeCopy.image = initialChallenge.image
	challengeCopy.squareImage = initialChallenge.squareImage ?? challenge.squareImage
	challengeCopy.goodPhotoSample = initialChallenge.goodPhotoSample
	challengeCopy.badPhotoSample = initialChallenge.badPhotoSample
	return challengeCopy
}

const getImageList = (files: ChallengeFiles): Array<UploadImgItem> => {
	const allImgs: Array<UploadImgItem> = [
		{
			imageKey: 'image',
			file: files.imageFile,
			description: ImageDescription.ChallengeImage,
		},
		{
			imageKey: 'squareImage',
			file: files.squareImageFile,
			description: ImageDescription.ChallengeSquareImage,
		},
		{
			imageKey: 'goodPhotoSample',
			file: files.goodPhotoFile,
			description: ImageDescription.GoodPhoto,
		},
		{
			imageKey: 'badPhotoSample',
			file: files.badPhotoFile,
			description: ImageDescription.BadPhoto,
		},
	]
	return allImgs.filter((item) => !!item.file)
}

const getImageListTranslate = (files: OptionalTranslate): Array<UploadImgItem> => {
	const allImgs: Array<UploadImgItem> = [
		{
			imageKey: 'image',
			file: files.image?.file,
			description: ImageDescription.ChallengeImage,
		},
		{
			imageKey: 'squareImage',
			file: files.squareImage?.file,
			description: ImageDescription.ChallengeSquareImage,
		},
		{
			imageKey: 'goodPhotoSample',
			file: files.goodPhotoSample?.file,
			description: ImageDescription.GoodPhoto,
		},
		{
			imageKey: 'badPhotoSample',
			file: files.badPhotoSample?.file,
			description: ImageDescription.BadPhoto,
		},
	]
	return allImgs.filter((item) => !!item.file)
}

const uploadImages = async ({
	challenge,
	files,
	analyticsProps,
	initialChallenge,
}: UpdateImgParams): Promise<Challenge> => {
	const challengeCopy = { ...challenge }
	const isEditing = !!initialChallenge
	const imageList = getImageList(files)

	if (hasElements(imageList)) {
		const allRequests = imageList.map((item: UploadImgItem) =>
			handleFileUpload(
				item.file,
				(url) => {
					challengeCopy[item.imageKey] = url
				},
				item.description,
				challengeCopy.executionMethod,
				challengeCopy.id,
				isEditing,
				analyticsProps,
			),
		)
		challengeCopy.translations?.forEach((translate) => {
			const imageListTranslates = getImageListTranslate(translate)
			const allTranslationRequest = imageListTranslates.map((item: UploadImgItem) =>
				handleFileUpload(
					item.file,
					(url) => {
						translate[item.imageKey] = url
					},
					item.description,
					challengeCopy.executionMethod,
					challengeCopy.id,
					isEditing,
					analyticsProps,
				),
			)
			allRequests.push(...allTranslationRequest)
		})
		await Promise.all(allRequests)
	}
	return challengeCopy
}

const updateChallengesImages = async ({
	challenge,
	files,
	analyticsProps,
	initialChallenge,
}: UpdateImgParams): Promise<Challenge> => {
	let challengeCopy = { ...challenge }
	if (initialChallenge) {
		challengeCopy = copyImgAttributes(challengeCopy, initialChallenge)
	}
	return uploadImages({ challenge: challengeCopy, analyticsProps, files, initialChallenge })
}

const upsertChallenge = async ({ challenge, initialChallenge }: UpsertChallenge): Promise<Challenge> => {
	const isEditing = !!initialChallenge
	const languages = challenge.translations?.map((translation) => translation.languageId)

	languages?.forEach((language) => {
		const languageSuffix = `-${language}`
		for (const key in challenge) {
			if (key.endsWith(languageSuffix)) {
				delete challenge[key]
			}
		}
	})

	const newTranslations = challenge.translations?.map((translation) => {
		if (isEditing && translation.image?.url) {
			return {
				...translation,
				image: translation.image.url,
				squareImage: translation.squareImage?.url,
			}
		}
		return translation
	}) as OptionalTranslate[]

	return ChallengeService.upsert({ ...challenge, translations: newTranslations }).catch((error) => {
		if (!isEditing) {
			deleteChallenge(challenge.id)
		}
		throw error
	})
}

async function execute({
	challenge,
	files,
	initialChallenge,
	accountsIndividualTarget,
	analyticsProps,
}: ExecuteChallenge): ExecuteResult {
	GlobalUseCase.load()
	const isEditing = !!initialChallenge
	let challengeParam = { ...challenge }

	try {
		challengeParam = await updateChallengesImages({
			challenge: challengeParam,
			analyticsProps,
			files,
			initialChallenge,
		})
	} catch (error) {
		return throwFailExecute()
	}
	challengeParam = defineChallengeId(challengeParam, initialChallenge)
	challengeParam.mode = ChallengeMode.Published

	const isOK = await deleteOldChallengeIfIdChanged(challengeParam, initialChallenge)
	if (!isOK) {
		return throwFailExecute()
	}

	try {
		const response = await upsertChallenge({
			challenge: challengeParam,
			initialChallenge,
			accountsIndividualTarget,
		})
		GlobalUseCase.loadDone()
		return response
	} catch (error: unknown) {
		trackChallengeCreatedErrorEvent(isEditing, error, analyticsProps)
		return throwFailExecute()
	}
}

export { execute }
