import { OrderWithCombo } from '@/domains'
import OrderWithRedeemables from '@/domains/OrderWithRedeemables'
import { TransactionStore } from '@/stores'
import { OrderWithCombosOrRedeemables } from '@/stores/transaction/TransactionState'
import { useStoreMap } from 'effector-react'

type useGetTransactionInfoResult = {
	order: OrderWithCombosOrRedeemables | null
	accountId: string
	placementDate: Date
	status: string
}

export const useGetTransactionInfo = ({
	isToggleRefundRedeemableItemsEnabled,
}: {
	isToggleRefundRedeemableItemsEnabled: boolean
}): useGetTransactionInfoResult => {
	const { order, accountId, placementDate, status } = useStoreMap({
		store: TransactionStore,
		keys: [],
		fn: (state) => {
			return getTransactionInfo({ selectedOrder: state.selectedOrder, isToggleRefundRedeemableItemsEnabled })
		},
	})

	return {
		order,
		accountId,
		placementDate,
		status,
	}
}

export const getTransactionInfo = ({
	selectedOrder = null,
	isToggleRefundRedeemableItemsEnabled,
}: {
	selectedOrder: OrderWithCombosOrRedeemables | null
	isToggleRefundRedeemableItemsEnabled: boolean
}): useGetTransactionInfoResult => {
	if (!selectedOrder) {
		return {
			order: selectedOrder,
			accountId: '',
			placementDate: new Date(),
			status: '',
		}
	}

	if (!isToggleRefundRedeemableItemsEnabled) {
		const orderCombos = selectedOrder as OrderWithCombo
		return {
			order: orderCombos,
			accountId: orderCombos?.accountId,
			placementDate: orderCombos?.order.placementDate,
			status: orderCombos?.order.status,
		}
	}

	const orderRedeemables = selectedOrder as OrderWithRedeemables
	return {
		order: orderRedeemables,
		...orderRedeemables,
	}
}
