import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		dialogRoot: {
			'& [data-testid="confirm-dialog"] > *:first-child': {
				borderRadius: 'var(--radii-6)',
			},
		},
		containerItem: {
			marginTop: '16px',
		},
		itemTitle: {
			marginBottom: '4px',
		},
		tableStatus: {
			'& svg': {
				display: 'none',
			},
		},
		rejectionReason: {
			color: theme.palette.error.dark,
			marginBottom: '4px',
		},
		tableContainer: {
			'& *': {
				overflow: 'visible',
			},
			'& tr:last-child ul': {
				bottom: '4px',
			},
		},
	}),
)

const gridContainerStyles = {
	rowGap: '1rem',
	margin: '1rem 0',
}

export { useStyles, gridContainerStyles }
