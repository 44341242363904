import { loadEarningRules } from '@/services/rules/RulesService'
import { useQuery } from 'react-query'

interface EarningRule {
	vendorId: string
	tier: string
}

export const useEarningRulesData = ({ vendorId, tier }: EarningRule) => {
	const { data, error, isLoading, refetch } = useQuery(
		['earningRules', vendorId, tier],
		() => loadEarningRules(vendorId, tier),
		{
			staleTime: Infinity,
		},
	)

	return { data, error, isLoading, refetch }
}
