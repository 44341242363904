import React from 'react'
import CreateEarningRuleProvider from './context/CreateEarningRuleProvider/CreateEarningRuleProvider'
import CreateEarningRule from './CreateEarningRule'

const CreateEarningRuleWrapper: React.FC = () => (
	<CreateEarningRuleProvider>
		<CreateEarningRule />
	</CreateEarningRuleProvider>
)

export default CreateEarningRuleWrapper
