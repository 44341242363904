import { updateRule, putItemRule } from '@/services/program/ProgramService'
import { getDefaultVendorIdOfCurrentZone } from '@/utils/getDefaultVendorId'
import SkuForm from '@/domains/sku/SkuForm'

async function execute({
	programId,
	ruleId,
	sku,
	isDevMultiVendorEarningRulesEnabled,
}: {
	programId: string
	ruleId: string
	sku: SkuForm
	isDevMultiVendorEarningRulesEnabled: boolean
}): Promise<boolean> {
	try {
		if (isDevMultiVendorEarningRulesEnabled) {
			const vendorId = getDefaultVendorIdOfCurrentZone()
			await putItemRule(programId, ruleId, sku.itemId, { vendorItemId: sku.id, vendorId })
		}
		await updateRule(programId, ruleId, { skus: [sku.id] })
		return true
	} catch {
		return false
	}
}

export { execute }
