import { ChallengeExecutionMethod } from '@/domains/enums'
import { useTranslation } from 'react-i18next'

import * as FeatureToggle from '@/utils/featureToggle'
import { useFeatureToggleV2 } from '../toggle/useFeatureToggle'

export type TypeList = {
	value: string
	label: string
}

const useChallengeTypeList = (): TypeList[] => {
	const { t } = useTranslation()

	const isTogglePurchaseChallengesEnabled = useFeatureToggleV2(FeatureToggle.PURCHASE_CHALLENGES)
	const isToggleMarkCompleteChallengeEnabled = useFeatureToggleV2(FeatureToggle.MARK_COMPLETE_CHALLENGES)
	const isTogglePhotoChallengeEnabled = useFeatureToggleV2(FeatureToggle.PHOTO_CHALLENGES)
	const isTogglePurchaseChallengeMultipleEnabled = useFeatureToggleV2(FeatureToggle.PURCHASE_CHALLENGES_MULTIPLE)
	const isToggleVolumeChallengeEnabled = useFeatureToggleV2(FeatureToggle.VOLUME_CHALLENGES)

	const typeList: TypeList[] = []

	if (isToggleVolumeChallengeEnabled) {
		typeList.push({
			value: ChallengeExecutionMethod.VolumeFixed,
			label: t('challenge:CHALLENGE_TYPE_VOLUME_FIXED_POINTS'),
		})
	}
	if (isTogglePurchaseChallengesEnabled) {
		typeList.push({
			value: ChallengeExecutionMethod.Purchase,
			label: t('challenge:CHALLENGE_TYPE_PURCHASE_SINGLE'),
		})
	}
	if (isTogglePurchaseChallengeMultipleEnabled) {
		typeList.push({
			value: ChallengeExecutionMethod.PurchaseMultiple,
			label: t('challenge:CHALLENGE_TYPE_PURCHASE_MULTIPLE'),
		})
	}
	if (isTogglePhotoChallengeEnabled) {
		typeList.push({ value: ChallengeExecutionMethod.TakePhoto, label: t('challenge:CHALLENGE_TYPE_1') })
	}
	if (isToggleMarkCompleteChallengeEnabled) {
		typeList.push({ value: ChallengeExecutionMethod.MarkAsComplete, label: t('challenge:CHALLENGE_TYPE_2') })
	}

	return typeList
}

export default useChallengeTypeList
