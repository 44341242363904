import { useMutation, useQueryClient } from 'react-query'
import useCurrentSettings from '@/hooks/settings/useCurrentSettings'
import { useCurrentTier } from '@/hooks/tier/useCurrentTier'
import { TypeToast, useToast } from 'admin-portal-shared-services'
import {} from 'react-router-dom'
import { useNavigation } from '@/hooks'
import { AxiosError } from 'axios'
import { useTranslation } from 'react-i18next'
import { updateEarningRule } from '../RulesService'
import { UpdateEarningRuleDTO } from '../RulesService.types'
import { handleEarningRulesError, normalizeSkus } from './earningRulesUtils'

export const useUpdateEarningRule = (): {
	isLoading: boolean
	putEarningRule: (payload: UpdateEarningRuleDTO) => void
} => {
	const toast = useToast()
	const settings = useCurrentSettings()
	const { vendorId: vendorIdSettings } = settings
	const { selectedTierKey } = useCurrentTier()
	const { goToRoutePath } = useNavigation()
	const queryClient = useQueryClient()
	const { t } = useTranslation()

	const { isLoading, mutate: putEarningRule } = useMutation(
		(payload: UpdateEarningRuleDTO) => {
			const skusWithVendorId = normalizeSkus({ items: payload.items, vendorIdSettings })

			const { amountSpent, points, description, status, vendorId, tier, ruleId } = payload

			const payloadNormalized: UpdateEarningRuleDTO = {
				amountSpent,
				points,
				description,
				status,
				items: skusWithVendorId,
				vendorId,
				tier,
				ruleId,
			}
			return updateEarningRule({ ...payloadNormalized, vendorId: vendorIdSettings, tier: selectedTierKey })
		},
		{
			onSuccess: async () => {
				toast.notify({
					type: TypeToast.SUCCESS,
					message: t('rules:CREATE_EARNING_RULES.EDITED_RULE_SUCCESSFUL_MESSAGE'),
				})
				await queryClient.invalidateQueries(['earning-rule-by-id'])
				goToRoutePath('/rules')
			},
			onError: (err: { cause: AxiosError }) => {
				handleEarningRulesError({ err, toast })
			},
		},
	)

	return {
		isLoading,
		putEarningRule,
	}
}
