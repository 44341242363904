export const createExclusionBreadcrumb = {
	homePath: '/membership',
	items: [
		{
			label: 'Rules list',
			path: '/membership/rules',
			isCurrentPage: false,
		},
		{
			label: 'Exclusion',
			path: '/membership/rules/exclusion-rule',
			isCurrentPage: true,
		},
	],
}
