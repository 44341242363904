import { useFeatureToggleV2 } from '@/hooks'
import { $pricePerPointConfigurationStore } from '@/stores'
import { PROJECT_NAME } from '@/utils/constants'
import * as FeatureToggle from '@/utils/featureToggle'
import { createFeatureToggleServiceV2 } from 'admin-portal-shared-services'

let initializedToggles = false

export const useIsToggleRedeemableItemsEnabled = (): boolean => {
	const { pricePerPointEnabled } = $pricePerPointConfigurationStore.getState()
	const isPricePerPointVendorSettingsEnabled = !!pricePerPointEnabled
	const isRedeemableItemsToggleEnabled = !!useFeatureToggleV2(FeatureToggle.REDEEMABLE_ITEMS_MODULE)

	return isRedeemableItemsToggleEnabled && isPricePerPointVendorSettingsEnabled
}

export const useIsTogglePricePerPointSettingsEnabled = (): boolean => {
	const isPerPricePointsSettingsEnabled = useFeatureToggleV2(FeatureToggle.PRICE_PER_POINT_SETTINGS)
	const isToggleRedeemableItemsEnabled = useIsToggleRedeemableItemsEnabled()
	return isToggleRedeemableItemsEnabled && isPerPricePointsSettingsEnabled!
}

export function initFeatureToggles(optimizelyKey: string): void {
	if (initializedToggles) {
		return
	}

	createFeatureToggleServiceV2(PROJECT_NAME, optimizelyKey || 'localhost')
	initializedToggles = true
}
