import { Grow } from '@material-ui/core'
import InputTextWithValidation from 'components/formik/InputTextWithValidation'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useFeatureToggleV2 } from '@/hooks'
import * as FeatureToggle from '@/utils/featureToggle'
import CombosList from '../CombosList/CombosList'
import OrderSection from './components/OrderSection/index'
import { RedeemablesList } from './components/RedeemablesList/RedeemablesList'

type Props = {
	validateOrderId: ({
		orderId,
		isToggleRefundRedeemableItemsEnabled,
	}: {
		orderId: string
		isToggleRefundRedeemableItemsEnabled?: boolean
	}) => Promise<string>
	showError: boolean
	onError?: (id: string, message: string) => void
	isContainerVisible?: boolean
	handleOrderIdKeyUp?: () => void
}

const TransactionRefundView: React.FC<Props> = ({
	validateOrderId,
	showError,
	isContainerVisible,
	handleOrderIdKeyUp,
	onError,
}: Props) => {
	const { t } = useTranslation()
	const emptyFieldErrorMessage = t('ERROR_MESSAGE.EMPTY_FIELD')
	const isToggleRefundRedeemableItemsEnabled = useFeatureToggleV2(FeatureToggle.CREATE_REFUND_REDEEMABLES)

	const handleValidateOrderId = (orderId: string) => {
		return validateOrderId({ orderId, isToggleRefundRedeemableItemsEnabled })
	}

	return (
		<>
			<InputTextWithValidation
				id="transaction-order-id"
				name="orderId"
				label={t('transaction:ORDER_ID')}
				validate={(val) => handleValidateOrderId(val)}
				required={emptyFieldErrorMessage}
				isFormValidating={showError}
				watchFormikValue
				hideErrorOnChange
				onKeyUpFirstKey={handleOrderIdKeyUp}
				onError={onError}
			/>
			{isContainerVisible && (
				<Grow in>
					<>
						<OrderSection />
						{isToggleRefundRedeemableItemsEnabled ? <RedeemablesList /> : <CombosList />}
					</>
				</Grow>
			)}
		</>
	)
}

export default TransactionRefundView
