import { styled } from '@bees/vision-tokens'

export const AudienceCardContent = styled('div', {
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	gap: '16px',
})

export const AudienceCardItem = styled('div', {
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'flex-start',
	gap: '8px',
	wordBreak: 'break-word',
	minWidth: '33%',
})
