import React from 'react'
import { SkuModalProvider, SkuModalValueType } from './context/SkuModalContext'
import { SkuModalConsumer } from './components/SkuModalConsumer/SkuModalConsumer'

interface SkuModalProps {
	readonly isModalOpened: boolean
	readonly closeModal: () => void
	readonly onAddValues: (value: SkuModalValueType) => void
}

export function SkuModal({ isModalOpened, closeModal, onAddValues }: SkuModalProps) {
	const onAddValueshandler = (value: SkuModalValueType) => {
		onAddValues(value)
		closeModal()
	}
	return (
		<SkuModalProvider>
			<SkuModalConsumer isModalOpened={isModalOpened} closeModal={closeModal} onAddValues={onAddValueshandler} />
		</SkuModalProvider>
	)
}
