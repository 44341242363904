import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { ChallengeEvents, ChallengeSetupFormData, ChallengeStore } from '@/stores/challengeCreation'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { BASE_ROUTE, CHALLENGE_BASE_ROUTE } from '@/utils/constants'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import { useStore } from 'effector-react'
import { OptionalTranslate } from '@/domains/Challenge'
import ChallengeFormSetupView from './ChallengeFormSetupView'
import { ChallengeLeavingGuard } from '../ChallengeLeavingGuard/ChallengeLeavingGuard'
import ChallengeFormSetupProvider from './ChallengeFormSetupProvider'
import MissingInfoModal from '../MissingInfoModal'
import { createTranslationsArray, validateOptionalsTranslations } from './utils/translationsUtils'

export type ChallengeFormSetupProps = {
	disableIdField?: boolean
	isEditing?: boolean
}

const ChallengeFormSetup: React.FC<ChallengeFormSetupProps> = ({ disableIdField = false, isEditing }) => {
	const { t } = useTranslation()
	const history = useHistory()
	const useGetInfo = useGetUserInfo()
	const { optionalLanguagesUsed } = useStore(ChallengeStore)
	const [showIncompleteModal, setShowIncompleteModal] = useState<boolean>(false)

	const convertToSetupFormData = (formData: ChallengeSetupFormData, translations?: OptionalTranslate[]) => {
		return {
			...formData,
			translations,
		}
	}

	const handleFormSubmit = (formData: ChallengeSetupFormData) => {
		const translations = createTranslationsArray(formData, optionalLanguagesUsed)
		const isValidTranslations = validateOptionalsTranslations(translations)
		if (isValidTranslations) {
			const setupFormData = convertToSetupFormData(formData, translations)
			ChallengeEvents.setChallengeSetupFormData({ ...setupFormData, id: formData.id?.trim() })
			AnalyticsService.events.buttonClicked({
				button_label: 'Continue',
				button_name: 'Continue',
				screen_name: 'Challenge Creation - 1st Step',
				...useGetInfo,
			})
			history.push(`${CHALLENGE_BASE_ROUTE}/filter`)
		} else {
			setShowIncompleteModal(true)
		}
	}

	useEffect(() => {
		ChallengeEvents.setCurrentStep({ stepIndex: 0 })
	}, [t])

	return (
		<ChallengeFormSetupProvider>
			<MissingInfoModal
				isOpen={showIncompleteModal}
				buttonActions={{
					cancel() {
						setShowIncompleteModal(false)
					},
				}}
			/>
			<ChallengeLeavingGuard whiteList={[`${BASE_ROUTE}/challenge/filter`]} />
			<ChallengeFormSetupView onFormSubmit={handleFormSubmit} disableIdField={disableIdField} isEditing={isEditing} />
		</ChallengeFormSetupProvider>
	)
}

export default ChallengeFormSetup
