import { RulesEvents } from '@/stores'
import React, { useCallback } from 'react'
import { useFormContext } from 'react-hook-form'
import Styled from './styles'

import { UploadSkuCsvFile } from '../components/UploadSkuCsvFile'
import { useTriggerSegmentsCsvSku } from '../hooks/useTriggerSegmentsCsvSku'

interface UploadFileProps {
	fileName?: string | null
	inputName: string
}

const UploadFile: React.FC<UploadFileProps> = ({ fileName = '', inputName }) => {
	const {
		register,
		trigger,
		setValue,
		clearErrors,
		formState: { errors },
	} = useFormContext()
	const { dispatchTracking } = useTriggerSegmentsCsvSku()

	const handleSubmitFile = useCallback(
		async (event: React.ChangeEvent<HTMLInputElement>) => {
			const { files } = event.currentTarget
			const file = files?.[0]

			clearErrors()
			setValue(inputName, files)
			RulesEvents.setSkuUploadFile(files as FileList)

			await trigger('uploadFile')
				.then((res) => RulesEvents.setPropInEditSkuStepData({ skus: res }))
				.catch((err) => RulesEvents.setPropInEditSkuStepData({ skus: !!err }))

			dispatchTracking(file)
		},
		[clearErrors, dispatchTracking, inputName, setValue, trigger],
	)

	return (
		<Styled.Content>
			<UploadSkuCsvFile
				handleSubmitFile={handleSubmitFile}
				fileName={fileName ?? ''}
				inputName={inputName}
				hookFormRegister={register}
				errors={!!errors.uploadFile}
				errorMessage={errors.uploadFile?.message}
			/>
		</Styled.Content>
	)
}

export default UploadFile
