import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { ChallengeExecutionMethod, ChallengeStatus } from '@/domains/enums'
import FormDialogTemplate from '@/components/templates/FormDialogTemplate'
import ChallengeFormDetailsView from '@/routes/challenge/componentsRedesign/ChallengeFormDetails/ChallengeFormDetailsView'
import { OnCancelButtonClickFunction } from '@/routes/challenges/model/EditChallengeDialog'
import { ChallengeDetailsFormData } from '@/stores/challengeCreation'
import { yupResolver } from '@hookform/resolvers/yup'
import { getChallengeDetailsValidationObject } from '@/routes/challenge/componentsRedesign/ChallengeFormDetails/validationObject'
import { hasElements } from '@/utils/array'
import { useFeatureToggleV2 } from '@/hooks'
import * as FeatureToggle from '@/utils/featureToggle'

type Props = {
	challengeDetailsFormData?: ChallengeDetailsFormData
	onFormSubmit: SubmitHandler<ChallengeDetailsFormData>
	onCancelButtonClick: OnCancelButtonClickFunction
	executionMethod: ChallengeExecutionMethod | null
	challengeStatus?: ChallengeStatus
	isValidatingSku?: boolean
}

const ChallengeFormDetailsEditDialogView: React.FC<Props> = ({
	challengeDetailsFormData,
	onFormSubmit,
	onCancelButtonClick,
	isValidatingSku,
	executionMethod,
}) => {
	const { t } = useTranslation()
	const isToggleVolumeFixedSingle = useFeatureToggleV2(FeatureToggle.VOLUME_FIXED_SINGLE)

	const formMethods = useForm({
		resolver: yupResolver(
			getChallengeDetailsValidationObject({
				isEditing: true,
				executionMethod: executionMethod as ChallengeExecutionMethod,
				isToggleVolumeFixedSingle: isToggleVolumeFixedSingle!,
				isIndividualTarget: false,
			}),
		),
		mode: 'onBlur',
		shouldFocusError: false,
	})

	const hasErrors = () => hasElements(Object.keys(formMethods.formState.errors))

	useEffect(() => {
		formMethods.reset(challengeDetailsFormData)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const hasDirtyFields =
		formMethods.formState.dirtyFields.skus ||
		formMethods.formState.dirtyFields.points ||
		formMethods.formState.dirtyFields.quantity

	return (
		<FormDialogTemplate
			showDialog
			title={t('challenge:EDIT_DIALOG.TITLE')}
			cancelButtonProps={{
				handleClick: onCancelButtonClick,
			}}
			submitButtonProps={{
				disabled: !(formMethods.formState.isValid && hasDirtyFields) || isValidatingSku || hasErrors(),
			}}
			handleSubmit={formMethods.handleSubmit(onFormSubmit)}
		>
			<FormProvider {...formMethods}>
				<ChallengeFormDetailsView isEditing />
			</FormProvider>
		</FormDialogTemplate>
	)
}

export default ChallengeFormDetailsEditDialogView
