import useGetUserInfo from '@/hooks/useInfo/useGetUserInfo'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { ValidateEarningRuleSkuUseCase } from '@/usecase'
import * as FeatureToggle from '@/utils/featureToggle'
import { useGetMultiplesFeatureToggles } from '@/hooks/toggle/useGetMultiplesFeatureToggles'

export const useGetSkuForRule = () => {
	const userInfo = useGetUserInfo()

	const { featuresListByToggleName } = useGetMultiplesFeatureToggles([
		FeatureToggle.DEV_MULTIVENDOR_EARNING_RULES_BY_VENDOR,
		FeatureToggle.DEV_MULTI_VENDOR_EARNING_RULES,
	])

	const isToggleEarningRulesByVendorEnabled =
		featuresListByToggleName[FeatureToggle.DEV_MULTIVENDOR_EARNING_RULES_BY_VENDOR]
	const multiVendorToggle = featuresListByToggleName[FeatureToggle.DEV_MULTI_VENDOR_EARNING_RULES]

	const getSkuForRule = async ({ skuId }: { skuId?: string }) => {
		AnalyticsService.events.tableSearched({
			search_query: skuId,
			content_type: 'SKU ID',
			table_name: 'Earning Rules - SKUs - Single',
			screen_name: 'Earning Rules - SKUs - Edit - Add - Single - Step 3',
			...userInfo,
		})

		if (skuId && skuId !== '') {
			const result = await ValidateEarningRuleSkuUseCase.execute({
				sku: skuId,
				isToggleEarningRulesByVendorEnabled,
				multiVendorToggle,
			})

			return result
		}

		return undefined
	}

	return {
		getSkuForRule,
	}
}
