import React, { useState } from 'react'
import StepSingleSkuComponentView from '@/routes/rules/RulesRedesign/Pages/EditSku/EditSkuStepper/StepSKU/StepSingleSkuComponent/StepSingleSkuComponentView'

import SkuForm from '@/domains/sku/SkuForm'
import { useGetSkuForRule } from '@/routes/rules/RulesRedesign/Pages/EditSku/EditSkuStepper/StepSKU/hooks/useGetSkuForRule'
import { useSkuModalContext } from '../../context/SkuModalContext'

export const SkuSearchTable = () => {
	const { setSkuModalValue, skuModalValue } = useSkuModalContext()
	const [searchText, setSearchText] = useState<string | undefined>()
	const [itemNotFound, setItemNotFound] = useState<boolean>(false)
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const { getSkuForRule } = useGetSkuForRule()

	const handleSearch = async () => {
		setIsLoading(true)

		const skuId = searchText
		const result = await getSkuForRule({ skuId })

		setSkuModalValue({ items: result ? [result.sku] : [], fileName: '' })
		setItemNotFound(!result?.sku)
		setIsLoading(false)
	}

	const handleClear = () => {
		setItemNotFound(false)
		setSkuModalValue({ items: [], fileName: '' })
	}

	return (
		<StepSingleSkuComponentView
			handleSearch={handleSearch}
			handleClear={handleClear}
			skuItem={skuModalValue.items?.[0] as SkuForm}
			isLoading={isLoading}
			setSearchText={setSearchText}
			itemNotFound={itemNotFound}
		/>
	)
}
