import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ChallengeSetupFormData } from '@/stores/challengeCreation'
import { ChallengeStatus } from '@/domains/enums'
import ChallengeFormSetupView from '@/routes/challenge/componentsRedesign/ChallengeFormSetup/ChallengeFormSetupView'
import { getValidationObject } from '@/routes/challenge/componentsRedesign/ChallengeFormSetup/validationObject'
import { OnCancelButtonClickFunction } from '@/routes/challenges/model/EditChallengeDialog'
import FormDialogTemplate from '@/components/templates/FormDialogTemplate'
import useSupportedLanguages from '@/hooks/useSupportedLanguages/useSupportedLanguages'
import { useFeatureToggleV2 } from '@/hooks'
import * as FeatureToggle from '@/utils/featureToggle'

type Props = {
	challengeSetupFormData?: ChallengeSetupFormData
	onFormSubmit: SubmitHandler<ChallengeSetupFormData>
	onCancelButtonClick: OnCancelButtonClickFunction
	challengeStatus?: ChallengeStatus
	isValidatingChallengeId?: boolean
	isValidatingOptionalFields?: boolean
}

const ChallengeFormSetupEditDialogView: React.FC<Props> = ({
	challengeSetupFormData,
	onFormSubmit,
	onCancelButtonClick,
	challengeStatus,
	isValidatingChallengeId,
	isValidatingOptionalFields,
}) => {
	const { t } = useTranslation()

	const { supportedLanguages } = useSupportedLanguages() ?? {}
	const isThumbnailAvailable = useFeatureToggleV2(FeatureToggle.DEV_CHALLENGE_THUMBNAIL)
	const formMethods = useForm({
		resolver: yupResolver(getValidationObject(supportedLanguages, false, isThumbnailAvailable)),
		mode: 'onBlur',
		shouldFocusError: false,
	})

	useEffect(() => {
		formMethods.reset(challengeSetupFormData)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [challengeSetupFormData])

	return (
		<FormDialogTemplate
			showDialog
			title={t('challenge:EDIT_DIALOG.TITLE')}
			cancelButtonProps={{
				handleClick: onCancelButtonClick,
			}}
			submitButtonProps={{
				disabled:
					!formMethods.formState.isValid ||
					!formMethods.formState.isDirty ||
					isValidatingChallengeId ||
					isValidatingOptionalFields,
			}}
			handleSubmit={formMethods.handleSubmit(onFormSubmit)}
		>
			<FormProvider {...formMethods}>
				<ChallengeFormSetupView disableIdField={ChallengeStatus.Active === challengeStatus} isEditing />
			</FormProvider>
		</FormDialogTemplate>
	)
}

export default ChallengeFormSetupEditDialogView
