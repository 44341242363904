import { createStyles, makeStyles } from '@material-ui/core/styles'

const LimitExceededCardStyle = makeStyles(() =>
	createStyles({
		warningMessage: {
			width: '100%',
			height: '56px',

			justifyContent: 'center',

			marginBottom: '16px',

			color: 'var(--colors-neutral100)',
			backgroundColor: 'var(--colors-interfaceDatavisMonoWarm10)',
			border: '1px solid var(--colors-interfaceDatavisMonoWarm30)',

			'& > *': {
				fontHeight: '--lineHeights-5',
			},
		},
	}),
)

export default LimitExceededCardStyle
