import { fieldCurrencyValidationRequired } from '@/utils/validationObjects'
import { yupResolver } from '@hookform/resolvers/yup'
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import * as Yup from 'yup'

const generateValidationSchema = (formField: string) => {
	return Yup.object().shape({
		[formField]: fieldCurrencyValidationRequired(),
	})
}

const EditRedeemableItemFormProvider: React.FC<{ formField: string }> = ({ children, formField }) => {
	const validationSchema = generateValidationSchema(formField)
	const formMethods = useForm({
		resolver: yupResolver(validationSchema),
		mode: 'onChange',
		shouldFocusError: true,
	})
	return (
		<FormProvider {...formMethods}>
			<form>{children}</form>
		</FormProvider>
	)
}

export default EditRedeemableItemFormProvider
