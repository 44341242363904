import { makeStyles, Theme } from '@material-ui/core/styles'

const styles = makeStyles((theme: Theme) => ({
	subContainer: {
		padding: 0,
		paddingBottom: theme.spacing(2),
		marginBottom: theme.spacing(3),
		boxShadow: 'inset 0 -1px 0 0 #DFDEDE',
	},
	dataContainer: {
		paddingBottom: theme.spacing(3),
	},
	bodyGrey: {
		color: theme.palette.text.secondary,
	},
	campaignIdLabel: {
		color: theme.palette.text.secondary,
		maxWidth: '39ch',
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
	titleAndDescription: {
		display: 'flex',
		flexFlow: 'column',
		maxWidth: '432px',
	},
	challengeDescription: {
		padding: '16px 0 0',
	},
	image: {
		marginBottom: '0',
		objectFit: 'cover',
		maxWidth: '100%',
		maxHeight: '148px',
	},
	descriptionContainer: {
		display: 'flex',
		minHeight: '35px',
	},
	goodBadImage: {
		borderBottom: '2px solid #FFF',
	},
	imageContainer: {
		display: 'flex',
		flexDirection: 'column',
		flexWrap: 'nowrap',
		alignItems: 'baseline',
	},
	imagesWrapper: {
		padding: '16px 0px',
		display: 'flex',
		flexDirection: 'row',
	},
}))

export default styles
