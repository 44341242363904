import { TypeToast } from 'admin-portal-shared-services'
import {} from 'react-router-dom'
import { AxiosError } from 'axios'
import ToastService from 'admin-portal-shared-services/dist/services/toast/ToastService'
import { SkuRuleItem } from '../RulesService.types'

export const normalizeSkus = ({
	items,
	vendorIdSettings,
}: {
	items?: SkuRuleItem[]
	vendorIdSettings: string
}): SkuRuleItem[] | undefined => {
	return items?.map((sku) => ({
		vendorId: sku.vendorId ? sku.vendorId : vendorIdSettings,
		vendorItemId: sku.vendorItemId,
		sku: sku.sku,
		itemId: sku.itemId,
		image: sku?.image,
	}))
}

export const handleEarningRulesError = ({ err, toast }: { toast: ToastService; err: { cause: AxiosError } }): void => {
	const response = err.cause.response as { status: number; data: { code: number } }

	toast.notify({
		type: TypeToast.ERROR,
		message:
			response.data.code === 33 && response.status === 422
				? 'Duplicated items. Item is already being used on other rule.'
				: 'An error ocurred. Try again.',
	})
}
