import React from 'react'
import { SkuPreviewProps } from './SkuPreview.types'
import { DEFAULT_IMAGE_FALLBACK_URL } from '@/utils/constants'
import * as Styled from './SkuPreview.styles'
import SkuForm from '@/domains/sku/SkuForm'

export const SkuPreview = (value: SkuPreviewProps, rowData: SkuForm) => {
	const srcImage = value ?? DEFAULT_IMAGE_FALLBACK_URL

	return (
		<Styled.Container>
			<Styled.Img src={srcImage} alt={`sku-${rowData?.id}-${rowData?.name}`} />
		</Styled.Container>
	)
}
