import React, { useEffect } from 'react'
import { IconButton } from '@hexa-ui/components'
import { Download, MoreHorizontal } from '@hexa-ui/icons'
import { useTranslation } from 'react-i18next'
import { useStyles } from './styles'

// TO DO: Add more classes as the `./styles.tsx` file grows.
type ComponentClasses = 'PENDING'

interface IDropdownButton {
	buttonText: string
	onClickAction?: (event: React.MouseEvent) => void
	key: string
}

export interface DropdownProps {
	isOpenDropdownMenu: boolean
	setIsOpenDropdownMenu: React.Dispatch<React.SetStateAction<string | null>>
	buttons: IDropdownButton[]
	iconButtonProps: {
		status: string
		onClick: () => void
	}
}

const Dropdown: React.FC<DropdownProps> = ({ isOpenDropdownMenu, setIsOpenDropdownMenu, buttons, iconButtonProps }) => {
	const { t } = useTranslation()
	const classes = useStyles()
	const componentClass: ComponentClasses = iconButtonProps.status as ComponentClasses
	const icon = iconButtonProps.status === 'PENDING' ? <MoreHorizontal fr={undefined} /> : <Download fr={undefined} />

	useEffect(() => {
		const handleClickOutside = (event: MouseEvent) => {
			const dropdownMenu = document.getElementById('dropdownMenu')
			if (isOpenDropdownMenu && dropdownMenu && !dropdownMenu.contains(event.target as Node)) {
				setIsOpenDropdownMenu(null)
			}
		}

		document.addEventListener('mousedown', handleClickOutside)
		return () => {
			document.removeEventListener('mousedown', handleClickOutside)
		}
	}, [setIsOpenDropdownMenu, isOpenDropdownMenu])

	return iconButtonProps.status === 'PENDING' ? (
		<div className={classes[componentClass]} data-testid="dropdown-menu-wrapper">
			{isOpenDropdownMenu && (
				<ul id="dropdownMenu" data-testid="dropdown-menu-buttons-list">
					{buttons.map((button) => (
						<li key={button.key}>
							<button type="button" onClick={button.onClickAction} data-testid="dropdown-menu-button">
								{t(button.buttonText)}
							</button>
						</li>
					))}
				</ul>
			)}
			<IconButton
				icon={() => icon}
				variant="tertiary"
				onClick={iconButtonProps.onClick}
				data-testid="dropdown-menu-icon"
			/>
		</div>
	) : (
		<IconButton icon={() => icon} variant="tertiary" onClick={iconButtonProps.onClick} data-testid="download-icon" />
	)
}

export default Dropdown
