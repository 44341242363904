import { Paragraph } from '@hexa-ui/components'
import React from 'react'
import { useTranslation } from 'react-i18next'
import Styled from '../StepSingleSkuComponentStyle'

const NotFoundedImage: React.FC = () => {
	const { t } = useTranslation()

	return (
		<Styled.EmptySkuContainer>
			<Styled.NotFoundedImage src="https://cdn-b2b-abi.global.ssl.fastly.net/admin/images/Buzz.png" alt="box" />
			<Paragraph weight="medium" style={{ margin: '8px 0' }}>
				{t('rules:NO_RESULTS')}
			</Paragraph>
			<Styled.EmptySkuMessage>{t('rules:NO_RESULTS_MESSAGE')}</Styled.EmptySkuMessage>
		</Styled.EmptySkuContainer>
	)
}

export default NotFoundedImage
