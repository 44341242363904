import { useQuery } from 'react-query'
import { loadAllItems } from '@/services/item/ItemService'
import { Item } from '@/domains'
import { SkuRuleItem } from '@/services/rules/RulesService.types'
import { useGetLoadAllSettings } from './useGetLoadAllSettings'

export const useGetLoadAllItemsByRuleId = ({
	skuIds,
}: {
	skuIds?: SkuRuleItem[]
}): {
	data: Item[] | undefined
	isLoading: boolean
} => {
	const { settings } = useGetLoadAllSettings()

	const { data, isLoading } = useQuery(
		['load-all-items-by-rule', skuIds, settings],
		() => {
			const skusIdsList = skuIds?.map((item) => (settings?.[0]?.toggles.findItemBySku ? item.sku : item.vendorItemId))

			return loadAllItems({
				vendorItemIds: skusIdsList || /* istanbul ignore next */ [],
			})
		},
		{ enabled: !!skuIds?.length },
	)

	return { data: data?.items, isLoading }
}
