import React from 'react'

function EmptySku() {
	return (
		<svg width="200" height="200" viewBox="0 0 200 200" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g opacity="0.3">
				<mask id="mask0_11585_7155" mask="luminance" maskUnits="userSpaceOnUse" x="103" y="32" width="88" height="88">
					<path
						d="M147.178 119.6C171.19 119.6 190.656 100.134 190.656 76.1222C190.656 52.1101 171.19 32.6444 147.178 32.6444C123.166 32.6444 103.7 52.1101 103.7 76.1222C103.7 100.134 123.166 119.6 147.178 119.6Z"
						fill="white"
					/>
				</mask>
				<g mask="url(#mask0_11585_7155)">
					<path
						d="M147.178 119.6C171.19 119.6 190.656 100.134 190.656 76.1222C190.656 52.1101 171.19 32.6444 147.178 32.6444C123.166 32.6444 103.7 52.1101 103.7 76.1222C103.7 100.134 123.166 119.6 147.178 119.6Z"
						fill="url(#paint0_linear_11585_7155)"
					/>
				</g>
			</g>
			<g opacity="0.3">
				<mask id="mask1_11585_7155" mask="luminance" maskUnits="userSpaceOnUse" x="0" y="93" width="74" height="75">
					<path
						d="M36.9 167.1C57.2793 167.1 73.8 150.579 73.8 130.2C73.8 109.821 57.2793 93.3 36.9 93.3C16.5207 93.3 0 109.821 0 130.2C0 150.579 16.5207 167.1 36.9 167.1Z"
						fill="white"
					/>
				</mask>
				<g mask="url(#mask1_11585_7155)">
					<path
						d="M36.9 167.1C57.2793 167.1 73.8 150.579 73.8 130.2C73.8 109.821 57.2793 93.3 36.9 93.3C16.5207 93.3 0 109.821 0 130.2C0 150.579 16.5207 167.1 36.9 167.1Z"
						fill="url(#paint1_linear_11585_7155)"
					/>
				</g>
			</g>
			<mask id="mask2_11585_7155" mask="luminance" maskUnits="userSpaceOnUse" x="2" y="5" width="193" height="162">
				<path
					d="M161.1 102.622C161.1 137.733 132.633 166.2 97.5221 166.2C62.4109 166.2 33.9443 137.733 33.9443 102.622L2.6665 103.067L15.2221 5.55554H184.433L194.333 103.078L161.1 102.622Z"
					fill="white"
				/>
			</mask>
			<g mask="url(#mask2_11585_7155)">
				<mask id="mask3_11585_7155" mask="luminance" maskUnits="userSpaceOnUse" x="33" y="38" width="129" height="129">
					<path
						d="M97.5441 166.144C132.657 166.144 161.122 137.68 161.122 102.567C161.122 67.4536 132.657 38.9889 97.5441 38.9889C62.431 38.9889 33.9663 67.4536 33.9663 102.567C33.9663 137.68 62.431 166.144 97.5441 166.144Z"
						fill="white"
					/>
				</mask>
				<g mask="url(#mask3_11585_7155)">
					<path
						d="M97.5441 166.144C132.657 166.144 161.122 137.68 161.122 102.567C161.122 67.4536 132.657 38.9889 97.5441 38.9889C62.431 38.9889 33.9663 67.4536 33.9663 102.567C33.9663 137.68 62.431 166.144 97.5441 166.144Z"
						fill="url(#paint2_linear_11585_7155)"
					/>
				</g>
			</g>
			<mask id="mask4_11585_7155" mask="luminance" maskUnits="userSpaceOnUse" x="2" y="5" width="193" height="162">
				<path
					d="M161.1 102.622C161.1 137.733 132.633 166.2 97.5221 166.2C62.4109 166.2 33.9443 137.733 33.9443 102.622L2.6665 103.067L15.2221 5.55554H184.433L194.333 103.078L161.1 102.622Z"
					fill="white"
				/>
			</mask>
			<g mask="url(#mask4_11585_7155)">
				<path
					d="M133.289 75.3778L149.122 63.2778C150.044 62.5667 151.366 62.7111 152.122 63.6L166.7 80.6556C167.533 81.6333 167.355 83.1111 166.311 83.8556L148.7 96.4667L133.289 75.3778Z"
					fill="#E5D770"
				/>
				<path
					d="M63.9554 75.3778L48.122 63.2778C47.1998 62.5667 45.8776 62.7111 45.122 63.6L30.5442 80.6556C29.7109 81.6333 29.8887 83.1111 30.9331 83.8556L48.5554 96.4667L63.9554 75.3778Z"
					fill="#E5D770"
				/>
				<path d="M148.555 96.4667H48.5552L63.9552 75.3778H133.155L148.555 96.4667Z" fill="#8C7C1B" />
				<path d="M148.566 96.4667H48.5552V196.478H148.566V96.4667Z" fill="url(#paint3_linear_11585_7155)" />
				<path
					d="M48.6886 96.4667H148.622L154.033 115.611C154.877 118.578 152.6 121.522 149.466 121.522H47.8441C44.7108 121.522 42.433 118.589 43.2775 115.611L48.6886 96.4667Z"
					fill="#E5D770"
				/>
				<path opacity="0.16" d="M148.544 121.511H48.6108V126.767H148.544V121.511Z" fill="black" />
			</g>
			<g opacity="0.5">
				<mask id="mask5_11585_7155" mask="luminance" maskUnits="userSpaceOnUse" x="19" y="55" width="23" height="23">
					<path
						d="M30.0776 77.1333C36.1282 77.1333 41.0332 72.2283 41.0332 66.1777C41.0332 60.1271 36.1282 55.2222 30.0776 55.2222C24.027 55.2222 19.1221 60.1271 19.1221 66.1777C19.1221 72.2283 24.027 77.1333 30.0776 77.1333Z"
						fill="white"
					/>
				</mask>
				<g mask="url(#mask5_11585_7155)">
					<path
						d="M30.0776 77.1333C36.1282 77.1333 41.0332 72.2283 41.0332 66.1777C41.0332 60.1271 36.1282 55.2222 30.0776 55.2222C24.027 55.2222 19.1221 60.1271 19.1221 66.1777C19.1221 72.2283 24.027 77.1333 30.0776 77.1333Z"
						fill="url(#paint4_linear_11585_7155)"
					/>
				</g>
			</g>
			<g opacity="0.5">
				<mask id="mask6_11585_7155" mask="luminance" maskUnits="userSpaceOnUse" x="156" y="147" width="23" height="23">
					<path
						d="M167.511 169.689C173.562 169.689 178.467 164.784 178.467 158.733C178.467 152.683 173.562 147.778 167.511 147.778C161.461 147.778 156.556 152.683 156.556 158.733C156.556 164.784 161.461 169.689 167.511 169.689Z"
						fill="white"
					/>
				</mask>
				<g mask="url(#mask6_11585_7155)">
					<path
						d="M167.511 169.689C173.562 169.689 178.467 164.784 178.467 158.733C178.467 152.683 173.562 147.778 167.511 147.778C161.461 147.778 156.556 152.683 156.556 158.733C156.556 164.784 161.461 169.689 167.511 169.689Z"
						fill="url(#paint5_linear_11585_7155)"
					/>
				</g>
			</g>
			<path
				d="M139.434 109.167L178.945 147.222C181.367 149.322 184.467 150.367 187.689 150.144C190.9 149.922 193.834 148.467 195.945 146.044C198.045 143.622 199.089 140.522 198.867 137.3C198.645 134.089 197.189 131.156 194.767 129.044L150.422 95.9778L139.434 109.167Z"
				fill="url(#paint6_linear_11585_7155)"
			/>
			<path
				opacity="0.16"
				d="M149.7 118.822L161.689 104.389L167.467 108.711C167.334 109.022 167.256 109.389 167.034 109.656L155.034 123.456C154.922 123.589 154.789 123.711 154.667 123.822L149.7 118.822Z"
				fill="black"
			/>
			<path
				d="M165.711 105.911L151.178 122.622C150.6 123.289 149.578 123.367 148.911 122.778L146.011 120.256C145.344 119.678 145.267 118.656 145.856 117.989L160.389 101.278C160.967 100.611 161.989 100.533 162.656 101.122L165.556 103.644C166.222 104.222 166.289 105.244 165.711 105.911Z"
				fill="url(#paint7_linear_11585_7155)"
			/>
			<path
				opacity="0.16"
				d="M145.033 114.544C147.411 112.756 149.633 110.689 151.667 108.356C153.722 105.989 155.478 103.467 156.933 100.822L150.422 95.9667L139.444 109.156L145.033 114.544Z"
				fill="black"
			/>
			<path
				opacity="0.4"
				d="M87.011 51.3222C73.4999 66.8555 75.1555 90.5 90.711 104.033C106.267 117.567 129.911 115.922 143.422 100.389C156.933 84.8555 155.278 61.2111 139.722 47.6778C124.167 34.1444 100.522 35.7889 87.011 51.3222Z"
				fill="url(#paint8_linear_11585_7155)"
			/>
			<path
				opacity="0.16"
				d="M101.589 109.944C100.722 109.311 99.8667 108.633 99.0334 107.922C83.8223 94.7 82.2778 71.5 95.5667 56.2111C106.033 44.1778 122.622 40.5778 136.633 46.0333C121.478 35.0555 100.067 37.3444 87.4889 51.8C74.1889 67.0889 75.7445 90.2889 90.9556 103.511C94.2 106.322 97.8 108.467 101.589 109.944Z"
				fill="black"
			/>
			<path
				d="M115.245 39.1556C124.1 39.1556 132.656 42.3556 139.322 48.1556C146.711 54.5778 151.156 63.5222 151.845 73.3222C152.533 83.1222 149.367 92.5889 142.933 99.9778C135.967 107.989 125.856 112.589 115.189 112.589C106.333 112.589 97.7779 109.389 91.1112 103.589C75.8223 90.3 74.2112 67.0444 87.5001 51.7667C94.4668 43.7556 104.578 39.1556 115.245 39.1556ZM115.245 32.4889C103.111 32.4889 91.0335 37.5333 82.4668 47.3889C66.7668 65.4444 68.6779 92.9111 86.7335 108.622C94.9335 115.756 105.078 119.256 115.189 119.256C127.322 119.256 139.4 114.211 147.967 104.356C163.667 86.3 161.756 58.8333 143.7 43.1222C135.489 35.9889 125.345 32.4889 115.245 32.4889Z"
				fill="url(#paint9_linear_11585_7155)"
			/>
			<defs>
				<linearGradient
					id="paint0_linear_11585_7155"
					x1="103.694"
					y1="76.1254"
					x2="190.652"
					y2="76.1254"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#DFE0E4" />
					<stop offset="1" stopColor="#EEEFF1" />
				</linearGradient>
				<linearGradient
					id="paint1_linear_11585_7155"
					x1="0"
					y1="130.204"
					x2="73.8067"
					y2="130.204"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#DFE0E4" />
					<stop offset="1" stopColor="#EEEFF1" />
				</linearGradient>
				<linearGradient
					id="paint2_linear_11585_7155"
					x1="33.9719"
					y1="102.568"
					x2="161.119"
					y2="102.568"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#DFE0E4" />
					<stop offset="1" stopColor="#EEEFF1" />
				</linearGradient>
				<linearGradient
					id="paint3_linear_11585_7155"
					x1="98.554"
					y1="196.47"
					x2="98.554"
					y2="96.4618"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#D8AF32" />
					<stop offset="1" stopColor="#D8BC32" />
				</linearGradient>
				<linearGradient
					id="paint4_linear_11585_7155"
					x1="19.118"
					y1="66.1728"
					x2="41.0395"
					y2="66.1728"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#DFE0E4" />
					<stop offset="1" stopColor="#EEEFF1" />
				</linearGradient>
				<linearGradient
					id="paint5_linear_11585_7155"
					x1="156.547"
					y1="158.733"
					x2="178.469"
					y2="158.733"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#DFE0E4" />
					<stop offset="1" stopColor="#EEEFF1" />
				</linearGradient>
				<linearGradient
					id="paint6_linear_11585_7155"
					x1="140.4"
					y1="124.83"
					x2="197.866"
					y2="121.283"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#898989" />
					<stop offset="1" stopColor="#B9B9B9" />
				</linearGradient>
				<linearGradient
					id="paint7_linear_11585_7155"
					x1="145.054"
					y1="112.616"
					x2="166.501"
					y2="111.292"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FFF250" />
					<stop offset="1" stopColor="#DDC703" />
				</linearGradient>
				<linearGradient
					id="paint8_linear_11585_7155"
					x1="77.9395"
					y1="78.1437"
					x2="152.484"
					y2="73.5044"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="white" />
					<stop offset="1" stopColor="#D9EDFE" />
				</linearGradient>
				<linearGradient
					id="paint9_linear_11585_7155"
					x1="86.7359"
					y1="108.618"
					x2="143.695"
					y2="43.1198"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#898989" />
					<stop offset="1" stopColor="#B9B9B9" />
				</linearGradient>
			</defs>
		</svg>
	)
}

export default EmptySku
