import React, { memo } from 'react'
import { Tabs } from '@hexa-ui/components'
import { useFeatureToggleV2 } from '@/hooks'
import * as FeatureToggle from '@/utils/featureToggle'
import { useTranslation } from 'react-i18next'

export const TabHeader: React.FC = memo(() => {
	const isShowExclusionListEnabled = useFeatureToggleV2(FeatureToggle.SHOW_EXCLUSION_LIST)
	const { t } = useTranslation()

	return (
		<>
			<Tabs.Trigger value="tab-defaultEarningRules">{t('rules:EARNING_LIST')}</Tabs.Trigger>
			{isShowExclusionListEnabled && <Tabs.Trigger value="tab-exclusionList">{t('rules:EXCLUSION_LIST')}</Tabs.Trigger>}
		</>
	)
})
