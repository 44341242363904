import { styled } from '@hexa-ui/theme'

export const TableContainer = styled('div', {
	display: 'block',
	width: '100%',
	marginTop: '$4',
	borderRadius: '24px',
	boxShadow: '0 4px 8px 0 rgba(0,0,0,0.16)',
	color: 'rgba(0,0,0,0.9)',
	'& h5': {
		margin: '0px',
	},
	'& ul': {
		paddingInlineStart: '0px',
	},
	'& th:nth-child(2) div, th:nth-child(3) div': {
		justifyContent: 'center',
	},
	'& th:nth-child(2) div h5, td:nth-child(2), td:nth-child(3)': {
		textAlign: 'center',
	},
})
