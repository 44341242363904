const downloadCSV = async (fileName: string, data: Blob): Promise<void> => {
	const blob = new Blob([data], { type: 'text/csv' })
	const url = window.URL.createObjectURL(blob)

	const a = document.createElement('a')
	a.href = url
	a.download = `${fileName}.csv`

	document.body.appendChild(a)
	a.click()
	a.remove()

	window.URL.revokeObjectURL(url)
}

export default downloadCSV
