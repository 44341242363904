import { styled, theme } from '@hexa-ui/theme'
import { Card as HexaCard } from '@hexa-ui/components'

const checkBoxSize = 24

const Card = styled(HexaCard, {
	padding: 16,
	cursor: 'pointer',
	borderRadius: 8,
	height: 78,
	variants: {
		isSelected: {
			true: {
				boxShadow: `0 0 0 4px ${theme.colors.semanticInfoBasis} !important`,
			},
		},
	},
})

const CardGrid = styled('div', {
	display: 'grid',
	gridTemplateColumns: '20px 1fr',
	gap: 16,
})

const LeftBox = styled('div', {
	width: '50px',
})

const RightBox = styled('div', {
	flex: '1',
})

const Checkbox = styled('div', {
	width: `${checkBoxSize}px`,
	height: `${checkBoxSize}px`,
	borderRadius: '50%',
	border: '1px solid black',
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
})

const Checked = styled('div', {
	width: `${checkBoxSize / 2}px`,
	height: `${checkBoxSize / 2}px`,
	borderRadius: '50%',
	backgroundColor: 'black',
})

const GridRadioCards = styled('div', {
	display: 'grid !important',
	gridTemplateColumns: '1fr 1fr',
	gap: 16,
})

export default {
	Card,
	CardGrid,
	LeftBox,
	RightBox,
	Checkbox,
	Checked,
	GridRadioCards,
}
