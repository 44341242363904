import React from 'react'
import { Paragraph } from '@hexa-ui/components'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import { AudienceCardContent, AudienceCardItem } from './AudienceCard.styles'
import { CreateExclusionRuleFormData } from '../../../../../context/CreateExclusionListProvider/CreateExclusionListProvider.types'

export const AudienceCard = () => {
	const { t } = useTranslation()
	const { getValues } = useFormContext<CreateExclusionRuleFormData>()
	const audienceName = getValues('audienceName')
	const estimatedPocs = getValues('estimatedPocCount')

	return (
		<AudienceCardContent>
			<AudienceCardItem>
				<Paragraph weight="semibold" size="small" data-testid="audience-name">
					{t('rules:CREATE_EXCLUSION_RULES.AUDIENCE_CARD.AUDIENCE_NAME')}
				</Paragraph>
				<Paragraph>{audienceName}</Paragraph>
			</AudienceCardItem>

			<AudienceCardItem>
				<Paragraph weight="semibold" size="small">
					{t('rules:CREATE_EXCLUSION_RULES.AUDIENCE_CARD.ESTIMATED_POCS_TARGETED')}
				</Paragraph>

				<Paragraph>{estimatedPocs}</Paragraph>
			</AudienceCardItem>
		</AudienceCardContent>
	)
}
