import MembershipFeatures from '@/domains/authorization/MembershipFeatures'
import { PermissionLevel } from '@/domains/enums'

export type Permission = {
	feature: MembershipFeatures
	permissionLevel: PermissionLevel
}

type Props = {
	isFeatureAuthorized: (featureToggle: boolean, requiredPermission?: Permission) => boolean
	isToggledCreateEarningRulesEnabled: boolean
}

export const createEarningRuleButtonEnabled = ({
	isFeatureAuthorized,
	isToggledCreateEarningRulesEnabled,
}: Props): boolean => {
	const isEnableCreateEarningRule = isFeatureAuthorized(isToggledCreateEarningRulesEnabled, {
		feature: 'CreateEarningRules',
		permissionLevel: PermissionLevel.Delete,
	})

	return isEnableCreateEarningRule
}
