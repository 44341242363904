import React from 'react'
import { useTranslation } from 'react-i18next'
import { Heading, Paragraph, Input } from '@hexa-ui/components'
import { Controller, useFormContext } from 'react-hook-form'
import { Card, Content, Header } from './SettingsCard.styles'
import { CreateExclusionRuleFormData } from '../../Pages/CreateExclusionPageRule/context/CreateExclusionListProvider/CreateExclusionListProvider.types'

export function SettingsCardExclusion() {
	const { t } = useTranslation()

	const { control } = useFormContext<CreateExclusionRuleFormData>()

	return (
		<Card border="medium" elevated="small" data-testid="settings-card">
			<Content>
				<Header>
					<Heading size="H3">{t('rules:CREATE_EARNING_RULES.EARNING_SECTION.SETTINGS')}</Heading>

					<Paragraph colortype="secondary">
						{t('rules:CREATE_EARNING_RULES.EARNING_SECTION.SETTINGS_DESCRIPTION')}
					</Paragraph>
				</Header>

				<Controller
					name="description"
					control={control}
					defaultValue={undefined}
					render={({ field: { ...field }, fieldState: { error } }) => (
						<Input
							{...field}
							errorText={error?.message}
							hasError={!!error?.message}
							label={t('rules:CREATE_EARNING_RULES.EARNING_SECTION.RULE_DESCRIPTION')}
							placeholder={t('rules:CREATE_EARNING_RULES.EARNING_SECTION.ENTER_RULE_DESCRIPTION')}
							width="100%"
						/>
					)}
				/>
			</Content>
		</Card>
	)
}
