import React from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@hexa-ui/components'
import { Edit2 } from '@hexa-ui/icons'
import { ToolbarExtraProps } from './ToolbarExtra.types'

export const ToolbarExtra = ({ handleButton }: ToolbarExtraProps) => {
	const { t } = useTranslation()

	return (
		<Button icon={Edit2} leading size="medium" variant="secondary" onClick={handleButton}>
			{t('rules:CREATE_EARNING_RULES.EARNING_SECTION.ADD_SKUS')}
		</Button>
	)
}
