import React from 'react'
import { useTranslation } from 'react-i18next'
import EmptyStateTable from '@/components/icons/EmptyStateTable/EmptyStateTable'
import Styled from '../StepSingleSkuComponentStyle'

const EmptyItemImage: React.FC = () => {
	const { t } = useTranslation()

	return (
		<Styled.EmptySkuContainer>
			<EmptyStateTable />
			<Styled.EmptySkuMessage>{t('rules:EMPTY_SKU_CONTAINER')}</Styled.EmptySkuMessage>
		</Styled.EmptySkuContainer>
	)
}

export default EmptyItemImage
