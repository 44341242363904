import axios, { AxiosError, AxiosInstance } from 'axios'
import { useAuthenticationService as authenticationService } from 'admin-portal-shared-services'
import { getUserCountry } from '@/utils/tokenB2CHelper'
import acceptedCountries, { AcceptedCountry } from './acceptedCountries'
import requestTraceId from './requestTraceId'
import { hasText } from './string'
import getBaseApiUrl from './getBaseApiUrl'

export function isExpired(exp: number): boolean {
	return Date.now() >= exp * 1000
}

function redirect(): void {
	window.location.href = '/'
	throw new Error('Access Denied')
}

export const withBaseURL = (instance: AxiosInstance): AxiosInstance => {
	instance.interceptors.request.use((request) => {
		const prevUrl = request.url
		if (hasText(prevUrl)) {
			request.url = getBaseApiUrl() + prevUrl
		}
		return request
	})
	return instance
}

export const withAuthentication = (instance: AxiosInstance): AxiosInstance => {
	instance.interceptors.request.use(
		(config) => {
			const authentication = authenticationService()
			const token = authentication.getAuthHeader()
			const zone = getUserCountry()

			if (token) {
				const JwtExp = authentication.getJWTExpiration()
				if (isExpired(parseInt(JwtExp as string, 10))) {
					authentication.setAuthHeader('')
					redirect()
				}

				config.headers.Accept = 'application/json'
				config.headers.Authorization = config.headers?.Authorization || token
				config.headers.requestTraceId = config.headers?.requestTraceId || requestTraceId()
			} else {
				redirect()
			}

			if (acceptedCountries.includes(zone as AcceptedCountry)) {
				config.headers.country = zone
			}

			return config
		},
		(error) => {
			return Promise.reject(new Error(`Request interceptor error: ${error}`))
		},
	)

	instance.interceptors.response.use(
		(response) => {
			return response
		},
		(error) => {
			if (
				error.response.config.url === '/api/rewards-admin-service/v1/cpp-transactions' &&
				error.response.status === 502 &&
				error.response.data.details[0].includes('CONFLICT')
			) {
				return Promise.reject(new Error(error.response.data.details[0]))
			}

			const axiosError = error as AxiosError
			return Promise.reject(
				new Error(`Response interceptor error: ${error}`, {
					cause: axiosError,
				}),
			)
		},
	)

	return instance
}

const axiosInstance = withAuthentication(withBaseURL(axios.create()))

export default axiosInstance
