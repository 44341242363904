import { RulesStore } from '@/stores'
import { useStore } from 'effector-react'
import React, { useEffect } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import UploadFile from './UploadFile'

const StepMultipleSkuComponent = ({ hasErrors }: { hasErrors: boolean }) => {
	const { t } = useTranslation()
	const { skuUploadFile } = useStore(RulesStore)
	const fileName = skuUploadFile?.[0]?.name ?? ''
	const { setError, clearErrors } = useFormContext()

	useEffect(() => {
		clearErrors()
		if (hasErrors) {
			setError('uploadFile', {
				type: 'generalError',
				message: `${t('ERROR_MESSAGE.UPLOAD_FAILED', { name: fileName })}`,
			})
		}
	}, [clearErrors, fileName, hasErrors, setError, t])

	return <UploadFile inputName="uploadFile" fileName={fileName} />
}

export default StepMultipleSkuComponent
