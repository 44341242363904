import { EditRedeemableItemRequest, PagedResponse } from '@/domains'
import { ComboType } from '@/domains/enums'
import CreateRedeemableItemRequest from '@/domains/redeemable/CreateRedeemableItemRequest'
import { LoadRedeemableByStatusRequest, LoadRedeemableRequestV2 } from '@/domains/redeemable/LoadRedeemableRequest'
import { RedeemableV2 } from '@/domains/redeemable/Redeemable'
import { RedeemableBulkData } from '@/domains/redeemable/RedeemableBulkData'
import { RedeemableItem } from '@/domains/redeemable/RedeemableItem'
import RedeemablePutItemRequest from '@/domains/redeemable/RedeemablePutItemRequest'
import axios from '@/utils/axiosInstance'
import { castListToStringParam } from '@/utils/axiosParams'
import { addAttributeName } from '@/utils/redeemable'
import { useLogService as logService } from 'admin-portal-shared-services'
import { AxiosResponse } from 'axios'

const endPointV2 = `/rewards-service/v2`
const baseUrlRewardsAdminService = `/rewards-admin-service/v1`
const baseUrlRewardsAdminServiceV2 = `/rewards-admin-service/v2`
const log = logService()

export async function load(
	props: LoadRedeemableRequestV2,
	isToggleCombos3PDIntegrationEnabled: boolean,
): Promise<PagedResponse<RedeemableV2>> {
	const toggleCombos3PD = isToggleCombos3PDIntegrationEnabled
	const params = toggleCombos3PD
		? {
				...props,
				redeemableIds: castListToStringParam(props.redeemableIds),
			}
		: {
				...props,
				vendorIds: castListToStringParam(props.vendorIds),
				redeemableIds: castListToStringParam(props.redeemableIds),
			}

	return axios
		.get<PagedResponse<RedeemableV2>>(`${endPointV2}/redeemables`, { params })
		.then((response) => response.data)
		.catch((error) => {
			log.error(`Error endpoint: ${endPointV2}`, error)
			throw error
		})
}

export const updateCombo = async (params: RedeemablePutItemRequest): Promise<AxiosResponse<RedeemableV2>> => {
	const { redeemableId, ...body } = params
	return axios
		.patch(`${endPointV2}/redeemables/${redeemableId}`, { points: body.points })
		.then((response) => response.data)
		.catch((error) => {
			log.error(`Error endpoint: ${endPointV2}`, error)
			throw error
		})
}

export const deleteCombo = async (redeemableId: string): Promise<void> => {
	return axios
		.delete(`${endPointV2}/redeemables/${redeemableId}`)
		.then((response) => response.data)
		.catch((error) => {
			log.error(`Error endpoint: ${endPointV2}`, error)
			throw error
		})
}

export async function deleteComboBulk(redeemableIds: Array<string>): Promise<void> {
	const url = `/v1/loyalty-business-relay/v2/redeemables:batch-delete`
	const data = {
		redeemableIds,
	}
	try {
		await axios.post<void>(url, data)
	} catch (error) {
		log.error(`Error endpoint: ${url}`, error)
		throw error
	}
}

export const insertComboBulk = async (data: RedeemableBulkData): Promise<void> => {
	const url = `${baseUrlRewardsAdminService}/redeemables/combos`
	try {
		await axios.post(url, data)
	} catch (error) {
		log.error(`Error endpoint: ${url}`, error)
		throw error
	}
}

export async function loadRedeemablesByStatus(
	params: LoadRedeemableByStatusRequest,
	isToggleCombos3PDIntegrationEnabled: boolean,
): Promise<PagedResponse<RedeemableV2<ComboType>>> {
	const { status, page, pageSize, redeemableIds } = params
	const toggleCombos3PD = isToggleCombos3PDIntegrationEnabled
	const propsEndPoint = toggleCombos3PD ? { status, page, pageSize, redeemableIds } : params
	try {
		const response = await axios.get<PagedResponse<RedeemableV2<ComboType>>>(
			`${baseUrlRewardsAdminService}/redeemables/combos`,
			{
				params: {
					...propsEndPoint,
					status: castListToStringParam(propsEndPoint.status),
					redeemableIds: castListToStringParam(propsEndPoint.redeemableIds),
				},
			},
		)
		return addAttributeName(response.data)
	} catch (error) {
		log.error(`Error endpoint: ${endPointV2}`, error)
		throw error
	}
}

export async function createRedeemable(data: CreateRedeemableItemRequest[]): Promise<RedeemableItem> {
	try {
		const response = await axios.post(`${baseUrlRewardsAdminServiceV2}/redeemables`, {
			redeemables: data,
		})
		return response.data
	} catch (error) {
		log.error(`Error endpoint: ${baseUrlRewardsAdminServiceV2}/redeemables`, error)
		throw error
	}
}

export async function editRedeemableItem(
	data: EditRedeemableItemRequest,
	redeemableItemId: string,
): Promise<RedeemableItem> {
	try {
		const url = `${baseUrlRewardsAdminService}/redeemables/${redeemableItemId}`
		const response = await axios.patch(url, data)
		return response.data
	} catch (error) {
		log.error(`Error endpoint: ${baseUrlRewardsAdminService}/redeemables/${redeemableItemId}`, error)
		throw error
	}
}

export const deleteRedeemable = async (redeemableId: string): Promise<void> => {
	const url = `${baseUrlRewardsAdminService}/redeemables/${redeemableId}`
	return axios
		.delete(url)
		.then((response) => response.data)
		.catch((error) => {
			log.error(`Error endpoint: ${url}`, error)
			throw error
		})
}

export async function getCSVFile(vendorId: string): Promise<string> {
	const url = `${baseUrlRewardsAdminService}/redeemables/generate-csv?vendorId=${vendorId}`
	try {
		const response = await axios.get(url)
		return response.data
	} catch (error) {
		log.error(`Error endpoint: ${url}`, error)
		throw error
	}
}

export const syncCombo = async (): Promise<AxiosResponse<RedeemableV2>> => {
	try {
		const response = await axios.post(`${baseUrlRewardsAdminServiceV2}/redeemables/vendors/synchronize`)
		return response
	} catch (error) {
		log.error(`Error endpoint: ${baseUrlRewardsAdminServiceV2}`, error)
		throw error
	}
}
