/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from 'react'
import { FailureReason, Item, ScreenName } from '@/domains'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { ValidateSkuUseCase, ValidateEarningRuleSkuUseCase } from '@/usecase'
import { hasElements } from '@/utils/array'
import { Box } from '@material-ui/core'
import { useFormContext, useFieldArray } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Button } from '@hexa-ui/components'
import { SkuBox, SkuBoxErrors } from '@/stores/challengeCreation'
import { SkuFound } from '@/usecase/item/ValidateEarningRuleSkuUseCase'
import { hasText } from '@/utils/string'
import { useFeatureToggleV2 } from '@/hooks'
import * as FeatureToggle from '@/utils/featureToggle'
import SkuFields from './SkuFields/SkuFields'

type Props = {
	disabled?: boolean
	isEditing?: boolean
	isEmpty: boolean
	hideQuantity?: boolean
	listSkus?: SkuBox[]
}

interface InputListProps {
	sku: string
	item?: Item
	earningRulesItem?: SkuFound
	errorsInput: SkuBoxErrors
}

export type ComboFormFieldArray = Array<{ comboId?: string; points?: number }>

const SkuBoxContainerView: React.FC<Props> = ({ disabled, isEditing, isEmpty, hideQuantity, listSkus }: Props) => {
	const {
		control,
		formState: { errors },
		clearErrors,
		getValues,
		setError,
		setValue,
		trigger,
	} = useFormContext()

	const { t } = useTranslation()
	const userInfo = useGetUserInfo()

	const isToggleEarningRulesByVendorEnabled = useFeatureToggleV2(FeatureToggle.DEV_MULTIVENDOR_EARNING_RULES_BY_VENDOR)
	const multiVendorToggle = useFeatureToggleV2(FeatureToggle.DEV_MULTI_VENDOR_EARNING_RULES)
	const isToggleSkuCopyPasteEnabled = useFeatureToggleV2(FeatureToggle.COPY_PASTE_SKU)

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'skus',
		keyName: 'key',
	})

	const skus = getValues('skus')

	useEffect(() => {
		if (!isEmpty) {
			listSkus?.forEach((sku, index) => sku.errorsInput && setInputError(sku.errorsInput, `skus.${index}.sku`, false))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isEmpty, fields])

	useEffect(() => {
		if (isEmpty) {
			setTimeout(() => append({}), 0)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const getFieldBase = (name: string) => {
		const words = name.split('.')
		if (words.length === 3) words.pop()
		return words.join('.')
	}

	const setMultivendorData = useCallback(
		(item: Item, name: string) => {
			const fieldBase = getFieldBase(name)
			setValue(`${fieldBase}.vendorId`, item?.vendorId)
			setValue(`${fieldBase}.vendorItemId`, item?.vendorItemId)
			setValue(`${fieldBase}.itemId`, item?.id)
		},
		[setValue],
	)

	const hasAlreadyAnUniqueError = () => {
		const uniqueErrors = (errors.skus || []).filter((error: { sku: { type: string } }) => {
			return error?.sku && error?.sku.type === 'unique'
		})
		return hasElements(uniqueErrors)
	}

	const handleItemAvailable = useCallback(
		(item: Item, name: string, earningRules?: string) => {
			clearErrors(name)
			AnalyticsService.events.skuAdded({
				earning_sku: earningRules ?? null,
				form_name: 'Challenge Creation - 3rd Step',
				screen_name: 'Challenge Creation - 3rd Step',
				sku: item.sku,
				...userInfo,
			})
			setMultivendorData(item, name)
			return true
		},
		[clearErrors, setMultivendorData, userInfo],
	)

	const createListForMultipleSkus = (skusNotFormatted: string): Array<string> => {
		const skuFormatted = skusNotFormatted.trim().split(/[,\s;]+/)

		if (hasElements(skuFormatted) && skuFormatted.length > 1) return skuFormatted

		return [skusNotFormatted]
	}

	const removeUnecessaryInput = useCallback((inputIndex) => remove(inputIndex), [remove])

	const validadeSku = useCallback(
		async (skuList: Array<string>) => {
			const skusUpdated = getValues('skus')

			const earningRules = await ValidateEarningRuleSkuUseCase.executeList({
				skus: skuList,
				isToggleEarningRulesByVendorEnabled: isToggleEarningRulesByVendorEnabled!,
				multiVendorToggle: multiVendorToggle!,
			})
			const skusValidated = await ValidateSkuUseCase.executeList(skuList)
			const inputList: Array<InputListProps> = []
			skuList.forEach((sku) => {
				const item = skusValidated.find((itemValid) => itemValid?.sku === sku) ?? undefined
				const earningRulesItem = earningRules.find((itemValid) => itemValid?.sku.id === sku)
				const isDuplicate =
					inputList.filter((input: InputListProps) => input.sku === sku).length > 0 ||
					skusUpdated.filter((itemValid: { sku: string }) => itemValid?.sku === sku).length > 0

				inputList.push({
					sku,
					item,
					earningRulesItem,
					errorsInput: {
						isDuplicate,
						isNotExist: !item,
					},
				})
			})

			return inputList
		},
		[isToggleEarningRulesByVendorEnabled, multiVendorToggle],
	)

	const setInputError = useCallback(
		(error: SkuBoxErrors, name: string, isTrack: boolean) => {
			if (error?.isDuplicate) {
				trigger(name)
				if (isTrack)
					AnalyticsService.events.error({
						screen_name: ScreenName.CreateChallenge3rdStep,
						failure_reason: FailureReason.DuplicatedSku,
						form_name: 'Challenge Creation - 3rd Step',
						...userInfo,
					})
			} else if (error?.isNotExist) {
				setError(name, { message: t('ERROR_MESSAGE.NO_SKU_FOUND_BY_ID'), type: 'server' })
				if (isTrack)
					AnalyticsService.events.error({
						screen_name: ScreenName.CreateChallenge3rdStep,
						failure_reason: FailureReason.NoSkuFounded,
						form_name: 'Challenge Creation - 3rd Step',
						...userInfo,
					})
			}
		},
		[setError, t, trigger, userInfo],
	)

	const returnInputs = (quantitySku: number, input: InputListProps, index: number) => {
		const name = `skus.${quantitySku + index}`
		const inputSkuName = `${name}.sku`
		append({})
		setValue(inputSkuName, input.sku)
		setValue(`${name}.errorsInput`, input.errorsInput)
		if (input.errorsInput && (input.errorsInput.isDuplicate || input.errorsInput.isNotExist))
			setInputError(input.errorsInput, inputSkuName, true)
		else handleItemAvailable(input.item!, name, input.earningRulesItem?.parentRule?.ruleId)
	}

	const generateInputs = (inputList: Array<InputListProps>) => {
		const quantitySku = listSkus?.length ?? 1
		return inputList.forEach((input, index) => returnInputs(quantitySku, input, index))
	}

	const handleItemDoesNotExist = (name: string) => {
		setError(name, { message: t('ERROR_MESSAGE.NO_SKU_FOUND_BY_ID'), type: 'server' })
		setValue(`${name.replace('.sku', '')}.errorsInput`, { isDuplicate: false, isNotExist: true })
		AnalyticsService.events.error({
			screen_name: ScreenName.CreateChallenge3rdStep,
			failure_reason: FailureReason.NoSkuFounded,
			form_name: 'Challenge Creation - 3rd Step',
			...userInfo,
		})
		return Promise.resolve(false)
	}

	const handleSkuValidation = async (value: string, name: string): Promise<boolean> => {
		if (isToggleSkuCopyPasteEnabled) {
			const skuList = createListForMultipleSkus(value)
			if (skuList.length > 1) return handleSkuValidationList(skuList)
		}

		const valueFormatted = value.trim()

		if (!hasText(valueFormatted)) {
			await trigger(name)
			return Promise.resolve(false)
		}
		await trigger(name)

		if (hasAlreadyAnUniqueError()) {
			setValue(`${name.replace('.sku', '')}.errorsInput`, { isDuplicate: true, isNotExist: false })
			AnalyticsService.events.error({
				screen_name: ScreenName.CreateChallenge3rdStep,
				failure_reason: FailureReason.DuplicatedSku,
				form_name: 'Challenge Creation - 3rd Step',
				...userInfo,
			})
			return Promise.resolve(false)
		}
		const earningRules = await ValidateEarningRuleSkuUseCase.execute({
			sku: valueFormatted,
			isToggleEarningRulesByVendorEnabled: isToggleEarningRulesByVendorEnabled!,
			multiVendorToggle: multiVendorToggle!,
		})
		const item = await ValidateSkuUseCase.execute(valueFormatted)
		if (!item) {
			return handleItemDoesNotExist(name)
		}
		setValue(`${name.replace('.sku', '')}.errorsInput`, { isDuplicate: false, isNotExist: false })
		return handleItemAvailable(item, name, earningRules?.parentRule?.ruleId)
	}

	const handleSkuValidationList = async (skuList: Array<string>): Promise<boolean> => {
		const quantitySku = (listSkus?.length ?? 1) - 1

		const inputList = await validadeSku(skuList)
		generateInputs(inputList)
		clearTimeout(100)
		setTimeout(() => removeUnecessaryInput(quantitySku), 400)

		inputList.forEach((input): boolean | undefined => {
			if (input.errorsInput.isDuplicate || input.errorsInput.isNotExist) return false
			return undefined
		})

		return true
	}

	return (
		<>
			{fields.map((value, index) => (
				<SkuFields
					key={value.key}
					handleRemoveItem={remove}
					index={index}
					defaultValue={skus && skus.length > 0 ? skus[index] : null}
					disableRemoveButton={fields.length === 1}
					disabled={disabled}
					isEditing={isEditing}
					handleSkuValidation={handleSkuValidation}
					hideQuantity={hideQuantity}
				/>
			))}
			{!disabled && (
				<Box mt={3}>
					<Button
						id="add-filter-btn"
						variant="secondary"
						onClick={() => append({}, { shouldFocus: false })}
						disabled={!!disabled}
						type="button"
					>
						{t('challenge:SKU_ADD_BUTTON')}
					</Button>
				</Box>
			)}
		</>
	)
}

export default SkuBoxContainerView
