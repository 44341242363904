import { RecordType } from '@/domains'
import { ComboEvents, PageLevelEvents, SettingsConfigurationEvents } from '@/stores'
import { UpdateProgramUseCase, UpdateRedeemLimitUseCase } from '@/usecase'
import { useTranslation } from 'react-i18next'
import AnalyticsService from 'services/analytics/AnalyticsService'
import Program from 'domains/program/Program'
import { UpdateRedeemParams } from '@/usecase/settings/UpdateRedeemLimitUseCase'
import { SettingDefaultConfiguration } from '@/domains/settings'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import { useFeatureToggleV2 } from '@/hooks'
import * as FeatureToggle from '@/utils/featureToggle'

export interface UseUpdateRedeemLimitResult {
	updateRedeemLimit: (params: UpdateRedeemParams, program: Program, redeemLimit: number) => void
	updateProgramUseCase: (program: Program, redeemLimit: number) => void
}

const useUpdateRedeemLimit = (): UseUpdateRedeemLimitResult => {
	const { t } = useTranslation()
	const userInfo = useGetUserInfo()
	const isConfigByTier = useFeatureToggleV2(FeatureToggle.CONFIG_INITIAL_BALANCE_REDEEM_LIMIT_BY_TIER)
	const isSettingsConfigByZoneEnabled = useFeatureToggleV2(FeatureToggle.SETTINGS_CONFIG_BY_ZONE)

	const handleUpdateRedeemLimit = (program: SettingDefaultConfiguration | Program, redeemLimit: number) => {
		if (isConfigByTier) {
			SettingsConfigurationEvents.setSettingsData(program as SettingDefaultConfiguration)
		} else {
			ComboEvents.setProgramDataForCombo(program as Program)
		}
		PageLevelEvents.showNotification({
			route: '/membership/combos',
			notificationType: 'success',
			message: t('combo:NOTIFICATION.EDIT_MAX_QUANTITY.SUCCESS'),
		})
		AnalyticsService.events.recordUpdated({
			record_type: RecordType.RedeemMaxQuantity,
			record_id: null,
			record_additional_info: `New Max Quantity: ${redeemLimit}`,
			form_name: null,
			...userInfo,
		})
	}

	const updateProgramUseCase = (program: Program, redeemLimit: number) => {
		UpdateProgramUseCase.execute(program).then((updatedProgram) => {
			if (updatedProgram) {
				handleUpdateRedeemLimit(program, redeemLimit)
			} else {
				PageLevelEvents.showNotification({
					route: '/membership/combos',
					notificationType: 'error',
					message: t('combo:NOTIFICATION.EDIT_MAX_QUANTITY.ERROR'),
				})
			}
			ComboEvents.setShowEditComboQuantityDialog(false)
		})
	}

	const updateRedeemLimit = (params: UpdateRedeemParams, program: Program, redeemLimit: number) => {
		UpdateRedeemLimitUseCase.execute({
			...params,
			isToggleSettingsConfigByZoneEnabled: isSettingsConfigByZoneEnabled!,
		})
			.then(() => {
				handleUpdateRedeemLimit(program, redeemLimit)
			})
			.catch(() => {
				PageLevelEvents.showNotification({
					route: '/membership/combos',
					notificationType: 'error',
					message: t('combo:NOTIFICATION.EDIT_MAX_QUANTITY.ERROR'),
				})
			})
			.finally(() => {
				ComboEvents.setShowEditComboQuantityDialog(false)
			})
	}

	return {
		updateRedeemLimit,
		updateProgramUseCase,
	}
}

export default useUpdateRedeemLimit
