import { useMemo } from 'react'
import { useAuthenticationService } from 'admin-portal-shared-services'
import { useCurrentTier } from '../tier/useCurrentTier'
import useCurrentVendor from '../vendor/useCurrentVendor'
import useCurrentZone from '../zone/useCurrentZone'

export type userMapResult = {
	tier: string | null
	user: string
	vendor: string | null
	zone: string | null
}

const useGetUserInfo = (): userMapResult => {
	const { selectedTierKey: tier } = useCurrentTier()
	const vendor = useCurrentVendor()?.vendorId ?? null
	const zone = useCurrentZone()
	const user = useAuthenticationService().getUserEmailB2C()

	const userInfo = useMemo(() => ({ tier, user, vendor, zone }), [tier, user, vendor, zone])

	return userInfo
}

export default useGetUserInfo
