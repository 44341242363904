import React from 'react'
import Styled from './styles'

interface IDownloaderFileProps {
	url: string
	label: string
	onClick?: () => void
}

const DownloaderFile = ({ url, label, onClick }: IDownloaderFileProps) => {
	return (
		<Styled.DownloadCSV type="button" onClick={onClick} data-testid="downloader">
			<Styled.DownloadIcon size="large" fr="someValue" />
			<a href={url} download>
				{label}
			</a>
		</Styled.DownloadCSV>
	)
}

export default DownloaderFile
