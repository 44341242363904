import React, { ReactNode } from 'react'
import { useFeatureToggleV2 } from '@/hooks'

type Props = {
	featureName: string
	children: ReactNode
	fallbackComponent?: ReactNode | null
}

const FeatureToggle: React.FC<Props> = ({ featureName, children, fallbackComponent }: Props) => {
	const featureToggleOn = useFeatureToggleV2(featureName)

	return <>{featureToggleOn ? children : fallbackComponent}</>
}

FeatureToggle.defaultProps = {
	fallbackComponent: <></>,
}

export default FeatureToggle
