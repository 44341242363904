import { useState } from 'react'
import { CardType } from '@/routes/rules/RulesRedesign/Pages/CreateRulePageV2/RuleTypeSelection/RuleTypeSelection.types'
import { useNavigateToRules } from '@/routes/rules/hooks/useNavigateToRules'

interface UseCardSelectionReturn {
	selectedCard: CardType | null
	handleCardClick: (cardType: CardType) => void
	handleCancel: () => void
	handleSubmit: () => void
	handleCardSelect: (cardType: CardType, onCardSelect: (cardType: CardType) => void) => void
}

const useCardSelection = (initialCard: CardType | null = null): UseCardSelectionReturn => {
	const [selectedCard, setSelectedCard] = useState<CardType | null>(initialCard)
	const { goToRulesList, goToCreateEarningRule, goToCreateExclusion } = useNavigateToRules()

	const handleCardClick = (cardType: CardType): void => {
		setSelectedCard(cardType)
	}

	const handleCancel = (): void => {
		goToRulesList()
	}

	const handleSubmit = (): void => {
		if (selectedCard === 'EARNING_RULE') {
			goToCreateEarningRule()
		} else {
			goToCreateExclusion()
		}
	}

	const handleCardSelect = (cardType: CardType, onCardSelect: (cardType: CardType) => void): void => {
		handleCardClick(cardType)
		onCardSelect(cardType)
	}

	return {
		selectedCard,
		handleCardClick,
		handleCancel,
		handleSubmit,
		handleCardSelect,
	}
}

export default useCardSelection
