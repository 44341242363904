import SkuForm from '@/domains/sku/SkuForm'
import { RulesEvents, RulesStore } from '@/stores'

import { useStoreMap } from 'effector-react'
import React, { useState } from 'react'

import StepSingleSkuComponentView from './StepSingleSkuComponentView'
import { useGetSkuForRule } from '../hooks/useGetSkuForRule'

const StepSingleSkuComponent: React.FC = () => {
	const skuItem = useStoreMap({ store: RulesStore, keys: [], fn: (state) => state.singleSku })
	const [itemNotFound, setItemNotFound] = useState<boolean>(false)
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [searchText, setSearchText] = useState<string | undefined>()
	const { getSkuForRule } = useGetSkuForRule()

	const handleSearch = async () => {
		const skuId = searchText
		setIsLoading(true)

		const result = await getSkuForRule({
			skuId,
		})

		if (result) {
			RulesEvents.addSingleSku(result.sku)
			setItemNotFound(false)
			setIsLoading(false)
		} else {
			setItemNotFound(true)
			RulesEvents.addSingleSku({} as SkuForm)
			setIsLoading(false)
		}
	}

	const handleClear = () => {
		RulesEvents.addSingleSku({} as SkuForm)
		setItemNotFound(false)
	}

	return (
		<StepSingleSkuComponentView
			handleSearch={handleSearch}
			handleClear={handleClear}
			skuItem={skuItem}
			itemNotFound={itemNotFound}
			isLoading={isLoading}
			setSearchText={setSearchText}
		/>
	)
}

export default StepSingleSkuComponent
