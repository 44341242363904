import { SettingsLevel, SettingsType } from '@/domains/enums/settings'
import { OfferCategory } from '@/domains/offerCategory/offerCategory'
import { SettingsRemovalCategoryConfig } from '@/domains/settings'
import { LoadDefaultConfigParams, loadReasonFields } from '@/services/settings/SettingsService'
import { DEFAULT_TIER_FOR_PPP } from '@/utils/constants'

export const execute = async (): Promise<OfferCategory[]> => {
	const params: LoadDefaultConfigParams = {
		levels: SettingsLevel.ZONE,
		settingIds: '',
		tiers: [DEFAULT_TIER_FOR_PPP],
		types: SettingsType.REMOVAL_CATEGORIES,
	}

	const response = await loadReasonFields<SettingsRemovalCategoryConfig>(params)

	const result = response[0]?.removalCategories || []
	return result
}
