import { SettingDefaultConfiguration } from '@/domains/settings'
import { useAnalyticsServiceEventButtonClicked } from '@/hooks/analyticsService/useAnalyticsServiceEventButtonClicked'
import { useAnalyticsServiceEventLinkClicked } from '@/hooks/analyticsService/useAnalyticsServiceEventLinkClicked'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { PageLevelEvents, PricePerPointConfiguration } from '@/stores'
import { useIsTogglePricePerPointSettingsEnabled } from '@/utils/featureToggle/featureToggleHelper'
import { Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import useAuditLogZoneConfiguration from '../../hooks/useAuditLogZoneConfiguration'
import { useZoneConfigurationEdit } from '../../hooks/useZoneConfigurationEdit'
import FormDialog from '../FormDialog'
import useStyles from './styles'

type Props = {
	onAfterSubmit: () => void
}

const EditButton: React.FC<Props> = ({ onAfterSubmit }) => {
	const { t } = useTranslation()
	const [isEditing, setIsEditing] = useState(false)
	const classes = useStyles()
	const userInfo = useGetUserInfo()
	const { updateData } = useZoneConfigurationEdit()
	const { sendAuditLogZoneConfiguration } = useAuditLogZoneConfiguration()
	const { sendAnalyticsServiceEventButtonClickedEvent } = useAnalyticsServiceEventButtonClicked()
	const { sendAnalyticsServiceEventLinkClickedEvent } = useAnalyticsServiceEventLinkClicked()
	const isTogglePricePerPointSettingsEnabled = useIsTogglePricePerPointSettingsEnabled()

	const handleOnClick = () => {
		setIsEditing(true)
		sendAnalyticsServiceEventLinkClickedEvent('Tier Configuration', 'Edit', 'Edit')
	}

	const handleCancel = () => {
		setIsEditing(false)
		sendAnalyticsServiceEventButtonClickedEvent('Tier Configuration - Edit Modal', 'Cancel', 'Cancel')
	}

	const handleSubmit = async (formData: SettingDefaultConfiguration) => {
		try {
			if (!isTogglePricePerPointSettingsEnabled) {
				delete (formData as PricePerPointConfiguration).pricePerPoint
			}

			await updateData(formData)
			AnalyticsService.events.tierConfigurationUpdated({
				initial_balance: `${formData.initialBalance}`,
				earn_limit: `${formData.earnLimit}`,
				redeem_limit: `${formData.redeemLimit}`,
				screen_name: 'Tier Configuration - Edit Modal',
				...userInfo,
			})
			sendAuditLogZoneConfiguration(formData)
			PageLevelEvents.showNotification({
				notificationType: 'success',
				message: t('settings:NOTIFICATION.EDIT.SUCCESS'),
			})
		} catch (error) {
			PageLevelEvents.showNotification({
				notificationType: 'error',
				message: t('settings:NOTIFICATION.EDIT.ERROR'),
			})
		} finally {
			setIsEditing(false)
			onAfterSubmit()
		}
	}

	return (
		<>
			<Typography className={classes.button} onClick={handleOnClick}>
				{t('settings:FORM.EDIT')}
			</Typography>
			{isEditing && <FormDialog showDialog={isEditing} onCancel={handleCancel} onSubmit={handleSubmit} />}
		</>
	)
}

export default EditButton
