export enum SettingsType {
	BEES_BANK = 'BEES_BANK',
	BENEFITS_BANNER = 'BENEFITS_BANNER',
	CATEGORIES = 'CATEGORIES',
	DEFAULT_CONFIGURATION = 'DEFAULT_CONFIGURATION',
	FILTER = 'FILTER',
	HUB_HEADER = 'HUB_HEADER',
	RULES = 'RULES',
	TERMS = 'TERMS',
	OFFER_CATEGORIES = 'OFFER_CATEGORIES',
	REMOVAL_CATEGORIES = 'REMOVAL_CATEGORIES',
	TOGGLES = 'TOGGLES',
}

export enum SettingsLevel {
	STORE = 'STORE',
	VENDOR = 'VENDOR',
	ZONE = 'ZONE',
}
