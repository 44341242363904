import { Tooltip } from '@hexa-ui/components'
import { AlertTriangle, Clock, XCircle } from '@hexa-ui/icons'
import React, { ReactNode } from 'react'
import { TFunction, useTranslation } from 'react-i18next'
import { styles } from './AlertsTooltipIconStyle'

interface BaseAlertsTooltipIcon {
	type: 'pending' | 'rejected' | 'warning'
}

interface PendingAlertsTooltipIcon extends BaseAlertsTooltipIcon {
	type: 'pending'
	text: string
}

interface RejectedAlertsTooltipIcon extends BaseAlertsTooltipIcon {
	type: 'rejected'
	rejectionReason: string
}

interface WarningAlertsTooltipIcon extends BaseAlertsTooltipIcon {
	type: 'warning'
	minimumPrice: string
	maximumPrice: string
}

type AlertsTooltipIconType = PendingAlertsTooltipIcon | RejectedAlertsTooltipIcon | WarningAlertsTooltipIcon

interface IconReturn {
	text: string
	icon: ReactNode
}

const getIconData = (item: AlertsTooltipIconType, t: TFunction<'translation', undefined>): IconReturn => {
	const css = styles()
	const { rejectionReason } = item as RejectedAlertsTooltipIcon

	const iconMap = {
		warning: () => ({
			text: t('redeemable:ITEMS_LIST_TABLE.PPP_RANGE_TOOLTIP', {
				minimumPrice: (item as WarningAlertsTooltipIcon).minimumPrice,
				maximumPrice: (item as WarningAlertsTooltipIcon).maximumPrice,
			}),
			icon: <AlertTriangle size="medium" className={css.warning} fr={undefined} />,
		}),
		pending: () => ({
			text: (item as PendingAlertsTooltipIcon).text,
			icon: <Clock size="medium" className={css.pending} fr={undefined} />,
		}),
		rejected: () => ({
			text: t('redeemable:ITEMS_LIST_TABLE.REJECTION_TOOLTIP', {
				rejectionReason: rejectionReason.length > 240 ? `${rejectionReason.slice(0, 240)}...` : rejectionReason,
			}),
			icon: <XCircle size="medium" className={css.rejected} fr={undefined} />,
		}),
	}

	return iconMap[item.type]()
}

export const AlertsTooltipIcon = (item: AlertsTooltipIconType) => {
	const css = styles()
	const { t } = useTranslation()
	const { icon, text } = getIconData(item, t)

	return (
		<div className={css.container}>
			<Tooltip placement="bottom" text={text}>
				{icon}
			</Tooltip>
		</div>
	)
}
