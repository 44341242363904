import { useMemo } from 'react'
import { Edit2, Trash2 } from '@admin-portal-shared-components/icons'
import { AppHeaderConfigPayload, OptionalButton } from 'admin-portal-shared-services'
import { useTranslation } from 'react-i18next'
import * as currencyHelper from '@/utils/currencyHelper'

interface UseCreateRulesHeaderPayloadProps {
	handleDeleteRule: () => Promise<void>
	handleEditRule: () => void
	hasEditPermission: boolean
	hasDeletePermission: boolean
	earningRule?: { points?: number; amountSpent?: number; ruleId?: string; status: string }
	exclusionRule?: { ruleId?: string; status: string }
	isOnEditPage: boolean
}

const homePath = '/membership'

function getEarningRulePath(earningRuleId: string, actionType: string): string {
	return `/membership/rules/earning-rule/${earningRuleId}?action=${actionType}`
}

function getExclusionRulePath(exclusionRuleId: string, actionType: string): string {
	return `/membership/rules/exclusion-rule/${exclusionRuleId}?action=${actionType}`
}

const useCreateRulesHeaderPayload = ({
	handleDeleteRule,
	handleEditRule,
	hasEditPermission = false,
	hasDeletePermission = false,
	earningRule,
	exclusionRule,
	isOnEditPage,
}: UseCreateRulesHeaderPayloadProps): AppHeaderConfigPayload => {
	const { status: earningStatus, points, amountSpent, ruleId: earningRuleId } = earningRule || {}
	const { status: exclusionStatus, ruleId: exclusionRuleId } = exclusionRule || {}

	const { t } = useTranslation()

	const rulesListBreadcrumbItem = {
		label: t('rules:TITLE'),
		path: '/membership/rules',
		isCurrentPage: false,
	}

	const pageTitle = useMemo(() => {
		if (isOnEditPage) {
			if (earningStatus === 'DRAFT') {
				return t('rules:CREATE_RULE_PAGE.EARNING_RULE_TITLE')
			}
			if (exclusionStatus === 'DRAFT') {
				return t('rules:CREATE_RULE_PAGE.EXCLUSION_RULE_TITLE')
			}
			return t('rules:CREATE_EARNING_RULES:EDIT_RULE')
		}

		if (points && amountSpent) {
			return t('rules:EARNING_NAME', {
				point: points,
				amountSpent: currencyHelper.format(amountSpent),
			})
		}

		return t('rules:CREATE_RULE_PAGE.EXCLUSION_RULE_TITLE')
	}, [amountSpent, earningStatus, exclusionStatus, isOnEditPage, points, t])

	const breadcrumbItems = useMemo(() => {
		if (earningRuleId && earningStatus === 'DRAFT') {
			return [
				rulesListBreadcrumbItem,
				{
					label: t('rules:CREATE_RULE_PAGE.EARNING_RULE_TITLE'),
					path: getEarningRulePath(earningRuleId, 'edit'),
					isCurrentPage: true,
				},
			]
		}

		if (exclusionStatus === 'DRAFT' && exclusionRuleId) {
			return [
				rulesListBreadcrumbItem,
				{
					label: t('rules:CREATE_RULE_PAGE.EXCLUSION_RULE_TITLE'),
					path: getExclusionRulePath(exclusionRuleId, 'edit'),
					isCurrentPage: true,
				},
			]
		}

		if (earningRuleId) {
			const earningRuleDetailBreadcrumb = {
				label: t('rules:EARNING_NAME', {
					point: points,
					amountSpent: currencyHelper.format(amountSpent),
				}),
				path: getEarningRulePath(earningRuleId, 'view'),
				isCurrentPage: false,
			}

			if (isOnEditPage) {
				return [
					rulesListBreadcrumbItem,
					earningRuleDetailBreadcrumb,
					{
						label: t('rules:CREATE_EARNING_RULES:EDIT_RULE'),
						path: getEarningRulePath(earningRuleId, 'edit'),
						isCurrentPage: true,
					},
				]
			}

			return [rulesListBreadcrumbItem, { ...earningRuleDetailBreadcrumb, isCurrentPage: true }]
		}

		if (exclusionRuleId) {
			const exclusionRuleDetailBreadcrumb = {
				label: t('rules:CREATE_RULE_PAGE.EXCLUSION_RULE_TITLE'),
				path: getExclusionRulePath(exclusionRuleId, 'view'),
				isCurrentPage: false,
			}
			if (isOnEditPage) {
				return [
					rulesListBreadcrumbItem,
					exclusionRuleDetailBreadcrumb,
					{
						label: t('rules:CREATE_EARNING_RULES:EDIT_RULE'),
						path: getExclusionRulePath(exclusionRuleId, 'edit'),
						isCurrentPage: true,
					},
				]
			}

			return [rulesListBreadcrumbItem, { ...exclusionRuleDetailBreadcrumb, isCurrentPage: true }]
		}

		return []
	}, [amountSpent, earningStatus, earningRuleId, exclusionRuleId, exclusionStatus, isOnEditPage, points, t])

	const breadcrumbConfig = {
		homePath,
		items: breadcrumbItems,
	}

	const optionalButtons = useMemo(() => {
		const optionalButtonsArray: [OptionalButton?, OptionalButton?] = []

		if (hasDeletePermission && !isOnEditPage) {
			optionalButtonsArray[0] = {
				label: t('rules:CREATE_EARNING_RULES:DELETE_RULE'),
				onClick: (): void => {
					handleDeleteRule()
				},
				icon: () => Trash2,
			}
		}

		if (hasEditPermission && !isOnEditPage) {
			optionalButtonsArray[1] = {
				label: t('rules:CREATE_EARNING_RULES:EDIT'),
				onClick: handleEditRule,
				icon: () => Edit2,
			}
		}

		return optionalButtonsArray
	}, [handleDeleteRule, handleEditRule, hasDeletePermission, hasEditPermission, isOnEditPage, t])

	return { optionalButtons, pageTitle, breadcrumbConfig }
}

export default useCreateRulesHeaderPayload
