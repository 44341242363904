import { PocSearchParams, RewardsProgram, ScreenName } from '@/domains'
import { AccountValidationResult } from '@/domains/enums'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { PageLevelEvents, RewardsPermissionEvents } from '@/stores'
import { LoadAllPocsEnrollmentUseCase } from '@/usecase'
import * as TranslateAccountIdUseCase from '@/usecase/account/TranslateAccountIdUseCase'
import { hasElements } from '@/utils/array'
import { isRewardsProgramEnabled } from '@/utils/rewardsProgram'
import { hasText } from '@/utils/string'
import React, { useEffect, useState } from 'react'
import * as FeatureToggle from '@/utils/featureToggle'
import { useFeatureToggleV2 } from '@/hooks'
import PermissionListView from './PermissionListView'

const RewardsPermission: React.FC = () => {
	const [isSearching, setIsSearching] = useState(false)
	const [isReady, setIsReady] = useState(false)
	const userInfo = useGetUserInfo()

	const isToggleMultivendorValidateAccountEnabled = useFeatureToggleV2(FeatureToggle.DEV_MULTIVENDOR_VALIDATE_ACCOUNT)

	useEffect(() => {
		AnalyticsService.events.pageViewed({
			screen_name: ScreenName.RewardsPermission,
			...userInfo,
		})
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const loadPoc = async (pocId: string): Promise<Array<RewardsProgram>> => {
		RewardsPermissionEvents.setShowErrorForRewardsPermission(false)
		if (!hasText(pocId)) {
			return []
		}
		setIsSearching(true)

		const accountId = await TranslateAccountIdUseCase.execute({
			vendorAccountId: pocId,
			isToggleMultivendorValidateAccountEnabled: isToggleMultivendorValidateAccountEnabled!,
		})
		return LoadAllPocsEnrollmentUseCase.execute([accountId])
			.catch((err) => {
				RewardsPermissionEvents.setShowErrorForRewardsPermission(true)
				throw err
			})
			.finally(() => {
				setIsSearching(false)
			})
	}

	const handleEdit = (row: RewardsProgram, rowIndex: number) => {
		RewardsPermissionEvents.setUpdateDialog({
			isShowing: true,
			enablePermission: !isRewardsProgramEnabled(row),
			accountId: row.accountId,
			rowIndex,
		})
	}

	const searchByPocId = async ({ pocId }: PocSearchParams): Promise<AccountValidationResult> => {
		PageLevelEvents.hideNotification()
		try {
			AnalyticsService.events.tableSearched({
				search_query: pocId,
				content_type: 'POC',
				table_name: ScreenName.RewardsPermission,
				screen_name: ScreenName.RewardsPermission,
				...userInfo,
			})
			const data = await loadPoc(pocId)
			RewardsPermissionEvents.setRewardsData(data)
			if (hasElements(data)) {
				return AccountValidationResult.OK
			}
		} catch {
			RewardsPermissionEvents.setRewardsData([])
			setIsSearching(false)
			return AccountValidationResult.INVALID
		}
		return AccountValidationResult.INVALID
	}

	useEffect(() => {
		RewardsPermissionEvents.setRewardsData([])
		setIsReady(true)
	}, [])

	return isReady ? (
		<PermissionListView isSearching={isSearching} handleEdit={handleEdit} searchHandler={searchByPocId} />
	) : null
}

export default RewardsPermission
