import { deleteApprovalCpp } from '@/services/redeemablesV2/RedeemablesService'

const execute = async (transactionId: string): Promise<unknown> => {
	try {
		const response = await deleteApprovalCpp(transactionId)

		if (response !== 204) {
			console.error('Failed to delete approval CPP')
			return null
		}

		return response
	} catch (error) {
		console.error('Error endpoint: ', error)
		return null
	}
}

export { execute }
