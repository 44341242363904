import React from 'react'
import { ArrowRight, Plus } from '@hexa-ui/icons'
import { useTranslation } from 'react-i18next'
import { useAuthorization, useFeatureToggleV2 } from '@/hooks'
import * as FeatureToggle from '@/utils/featureToggle'
import { useSetAppHeaderOptionalButtons } from '@/hooks/useSetAppHeaderOptionalButtons/useSetAppHeaderOptionalButtons'
import RulesListRedesign from './RulesRedesign/Pages/RuleListRedesign/RulesListRedesign'
import RulesProvider from './providers/RulesProvider'
import { useNavigateToRules } from './hooks/useNavigateToRules'
import { createEarningRuleButtonEnabled } from './RulesRedesign/utils/createEarningRuleButtonEnabled'
import { createExclusionListEnabled } from './RulesRedesign/utils/createExclusionListEnabled'
import { useHasPermissionRules } from './hooks/useHasPermissionRules/useHasPermissionRules'

const RulesRoute: React.FC = () => {
	const { t } = useTranslation()
	const { goToCreateRule } = useNavigateToRules()
	const { isFeatureAuthorized } = useAuthorization()
	const { hasOneOfRulesPermission, hasCreateExclusionListPermission } = useHasPermissionRules()
	const isShowExclusionListEnabled = useFeatureToggleV2(FeatureToggle.SHOW_EXCLUSION_LIST)
	const isToggledCreateEarningRulesEnabled = useFeatureToggleV2(FeatureToggle.CREATE_EARNING_RULES)

	const createEarningRuleEnabled = createEarningRuleButtonEnabled({
		isFeatureAuthorized,
		isToggledCreateEarningRulesEnabled: isToggledCreateEarningRulesEnabled!,
	})

	const isCreateExclusionListEnabled = createExclusionListEnabled({
		isFeatureAuthorized,
		isToggledShowExclusionListEnabled: isShowExclusionListEnabled!,
	})

	useSetAppHeaderOptionalButtons({
		hasFeatureToggle: isToggledCreateEarningRulesEnabled,
		primaryButton:
			(createEarningRuleEnabled || isCreateExclusionListEnabled) && hasOneOfRulesPermission
				? {
						label: t('rules:CREATE_EARNING_RULES:CREATE_NEW_EARNING_RULE'),
						onClick: () => {
							goToCreateRule()
						},
						icon: () => Plus,
					}
				: undefined,
		secondaryButton:
			isCreateExclusionListEnabled && hasCreateExclusionListPermission
				? {
						label: t('rules:CREATE_EARNING_RULES:GO_TO_AUDIENCES_BTN'),
						onClick: () => {
							window.location.href = '/audiences?origin=membership'
						},
						icon: () => ArrowRight,
					}
				: undefined,
	})

	return (
		<RulesProvider>
			<RulesListRedesign />
		</RulesProvider>
	)
}

export default RulesRoute
