import { styled } from '@bees/vision-tokens'

export const Container = styled('div', {
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	width: '90px',
	height: '64px',
})

export const Img = styled('img', {
	height: '100%',
})
