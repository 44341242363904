import RefundTransaction, {
	RefundCombosTransaction,
	RefundRedeemablesTransaction,
} from '@/domains/transaction/RefundTransaction'
import { RefundItemFormik } from '@/routes/transaction/model/RefundItemFormik'
import { FormikValues } from 'formik'

const transactionFormValuesToRefund = ({
	values,
	isToggleRefundRedeemableItemsEnabled,
}: {
	values: FormikValues
	isToggleRefundRedeemableItemsEnabled: boolean
}): RefundTransaction => {
	if (!isToggleRefundRedeemableItemsEnabled) {
		const combos = values.combos as Array<RefundItemFormik>
		return {
			orderId: values.orderId,
			combos: combos.filter(onlyChecked).map(({ id: comboId, quantity }) => ({ comboId, quantity })),
		} as RefundCombosTransaction
	}

	const redeemables = values.redeemables as Array<RefundItemFormik>
	return {
		orderId: values.orderId,
		redeemables: redeemables.filter(onlyChecked).map(({ id, quantity }) => ({ id, quantity })),
	} as RefundRedeemablesTransaction
}

const onlyChecked = (redeemable: RefundItemFormik): boolean => redeemable.checked

export default transactionFormValuesToRefund
