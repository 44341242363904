import { SettingsLevel, SettingsType } from '@/domains/enums/settings'
import { LoadDefaultConfigParams, loadSettingsV2 } from '@/services/settings/SettingsService'
import { SettingsConfiguration } from '@/stores'
import { DEFAULT_TIER_FOR_PPP } from '@/utils/constants'
import { getDefaultVendorIdOfCurrentZone } from '@/utils/getDefaultVendorId'

export const execute = async (): Promise<SettingsConfiguration> => {
	const params: LoadDefaultConfigParams = {
		levels: SettingsLevel.VENDOR,
		settingIds: getDefaultVendorIdOfCurrentZone(),
		tiers: [DEFAULT_TIER_FOR_PPP],
		types: SettingsType.DEFAULT_CONFIGURATION,
	}

	const response = await loadSettingsV2<SettingsConfiguration>(params)

	return response || []
}
