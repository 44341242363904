import { useCallback, useMemo } from 'react'
import { CREATE_EARNING_RULES, CREATE_EXCLUSION_LIST } from '@/utils/constants'
import useScopeAuthorization from '@/hooks/useScopeAuthorization/useScopeAuthorization'
import { useNavigateToRules } from '../useNavigateToRules'
import { useHasPermissionRulesProps } from './useHasPermissionRules.types'

export const useHasPermissionRules = (): useHasPermissionRulesProps => {
	const { goToRulesList } = useNavigateToRules()

	const hasCreateEarningRulesPermission = useScopeAuthorization([CREATE_EARNING_RULES])
	const hasCreateExclusionListPermission = useScopeAuthorization([CREATE_EXCLUSION_LIST])
	const hasOneOfRulesPermission = hasCreateEarningRulesPermission || hasCreateExclusionListPermission
	const hasAllRulesPermission = hasCreateEarningRulesPermission && hasCreateExclusionListPermission

	const redirectToRuleList = useCallback(() => goToRulesList(), [goToRulesList])

	return useMemo(
		() => ({
			hasCreateEarningRulesPermission,
			hasCreateExclusionListPermission,
			hasOneOfRulesPermission,
			hasAllRulesPermission,
			redirectToRuleList,
		}),
		[
			hasCreateEarningRulesPermission,
			hasCreateExclusionListPermission,
			hasOneOfRulesPermission,
			hasAllRulesPermission,
			redirectToRuleList,
		],
	)
}
