import useAdminAuditLog, { IAuditEntities, IAuditOperations } from '@/hooks/useAdminAuditLog'
import { useCallback } from 'react'

export interface IAuditLogDTCombos {
	recordId: string
	points: string
	operation: IAuditOperations
}

const useAuditLogDTcombos = (): {
	sendAuditLogDTCombos: ({ recordId, points, operation }: IAuditLogDTCombos) => void
	sendAuditLogDeleteDTCombos: (recordId: string) => void
} => {
	const { auditLogInstance } = useAdminAuditLog()

	const sendAuditLogDTCombos = useCallback(
		({ recordId, points, operation }: IAuditLogDTCombos) => {
			auditLogInstance({
				metadata: {
					points,
					type: 'dt_combo',
				},
				entity: IAuditEntities.REDEEMABLES,
				entityId: recordId,
				operation,
			})
		},
		[auditLogInstance],
	)

	const sendAuditLogDeleteDTCombos = useCallback(
		async (recordId) => {
			try {
				await auditLogInstance({
					metadata: {
						type: 'dt_combo',
					},
					entity: IAuditEntities.REDEEMABLES,
					entityId: recordId,
					operation: IAuditOperations.DELETE,
				})
			} catch (error) {
				console.error('Audit Log Error: ', error)
			}
		},
		[auditLogInstance],
	)

	return { sendAuditLogDTCombos, sendAuditLogDeleteDTCombos }
}

export default useAuditLogDTcombos
