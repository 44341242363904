import { OrderWithCombo } from '@/domains'
import { TableCell, TableRow, Typography } from '@material-ui/core'
import clsx from 'clsx'
import InputCheckBox from 'components/formik/InputCheckBox'
import InputSelect, { SelectOptions } from 'components/formik/InputSelect'
import ComboDetailed from 'domains/ComboDetailed'
import ComboRefundStatus from 'domains/enums/combo/ComboRefundStatus'
import { useStoreMap } from 'effector-react'
import { useField } from 'formik'
import React, { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import TransactionStore from 'stores/transaction/TransactionStore'
import arrayOfNumber from 'utils/arrayOfNumber'
import { useFeatureToggleV2 } from '@/hooks'
import * as FeatureToggle from '@/utils/featureToggle'
import useStyles from '../CombosListStyle'

type Props = {
	index: number
	combo: ComboDetailed
	existFullyRefunded: boolean
	allAreFullyRefunded?: boolean
}
const ComboListRow: React.FC<Props> = ({ index, combo, existFullyRefunded, allAreFullyRefunded }: Props) => {
	const { t } = useTranslation()
	const classes = useStyles()
	const { selectedOrder } = useStoreMap({
		store: TransactionStore,
		keys: [],
		fn: (state) => ({
			selectedOrder: state.selectedOrder as OrderWithCombo,
		}),
	})

	const isCombosMultivendorToggleEnabled = useFeatureToggleV2(FeatureToggle.DEV_MULTI_VENDOR_COMBOS)

	const [comboChecked] = useField<boolean>(`combos[${index}].checked`)

	const comboFullyRefunded = combo.status === ComboRefundStatus.FullyRefunded
	const [selectedQuantity, setSelectedQuantity] = useState(comboFullyRefunded ? combo.maxQuantity : combo.quantity)

	const comboPoints = useMemo(() => {
		if (combo.points) {
			return combo.points * selectedQuantity
		}
		return combo.points
	}, [combo.points, selectedQuantity])

	useEffect(
		() => setSelectedQuantity(comboFullyRefunded ? combo.maxQuantity : combo.quantity),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[selectedOrder],
	)

	const handleChangeQuantity = (quantity: number) => {
		setSelectedQuantity(quantity)
	}

	const quantityDisabled = useMemo(() => {
		return !comboChecked.value
	}, [comboChecked])

	const getComboOptions = (): Array<SelectOptions> => {
		const selectedCombo = selectedOrder?.combos[index]
		if (selectedCombo) {
			return arrayOfNumber(selectedCombo.maxQuantity).map((quantity) => {
				return {
					label: quantity,
					value: quantity,
					disabled: quantity > selectedCombo.quantity,
				} as SelectOptions
			})
		}

		return []
	}

	return (
		<TableRow key={combo.comboId} id={combo.comboId} data-testid="refund-combo-list-row">
			<TableCell className={clsx({ [classes.cell]: comboFullyRefunded, [classes.cellCenter]: !comboFullyRefunded })}>
				{allAreFullyRefunded && (
					<Typography component="span">
						<Typography variant="body1">{combo.name}</Typography>
						<Typography variant="body2">
							{isCombosMultivendorToggleEnabled ? combo.vendorComboId : combo.comboId}
						</Typography>
					</Typography>
				)}
				{!allAreFullyRefunded && (
					<InputCheckBox
						id={`combos${index}checked`}
						name={`combos.${index}.checked`}
						label={combo.name}
						optionalLabel={combo.comboId}
						disabled={comboFullyRefunded}
						checkBoxVisible={comboFullyRefunded}
					/>
				)}
			</TableCell>
			<TableCell className={classes.cell}>
				{comboFullyRefunded && <div className={classes.qntAligned}>{combo.maxQuantity}</div>}
				{!comboFullyRefunded && (
					<InputSelect
						id={`combos${index}quantity`}
						name={`combos.${index}.quantity`}
						label=""
						options={getComboOptions()}
						onChange={(event) => handleChangeQuantity(event.target.value as number)}
						disabled={quantityDisabled}
						disableGutters
					/>
				)}
			</TableCell>
			<TableCell className={clsx({ [classes.cell]: comboFullyRefunded, [classes.cellCenter]: !comboFullyRefunded })}>
				<div className={classes.pointsAligned}>{`${comboPoints}${t('transaction:REFUND.POINTS_ABBREV')}`}</div>
			</TableCell>
			{existFullyRefunded && (
				<TableCell className={classes.cell}>
					<div className={classes.fullyAligned}>{comboFullyRefunded && t('transaction:REFUND.FULLY_REFUNDED')}</div>
				</TableCell>
			)}
		</TableRow>
	)
}

export default React.memo(ComboListRow)
