import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core'
import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useFeatureToggleV2 } from '@/hooks'
import * as FeatureToggle from '@/utils/featureToggle'
import useStyles from '../../../CombosList/CombosListStyle'

type ResultListWrapperProps = {
	existFullyRefunded?: boolean
	children: React.ReactNode
}

export const ResultListWrapper = ({ children, existFullyRefunded, ...props }: ResultListWrapperProps) => {
	const isToggleRefundRedeemableItemsEnabled = useFeatureToggleV2(FeatureToggle.CREATE_REFUND_REDEEMABLES)

	const title = useMemo(() => {
		return isToggleRefundRedeemableItemsEnabled
			? 'transaction:REFUND.SELECT_REDEEMABLES_REFUND'
			: 'transaction:REFUND.SELECT_COMBOS_REFUND'
	}, [isToggleRefundRedeemableItemsEnabled])

	const redeemableTitleColumn = useMemo(() => {
		return isToggleRefundRedeemableItemsEnabled ? 'transaction:REFUND.REDEEMABLES' : 'transaction:REFUND.DT_COMBOS'
	}, [isToggleRefundRedeemableItemsEnabled])

	const { t } = useTranslation()
	const classes = useStyles()

	const firstColumnWidth = existFullyRefunded ? { maxWidth: '50%', width: '50%' } : { maxWidth: '70%', width: '70%' }

	return (
		<>
			<Typography variant="h4" className={classes.title}>
				{t(title)}
			</Typography>
			<TableContainer {...props}>
				<Table id="table-combos" aria-label="table">
					<TableHead id="table-combos-headers">
						<TableRow>
							<TableCell id="comboId" align="left" style={firstColumnWidth} data-testid="table-column-redeemable">
								{t(redeemableTitleColumn)}
							</TableCell>
							<TableCell
								id="quantity"
								align="left"
								style={{ maxWidth: '20%', width: '20%' }}
								data-testid="table-column-quantity"
							>
								{t('transaction:REFUND.QUANTITY')}
							</TableCell>
							<TableCell
								id="points"
								align="left"
								style={{ maxWidth: '10%', width: '10%' }}
								data-testid="table-column-points"
							>
								{t('transaction:REFUND.POINTS')}
							</TableCell>
							{existFullyRefunded && <TableCell id="status" align="left" style={{ maxWidth: '20%', width: '20%' }} />}
						</TableRow>
					</TableHead>
					<TableBody>{children}</TableBody>
				</Table>
			</TableContainer>
		</>
	)
}
