import { useCallback, useState } from 'react'
import { CurrentTransactionStatusEnum } from '@/domains/redeemable/RedeemableItem'
import { LoadDefaultCppUseCase } from '@/usecase/redeemableItemsV2'
import { TypeToast, useToast } from 'admin-portal-shared-services'
import { useTranslation } from 'react-i18next'

export type UseDefaultCpp = {
	defaultCppData?: DefaultCppData
	hasDefaultCppError: boolean
	isDefaultCppLoading: boolean
	loadDefaultCppData: (hasPermission?: boolean) => void
}

export interface DefaultCppData {
	defaultCpp: number
	newDefaultCpp: number
	id: string
	update: {
		status: CurrentTransactionStatusEnum | null
		timestamp: string
		reason: string
	}
}

export const useDefaultCpp = (): UseDefaultCpp => {
	const toast = useToast()
	const { t } = useTranslation()

	const [isDefaultCppLoading, setIsDefaultCppLoading] = useState(false)
	const [hasDefaultCppError, setHasDefaultCppError] = useState(false)
	const [defaultCppData, setDefaultCppData] = useState<DefaultCppData>({
		id: '',
		defaultCpp: 0,
		newDefaultCpp: 0,
		update: {
			status: null,
			timestamp: '',
			reason: '',
		},
	})

	const loadDefaultCppData = useCallback(async (hasPermission?: boolean) => {
		if (!hasPermission) return

		setIsDefaultCppLoading(true)
		setHasDefaultCppError(false)

		try {
			const result = await LoadDefaultCppUseCase.execute()
			if (result)
				setDefaultCppData({
					id: result.id,
					defaultCpp: result.defaultCpp,
					newDefaultCpp: result.pricePerPoint,
					update: result.updates[0],
				})
		} catch (error) {
			setHasDefaultCppError(true)
			toast.notify({
				type: TypeToast.ERROR,
				message: t('common:ERROR_MESSAGE.GENERIC_ERROR'),
			})
		} finally {
			setIsDefaultCppLoading(false)
		}
	}, [])

	return {
		defaultCppData,
		hasDefaultCppError,
		isDefaultCppLoading,
		loadDefaultCppData,
	}
}
