import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		PENDING: {
			alignItems: 'center',
			display: 'flex',
			flexDirection: 'row',

			'& > ul#dropdownMenu': {
				borderRadius: '8px',
				boxShadow: '0px 0px 4px 0px rgba(0, 0, 0, 0.16)',
				display: 'flex',
				flexDirection: 'column',
				justifyContent: 'flex-start',
				listStyle: 'none',
				margin: 0,
				padding: 0,
				position: 'absolute',
				transform: 'translateX(calc(-100% - 4px))',
				width: '172px',
				zIndex: '3990 !important',

				'& > li': {
					backgroundColor: theme.palette.background.paper,
					borderRadius: '8px 8px 0px 0px',
					fontSize: '16px',

					'&:hover': {
						cursor: 'pointer',

						'& > button': {
							fontWeight: '500',
						},
					},

					'& > button': {
						all: 'unset',
						boxSizing: 'border-box',
						padding: '16px',
						display: 'block',
						width: '100%',
					},

					'&:nth-child(2n)': {
						backgroundColor: theme.palette.background.default,
						borderRadius: '0px 0px 8px 8px',
					},
				},
			},
		},
	}),
)

const gridContainerStyles = {
	rowGap: '1rem',
	margin: '1rem 0',
}

export { useStyles, gridContainerStyles }
