import React, { useMemo } from 'react'
import SmallSelect from '@/components/form/Select/SmallSelect'
import { useAuthenticationService, useUserMetadata } from 'admin-portal-shared-services'
import useCurrentZone from '@/hooks/zone/useCurrentZone'
import { getAvailableCountries } from '@/utils/countryUtil'
import { useGlobalConfig } from '@/hooks/global/useGlobalConfig'
import { hasMoreElementsThan } from '@/utils/array'
import { useTranslation } from 'react-i18next'
import useEditMode from '@/routes/rules/hooks/useEditMode/useEditMode'
import { useNavigateToRules } from '@/routes/rules/hooks/useNavigateToRules'
import useConfirmationModal from '@/hooks/useConfirmationModal/useConfirmationModal'

interface ICountry {
	label: string
	value: string
}

const SelectZone: React.FC = (): JSX.Element | null => {
	const { t } = useTranslation()
	const selectedZone = useCurrentZone()
	const userCountry = useAuthenticationService().getCountryB2C()
	const { data } = useUserMetadata()
	const { defineCurrentZone } = useGlobalConfig()
	const { isEditMode } = useEditMode()
	const { goToRulesList } = useNavigateToRules()

	const options = useMemo(
		() =>
			getAvailableCountries({
				supportedCountries: data?.supportedCountries,
				userCountry,
			})
				.map((country: string) => ({
					label: t(`COUNTRIES.${country}`),
					value: country,
				}))
				.sort((a, b) => a.label.localeCompare(b.label)),
		[data?.supportedCountries, userCountry, t],
	)

	const showSelectCountries = hasMoreElementsThan<ICountry>(options, 1)

	const { showModal: showCountryModal } = useConfirmationModal({
		confirmLabel: t('rules:MODAL_COUNTRY.CONFIRM_LABEL'),
		cancelLabel: t('rules:MODAL_COUNTRY.CANCEL_LABEL'),
		title: t('rules:MODAL_COUNTRY.TITLE'),
		body: t('rules:MODAL_COUNTRY.BODY'),
		confirmVariant: 'destructive',
	})

	const handleChange = async (value: string) => {
		if (isEditMode) {
			const confirmed = await showCountryModal()
			if (confirmed) {
				defineCurrentZone(value)
				goToRulesList()
			}
		} else {
			defineCurrentZone(value)
		}
	}

	return showSelectCountries ? (
		<SmallSelect value={selectedZone ?? undefined} options={options} onChange={handleChange} />
	) : null
}

export default SelectZone
