import React, { ChangeEvent, useCallback } from 'react'
import { Button, Dialog, Heading, Input } from '@hexa-ui/components'
import { useTranslation } from 'react-i18next'
import { useStore } from 'effector-react'
import {
	$redeemableItemLimitStore,
	RedeemableItemLimitStoreEvents,
} from '@/stores/redeemableItems/RedeemableItemLimitStore'
import { Controller, FormProvider, useForm, useFormContext } from 'react-hook-form'
import * as UpdateRedeemableItemLimitUseCase from '@/usecase/redeemableItemsV2/UpdateRedeemableItemLimitUseCase'
import { TypeToast, useToast } from 'admin-portal-shared-services'
import { useStyles } from './UpdateLimitDialogStyle'

interface IUpdateLimitDialog {
	trigger?: React.ReactNode
}

const UpdateLimitDialog: React.FC<IUpdateLimitDialog> = ({ trigger }) => {
	const css = useStyles()
	const { t } = useTranslation()
	const toast = useToast()
	const { isOpened, limit } = useStore($redeemableItemLimitStore)
	const { handleSubmit, watch, reset, control, setValue } = useFormContext()
	const buttonI18nText = limit ? 'BUTTON.UPDATE' : 'BUTTON.SAVE'

	const inputValue = watch('inputValue')

	const buttonAction = useCallback(async () => {
		if (inputValue) {
			try {
				const response = await UpdateRedeemableItemLimitUseCase.execute(inputValue)

				if (response) {
					reset()
					RedeemableItemLimitStoreEvents.onClose()
				}
			} catch (error) {
				reset()
				setValue('inputValue', '')
				toast.notify({
					type: TypeToast.ERROR,
					message: t('ERROR_MESSAGE.GENERIC_ERROR'),
				})
			}
		}
	}, [inputValue, t, toast, reset, setValue])

	const handleCancel = useCallback(() => {
		reset()
		RedeemableItemLimitStoreEvents.onClose()
	}, [reset])

	return (
		<div className={css.container} data-testid="update-redeemable-limit-dialog">
			<UpdateLimitFormProvider>
				<Dialog.Root
					closeButton
					title={
						<Heading size="H3">{t(limit ? 'redeemable:LIMIT.UPDATE_LIMIT' : 'redeemable:LIMIT.SET_LIMIT')}</Heading>
					}
					trigger={trigger}
					open={isOpened}
					onClose={handleCancel}
				>
					<p className={css.description}>{t('redeemable:LIMIT.DESCRIPTION')}</p>

					<Controller
						control={control}
						name="inputValue"
						render={({ field }) => {
							return (
								<Input
									type="text"
									data-testid="set-limit-dialog-input"
									label={t('redeemable:LIMIT:LABEL')}
									placeholder={t('redeemable:LIMIT.PLACEHOLDER')}
									width="55%"
									{...field}
									maxLength={9}
									onChange={(e: ChangeEvent<HTMLInputElement>) =>
										field.onChange(e.target.value.replace(/[^0-9]/g, '').replace(/^0+/, ''))
									}
									value={field.value}
								/>
							)
						}}
					/>

					<div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
						<Button variant="secondary" onClick={handleCancel}>
							{t('common:BUTTON.CANCEL')}
						</Button>
						<Button
							type="submit"
							variant="primary"
							onClick={handleSubmit(buttonAction)}
							style={{ marginLeft: '8px' }}
							disabled={!inputValue || inputValue <= 0}
						>
							{t(buttonI18nText)}
						</Button>
					</div>
				</Dialog.Root>
			</UpdateLimitFormProvider>
		</div>
	)
}

export default UpdateLimitDialog

export const UpdateLimitFormProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const methods = useForm()
	return <FormProvider {...methods}>{children}</FormProvider>
}
