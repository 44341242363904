import { RewardsOfferTransaction, TransactionResponse } from '@/domains'
import { addPoints, rewardsOffers } from '@/services/transaction/TransactionService'
import { addPoints as addPointsV2 } from '@/services/transactionV2/TransactionService'

async function execute({
	accountId,
	rewardsOfferTransaction,
	isIdentifyDimensionEnabled,
	isNewTransactionLogsToggleEnabled,
}: {
	accountId: string
	rewardsOfferTransaction: RewardsOfferTransaction
	isIdentifyDimensionEnabled: boolean
	isNewTransactionLogsToggleEnabled: boolean
}): Promise<TransactionResponse> {
	const addPoint = isNewTransactionLogsToggleEnabled
		? addPointsV2(rewardsOfferTransaction)
		: addPoints(rewardsOfferTransaction)

	return isIdentifyDimensionEnabled ? addPoint : rewardsOffers(accountId, rewardsOfferTransaction)
}

export { execute }
