import { Grid } from '@hexa-ui/components'
import { Dot } from '@hexa-ui/icons'
import { styled } from '@hexa-ui/theme'

export const Container = styled(Grid.Item, {
	width: 'fit-content',
	display: 'flex',
	gap: '8px',
	alignItems: 'center',
	padding: '4px 12px',
	borderRadius: '4px',
	margin: 0,
	variants: {
		state: {
			active: {
				backgroundColor: 'var(--colors-semanticSuccessBackground)',
			},
			inactive: {
				backgroundColor: 'var(--colors-semanticErrorBackground)',
			},
			pending: {
				backgroundColor: 'var(--colors-semanticWarningBackground)',
			},
			disabled: {
				backgroundColor: 'var(--colors-neutral30)',
			},
		},
	},
})

export const Bullet = styled(Dot, {
	variants: {
		state: {
			active: {
				color: 'var(--colors-semanticSuccessBasis)',
			},
			inactive: {
				color: 'var(--colors-semanticErrorBasis)',
			},
			pending: {
				color: 'var(--colors-semanticWarningBasis)',
			},
			disabled: {
				color: 'var(--colors-neutral40)',
			},
		},
	},
})

export const Text = styled('span', {
	variants: {
		state: {
			active: {
				color: 'var(--colors-semanticSuccessText)',
			},
			inactive: {
				color: 'var(--colors-semanticErrorText)',
			},
			pending: {
				color: 'var(--colors-semanticWarningText)',
			},
			disabled: {
				color: 'var(--colors-neutral40)',
			},
		},
	},
})
