import React from 'react'
import { Heading, Paragraph } from '@hexa-ui/components'
import { SKUProcess } from '@/domains/sku/SkuStep'
import Styled from '../../RadioCards.styles'

import { useSkuModalContext } from '../../../../context/SkuModalContext'

export const RadioCard = ({ title, subtitle, value }: { title: string; subtitle: string; value: string }) => {
	const { setAddType, addType } = useSkuModalContext()

	const handleClick = (val: SKUProcess) => setAddType({ type: val, continue: false })

	return (
		<Styled.Card
			data-testid={`radio-card-${value}`}
			elevated="minimal"
			border="medium"
			isSelected={addType.type === value}
			onClick={() => handleClick(value as SKUProcess)}
		>
			<Styled.CardGrid>
				<Styled.LeftBox>
					<Styled.Checkbox>
						{addType.type === value && <Styled.Checked data-testid="radio-card-checked" />}
					</Styled.Checkbox>
				</Styled.LeftBox>
				<div>
					<Heading size="H5">{title}</Heading>
					<Paragraph> {subtitle}</Paragraph>
				</div>
			</Styled.CardGrid>
		</Styled.Card>
	)
}
