import React from 'react'
import { useTranslation } from 'react-i18next'
import { Card, EmptyState, Grid, LoadingBuzz } from '@hexa-ui/components'
import { $createRedeemableItemStore } from '@/stores'
import { ViewState } from '@/domains/redeemable/ViewState'
import { useStore } from 'effector-react'
import RedeemableItemsEmptyStateStyle from './RedeemableItemsEmptyStateStyle'
import { AddRedeemableDialog } from '../../../AddRedeemableDialog/AddRedeemableDialog'
import { CreateRedeemableItemButton } from '../CreateRedeemableItemButton/CreateRedeemableItemButton'
import UpdateLimitDialog, {
	UpdateLimitFormProvider,
} from '../RedeemableItemsTable/components/UpdateLimitDialog/UpdateLimitDialog'
import { UpdateLimitButton } from '../RedeemableItemsTable/components/UpdateLimitButton/UpdateLimitButton'

type RedeemableItemsEmptyStateProps = {
	viewState: ViewState
	canCreateSKU?: boolean
}

export const RedeemableItemsEmptyState = ({ viewState, canCreateSKU }: RedeemableItemsEmptyStateProps) => {
	const isLoading = viewState === ViewState.LOADING
	const isNoLimit = viewState === ViewState.NOLIMIT

	const { t } = useTranslation()
	const css = RedeemableItemsEmptyStateStyle()

	const { isOpened } = useStore($createRedeemableItemStore)

	const renderLoadingState = () => (
		<Grid.Item className={css.emptyContainer}>
			<LoadingBuzz size="large" data-testid="redeemable-items-empty-state-loading" />
		</Grid.Item>
	)

	const renderEmptyState = () => (
		<Grid.Item className={css.emptyContainer} data-testid="redeemable-items-empty-state-content">
			<Grid.Item className={css.pageLevelWrapper}>
				<EmptyState.PageLevel
					ilustrationName="beesProductLevel"
					title={t('redeemable:EMPTY_STATE.TITLE')}
					description={t('redeemable:EMPTY_STATE.DESCRIPTION')}
				/>
				{canCreateSKU && <AddRedeemableDialog isOpened={isOpened} trigger={<CreateRedeemableItemButton />} />}
			</Grid.Item>
		</Grid.Item>
	)

	const renderNoLimitEmptyState = () => (
		<Grid.Item className={css.emptyContainer} data-testid="redeemable-items-no-limit-state-content">
			<Grid.Item className={css.pageLevelWrapper}>
				<EmptyState.PageLevel
					ilustrationName="beesProductLevel"
					title={t('redeemable:NO_LIMIT_EMPTY_STATE.TITLE')}
					description={t('redeemable:NO_LIMIT_EMPTY_STATE.DESCRIPTION')}
				/>
				<UpdateLimitFormProvider>
					<UpdateLimitDialog trigger={<UpdateLimitButton />} />
				</UpdateLimitFormProvider>
			</Grid.Item>
		</Grid.Item>
	)

	const renderEmpty = isNoLimit ? renderNoLimitEmptyState() : renderEmptyState()

	return (
		<Card border="large" elevated="small" className={css.mainWrapper}>
			{isLoading ? renderLoadingState() : renderEmpty}
		</Card>
	)
}
