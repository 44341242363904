import { Box, Button } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DeepMap, FieldError, useFieldArray, useFormContext } from 'react-hook-form'
import { ComboValidateType } from '@/domains/enums'
import { ValidateComboUseCase } from '@/usecase'
import { ComboFormFieldArray } from '@/domains'
import { useFeatureToggleV2 } from '@/hooks'
import * as FeatureToggle from 'utils/featureToggle'
import SingleComboFields from '../SingleComboFields/SingleComboFields'

const ERROR_MESSAGES: Partial<Record<ComboValidateType, string>> = {
	[ComboValidateType.EnterValidCombo]: 'combo:ERROR_MESSAGE.INVALID_COMBO_ID',
	[ComboValidateType.AlreadyIncluded]: 'combo:ERROR_MESSAGE.DT_COMBO_ALREADY_INCLUDED',
	[ComboValidateType.AlreadyIncludeProgram]: 'combo:ERROR_MESSAGE.DT_COMBO_ALREADY_INCLUDED_PROGRAM',
}

const SingleComboView: React.FC = () => {
	const { t } = useTranslation()
	const [isAddButtonDisabled, setIsAddButtonDisabled] = useState(true)
	const isDevMultivendorCombosEnabled = useFeatureToggleV2(FeatureToggle.DEV_MULTI_VENDOR_COMBOS)

	const {
		control,
		setError,
		clearErrors,
		trigger,
		formState: { errors },
	} = useFormContext()

	const { fields, append, remove } = useFieldArray({
		control,
		name: 'combos',
		keyName: 'key',
	})

	const handleComboValidation = async (comboId: string, name: string): Promise<boolean> => {
		if (!comboId) {
			clearErrors(name)
			trigger(name)
			return Promise.resolve(false)
		}

		await trigger(name)
		const alreadyHasUniqueError =
			((errors as DeepMap<{ combos: ComboFormFieldArray }, FieldError>).combos || []).filter((item) => {
				return item?.comboId && item.comboId.type === 'unique'
			}).length > 0

		if (alreadyHasUniqueError) {
			setIsAddButtonDisabled(true)
			return Promise.resolve(false)
		}

		const handleValidate = (comboValidate: ComboValidateType) => {
			if (comboValidate === ComboValidateType.Valid) {
				clearErrors(name)
				setIsAddButtonDisabled(false)
				return Promise.resolve(true)
			}
			setError(name, { message: t(ERROR_MESSAGES[comboValidate] ?? ''), type: 'server' })
			setIsAddButtonDisabled(true)
			return Promise.resolve(false)
		}

		return ValidateComboUseCase.execute({
			comboId,
			isDevMultivendorCombosEnabled: isDevMultivendorCombosEnabled!,
		}).then(handleValidate)
	}

	useEffect(() => {
		if (fields.length === 0) {
			append({})
		}
	}, [append, fields.length])

	const handleAddField = () => {
		append({})
		setIsAddButtonDisabled(true)
	}

	const handleRemoveField = (index: number) => {
		remove(index)
		const disabledAddButton = Object.keys(errors).length === 0
		setIsAddButtonDisabled(!disabledAddButton)
	}

	return (
		<Box paddingX={1}>
			{fields.map((value, index) => (
				<SingleComboFields
					key={value.key}
					handleRemoveItem={handleRemoveField}
					index={index}
					disableRemoveButton={fields.length === 1}
					handleValidation={handleComboValidation}
				/>
			))}
			<Box mt={1}>
				<Button
					id="add-filter-btn"
					type="button"
					variant="contained"
					color="primary"
					onClick={handleAddField}
					disabled={isAddButtonDisabled}
				>
					{t('combo:DT_COMBOS.ADD_COMBO_BUTTON')}
				</Button>
			</Box>
		</Box>
	)
}
export default SingleComboView
