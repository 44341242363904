import { RulesEvents } from '@/stores'
import React, { useEffect, useState } from 'react'

import { useLoadRules } from '../hooks/useLoadRules'

const RulesProvider: React.FC = ({ children }) => {
	const [isReady, setIsReady] = useState(false)
	const { loadRules, togglesReady } = useLoadRules()

	useEffect(() => {
		if (togglesReady) {
			loadRules().finally(() => setIsReady(true))
			setIsReady(true)
		}
		return () => {
			setIsReady(false)
			RulesEvents.resetRulesStore()
		}
	}, [loadRules, togglesReady])

	return isReady ? <>{children}</> : null
}

export default RulesProvider
