import useAnalyticsServiceMembership from '@/hooks/analyticsService/useAnalyticsServiceMembership'
import '@/i18n'
import { EnvConfig, EnvProvider } from '@/providers/EnvProvider/EnvProvider'
import TestEnvProvider from '@/providers/testEnv/TestEnvProvider'
import ZoneProvider from '@/providers/zone/ZoneProvider'
import { usePricePerPointConfiguration } from '@/routes/redeemableItems/hooks/usePricePerPointConfiguration'
import theme from '@/theme'
import { initFeatureToggles } from '@/utils/featureToggle/featureToggleHelper'
import { CssBaseline } from '@material-ui/core'
import { StylesProvider, ThemeProvider, createGenerateClassName } from '@material-ui/core/styles'
import React, { useEffect } from 'react'
import DialogProvider from '@/context/DialogContext/DialogContext'
import { QueryClient, QueryClientProvider } from 'react-query'
import { RuleSelectedStatusProvider } from '@/context/RuleSelectedStatusContext/RuleSelectedStatusContext'
import LocaleProvider from '../providers/locale/LocaleProvider'
import AppErrorBoundary from './AppErrorBoundary'
import AppRouter from './AppRouter'

const generateClassName = createGenerateClassName({
	productionPrefix: 'membership',
	seed: 'membership',
})

const App = (props: Readonly<EnvConfig>): JSX.Element => {
	const { segmentKey, optimizelyKey } = props
	const analyticsServiceMembership = useAnalyticsServiceMembership()

	initFeatureToggles(optimizelyKey)

	analyticsServiceMembership.load(segmentKey || '')
	analyticsServiceMembership.identify()

	const { loadData } = usePricePerPointConfiguration()

	useEffect(() => {
		loadData()
	}, [loadData])

	const queryClient = new QueryClient()

	return (
		<QueryClientProvider client={queryClient}>
			<AppErrorBoundary>
				<ThemeProvider theme={theme}>
					<DialogProvider>
						<RuleSelectedStatusProvider>
							<TestEnvProvider>
								<ZoneProvider>
									<EnvProvider env={props}>
										<LocaleProvider>
											<StylesProvider generateClassName={generateClassName}>
												<CssBaseline />
												<AppRouter />
											</StylesProvider>
										</LocaleProvider>
									</EnvProvider>
								</ZoneProvider>
							</TestEnvProvider>
						</RuleSelectedStatusProvider>
					</DialogProvider>
				</ThemeProvider>
			</AppErrorBoundary>
		</QueryClientProvider>
	)
}

export default App
