import { styled } from '@bees/vision-tokens'
import { Paragraph, Button } from '@hexa-ui/components'

const EmptySkuContainer = styled('div', {
	alignItems: 'center',
	display: 'flex',
	flexDirection: 'column',
	margin: 'auto',
})

const EmptyGraphic = styled('img', {
	height: '145px',
	width: '198px',
})
const NotFoundedImage = styled('img', {
	width: '90px',
})
const EmptySkuMessage = styled(Paragraph, {
	color: '#141414 !important',
	fontFamily: 'Work Sans !important',
	fontSize: '14px !important',
	fonWeight: '400 !important',
	letterSpacing: '0',
	lineHeight: '20px',
	textAlign: 'center',
})
const ItemCard = styled('div', {
	height: '170px',
	margin: '16px auto',
	display: 'flex',
	flexDirection: 'column',
	width: '100%',
})
const Loading = styled('div', {
	display: 'flex',
	height: '170px',
	justifyContent: 'center',
})
const SearchButton = styled(Button, {
	maxWidth: '118px',
	outline: '0.5px solid',
})

export default {
	EmptySkuContainer,
	EmptyGraphic,
	NotFoundedImage,
	EmptySkuMessage,
	ItemCard,
	Loading,
	SearchButton,
}
