import React from 'react'
import { Paragraph } from '@hexa-ui/components'
import { useTranslation } from 'react-i18next'
import { EmptyStateContainer } from './EmptyState.styles'
import { EmptyTableImage } from './assets/EmptyTableImage'

export const EmptyState = () => {
	const { t } = useTranslation()

	return (
		<EmptyStateContainer>
			<EmptyTableImage />
			<Paragraph>{t('rules:CREATE_EARNING_RULES.EARNING_SECTION.SKU_EMPTY_TABLE_MESSAGE')}</Paragraph>
		</EmptyStateContainer>
	)
}
