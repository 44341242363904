import { createEvent, createStore } from 'effector'
import { TABLE_PAGE_SIZE_OPTIONS } from '@/utils/constants'
import { PagedResponse } from '@/domains'
import { IPaginationResponse } from '@/domains/PagedResponse'

// Store

export type TransactionState = PagedResponse<ILoad> & {
	reloadPageAtTime: number
}

export interface ILoad {
	id: string
	type: string
	audience: string
	status: string
	dateRegistered: string
}

export const transactionInitialState: TransactionState = {
	content: [],
	pagination: {
		page: 0,
		pageSize: TABLE_PAGE_SIZE_OPTIONS.DEFAULT[0],
		totalElements: 0,
		totalPages: 0,
	},
	reloadPageAtTime: Date.now(),
}

// Events

export const TransactionStoreEvents = {
	setContent: createEvent<ILoad[]>(),
	setPagination: createEvent<Partial<IPaginationResponse>>(),
	reloadPage: createEvent(),
	reset: createEvent('reset'),
}

// Handlers

export const $transactionStore = createStore(transactionInitialState).reset(TransactionStoreEvents.reset)

$transactionStore
	.on(TransactionStoreEvents.setContent, (state, content) => {
		return {
			...state,
			content,
		}
	})
	.on(TransactionStoreEvents.setPagination, (state, payload) => {
		return {
			...state,
			pagination: {
				...state.pagination,
				...payload,
			},
		}
	})
	.on(TransactionStoreEvents.reloadPage, (state) => {
		return {
			...state,
			reloadPageAtTime: Date.now(),
		}
	})
