import { useMutation } from 'react-query'
import useCurrentSettings from '@/hooks/settings/useCurrentSettings'
import { TypeToast, useToast } from 'admin-portal-shared-services'
import {} from 'react-router-dom'
import { useNavigation } from '@/hooks'
import { useTranslation } from 'react-i18next'
import { deleteExclusionRule } from '@/services/rules/RulesService'
import { getRuleIdFromPathname } from '@/utils/getRuleIdFrompathName'

export const useDeleteExclusionRule = (): {
	isLoading: boolean
	deleteRule: () => void
} => {
	const toast = useToast()
	const settings = useCurrentSettings()
	const { vendorId } = settings
	const { goToRoutePath } = useNavigation()
	const { t } = useTranslation()
	const ruleId = getRuleIdFromPathname()

	const { isLoading, mutate: deleteRule } = useMutation(
		() => {
			return deleteExclusionRule(ruleId ?? '', vendorId)
		},
		{
			onSuccess: async () => {
				toast.notify({
					type: TypeToast.SUCCESS,
					message: t('common:TOAST_DELETE_RULE:SUCCESS'),
				})

				goToRoutePath('/rules')
			},
			onError: () => {
				toast.notify({
					type: TypeToast.ERROR,
					message: t('common:TOAST_DELETE_RULE:ERROR'),
				})
			},
		},
	)

	return {
		isLoading,
		deleteRule,
	}
}
