import { createStyles, makeStyles } from '@material-ui/core/styles'

export const styles = makeStyles(() =>
	createStyles({
		container: {
			display: 'flex',
			alignItems: 'center',

			'& > div': {
				height: 'var(--space-4)',
			},
		},
		warning: {
			color: 'var(--colors-semanticWarningBasis)',
		},
		pending: {
			color: 'var(--colors-semanticInfoBasis)',
		},
		rejected: {
			color: 'var(--colors-semanticErrorText)',
		},
	}),
)
