import { load } from '@/services/transactionV2/TransactionService'
import { transactionInitialState, TransactionState } from '@/stores/transactionV2/TransactionStore'
import { useLogService as logService } from 'admin-portal-shared-services'
import { getDefaultVendorIdOfCurrentZone } from '@/utils/getDefaultVendorId'

interface ILoadTransactionRequest {
	page?: number
	pageSize?: number
}

const log = logService()

export const execute = async (params?: ILoadTransactionRequest): Promise<TransactionState> => {
	try {
		const vendorId = getDefaultVendorIdOfCurrentZone()

		const { page: defaultPage, pageSize: defaultPageSize } = transactionInitialState.pagination

		const result = await load(vendorId, {
			page: params?.page ?? defaultPage,
			pageSize: params?.pageSize ?? defaultPageSize,
		})

		return result
	} catch (error) {
		log.error(`Error load: transactions`, error)
		throw error
	}
}
