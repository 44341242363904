import React from 'react'
import { useTranslation } from 'react-i18next'
import { Table, TableProps } from '@hexa-ui/components'
import { GridContainer } from '@/components/templates/GridTemplate'
import TierMessage from '@/routes/rules/RulesRedesign/Components/TierMessage/TierMessage'
import { TableContainer } from './GenericRuleList.styles'

export interface GenericRulesListProps<T> extends TableProps<T> {
	description: string
}

export const GenericRulesList = <T,>({ description, ...props }: GenericRulesListProps<T>) => {
	const { t } = useTranslation()

	return (
		<GridContainer>
			<TierMessage tierMessagePath={description} />

			<TableContainer>
				<Table emptyMessage={t('EMPTY_DATA_TABLE')} {...props} />
			</TableContainer>
		</GridContainer>
	)
}

export default GenericRulesList
