import React, { useEffect } from 'react'
import { setAppHeaderConfig } from 'admin-portal-shared-services'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import useCreateRulesHeaderPayload from '@/routes/rules/hooks/useCreateRulesHeaderPayload/useCreateRulesHeaderPayload'
import useEditMode from '@/routes/rules/hooks/useEditMode/useEditMode'
import useConfirmationModal from '@/hooks/useConfirmationModal/useConfirmationModal'
import { useDeleteExclusionRule } from '@/hooks/useDeleteExclusionRule/useDeleteExclusionRule'
import { EnuStatusRule } from '@/services/rules/RulesService.types'
import { useCreateExclusionRule } from '@/services/rules/hooks/useCreateExclusionRule'
import { useUpdateExclusionRule } from '@/services/rules/hooks/useUpdateExclusionRule'
import { getRuleIdFromPathname } from '@/utils/getRuleIdFrompathName'
import { useHasPermissionRules } from '@/routes/rules/hooks/useHasPermissionRules/useHasPermissionRules'
import { useNavigateToRules } from '@/routes/rules/hooks/useNavigateToRules'
import ActionButtonsWrapper from '../../Components/ActionButtonsWrapper/ActionButtonsWrapper'
import AudienceExclusion from './components/AudienceExclusion/AudienceExclusion'
import { SettingsExclusion } from './components/SettingsExclusion/SettingsExclusion'
import { useHandlersExclusionActionsButtons } from './hooks/useHandlersExclusionActionsButtons/useHandlersExclusionActionsButtons'
import { createExclusionBreadcrumb } from './utils/createExclusionBreadcrumb'
import { CreateExclusionRuleFormData } from './context/CreateExclusionListProvider/CreateExclusionListProvider.types'
import { TierMessage } from '../../Components/TierMessage/TierMessage'
import * as Styles from './CreateExclusionPageRule.styles'

const CreateExclusionPageRule: React.FC = () => {
	const { t } = useTranslation()
	const actionsButtonsProps = useHandlersExclusionActionsButtons()
	const { hasCreateExclusionListPermission, hasCreateEarningRulesPermission } = useHasPermissionRules()
	const { postExclusionRule } = useCreateExclusionRule()
	const { putExclusionRule } = useUpdateExclusionRule()
	const { goToRulesList } = useNavigateToRules()
	const hasRuleId = getRuleIdFromPathname()
	const { deleteRule } = useDeleteExclusionRule()

	const { isEditMode, setEditAction } = useEditMode()

	const {
		getValues,
		watch,
		formState: { isValid },
	} = useFormContext<CreateExclusionRuleFormData>()

	const status = watch('status')
	const exclusionRuleId = watch('ruleId')
	const hasAudienceId = !!watch('audienceId')

	const { showModal: showConfimationExclusionModal } = useConfirmationModal({
		confirmLabel: t('rules:MODAL_EXCLUSION.CONFIRM_LABEL'),
		cancelLabel: t('rules:MODAL_EXCLUSION.CANCEL_LABEL'),
		title: t('rules:MODAL_EXCLUSION.TITLE'),
		body: t('rules:MODAL_EXCLUSION.BODY'),
		confirmVariant: 'destructive',
	})
	const { showModal: showUpdateRuleModal } = useConfirmationModal({
		confirmLabel: t('rules:MODAL_UPDATE.CONFIRM_LABEL'),
		cancelLabel: t('rules:MODAL_UPDATE.CANCEL_LABEL'),
		title: t('rules:MODAL_UPDATE.TITLE'),
		body: t('rules:MODAL_UPDATE.BODY'),
	})

	/* istanbul ignore next */
	const handleDeleteRule = async () => {
		const confirmexclusion = await showConfimationExclusionModal()

		if (confirmexclusion) {
			deleteRule()
		}
	}

	const handleEditRule = /* istanbul ignore next */ () => {
		/* istanbul ignore next */
		setEditAction()
	}

	const headerPayload = useCreateRulesHeaderPayload({
		handleDeleteRule,
		handleEditRule,
		hasEditPermission: hasCreateExclusionListPermission,
		hasDeletePermission: hasCreateExclusionListPermission,
		isOnEditPage: isEditMode && !!hasRuleId,
		exclusionRule: { ruleId: exclusionRuleId, status },
	})

	useEffect(() => {
		if (hasRuleId) setAppHeaderConfig(headerPayload)

		if (!hasCreateEarningRulesPermission) {
			/* istanbul ignore else */
			if (!hasRuleId) {
				setAppHeaderConfig({ breadcrumbConfig: createExclusionBreadcrumb })
			}
		}

		if (!hasCreateExclusionListPermission && hasRuleId && status === 'DRAFT') {
			goToRulesList()
		}

		return () => {
			setAppHeaderConfig({
				optionalButtons: [],
			})
		}
	}, [
		hasCreateEarningRulesPermission,
		headerPayload,
		hasRuleId,
		status,
		goToRulesList,
		hasCreateExclusionListPermission,
	])

	const handleSaveAsDraft = async () => {
		const payload = getValues()

		if (hasRuleId) {
			const isToUpdate = await showUpdateRuleModal()

			/* istanbul ignore else */
			if (isToUpdate) {
				putExclusionRule({ ...payload, ruleId: hasRuleId, status: EnuStatusRule.draft })
			}
			return
		}

		postExclusionRule({ ...payload, status: EnuStatusRule.draft })
	}

	const handleSubmit = async () => {
		const payload = getValues()

		if (hasRuleId) {
			const isToUpdate = await showUpdateRuleModal()

			if (isToUpdate) {
				putExclusionRule({ ...payload, ruleId: hasRuleId, status: EnuStatusRule.active })
			}
			return
		}

		postExclusionRule({ ...payload, status: EnuStatusRule.active })
	}

	return (
		<Styles.Container>
			{isEditMode ? <TierMessage tierMessagePath="rules:CREATE_EXCLUSION_RULES.EXCLUSION_DESCRIPTION" /> : <br />}
			<SettingsExclusion />
			<AudienceExclusion />
			<ActionButtonsWrapper
				{...actionsButtonsProps}
				isSaveAndExitButtonDisabled={!isValid}
				isSubmitButtonDisabled={!(hasAudienceId && isValid && hasCreateExclusionListPermission)}
				onActionSaveAndExit={status === EnuStatusRule.draft && isEditMode ? handleSaveAsDraft : undefined}
				onActionSubmit={isEditMode && hasCreateExclusionListPermission ? handleSubmit : undefined}
				isGoBackButtonDisabled={!isEditMode ? undefined : true}
			/>
		</Styles.Container>
	)
}

export default CreateExclusionPageRule
