import { RewardsOfferType, TransactionType } from '@/domains/enums'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { TransactionStore } from '@/stores'
import { createDataRefund, createDataBulk, createDataToPoints } from '@/utils/createDataSegmentationEvents'
import { useStoreMap } from 'effector-react'
import { FormikValues } from 'formik'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import { useSelectedReward } from '@/context/RewardsTypeSelectedContext/RewardsTypeSelectedContext'
import * as FeatureToggle from '@/utils/featureToggle'
import { useFeatureToggleV2 } from '../toggle/useFeatureToggle'

export type TransactionSegmentation = {
	handleSegmentationEvents: (formValues: FormikValues) => void
}

const useTransactionSegmentationEvents = (): TransactionSegmentation => {
	const isToggleRefundRedeemableItemsEnabled = useFeatureToggleV2(FeatureToggle.CREATE_REFUND_REDEEMABLES)

	const userInfo = useGetUserInfo()
	const { rewardSelected } = useSelectedReward()
	const selectedOrder = useStoreMap({
		store: TransactionStore,
		keys: [],
		fn: (state) => state.selectedOrder,
	})

	const transactionType = useStoreMap({
		store: TransactionStore,
		keys: [],
		fn: (state) => state.transactionType,
	})

	const rewardsOfferType = useStoreMap({
		store: TransactionStore,
		keys: [],
		fn: (state) => state.rewardsOfferType,
	})

	const isPointsRemoval =
		transactionType === TransactionType.PointsRemoval ||
		(transactionType === TransactionType.RewardsOffer && rewardsOfferType === RewardsOfferType.SinglePoc) ||
		transactionType === TransactionType.ServiceUtility ||
		transactionType === TransactionType.PointsTransferFrom

	const handleSegmentationEvents = (formValues: FormikValues): void => {
		if (transactionType === TransactionType.Refund && selectedOrder) {
			const dataRefund = createDataRefund({
				transactionType,
				selectedOrder,
				isToggleRefundRedeemableItemsEnabled: isToggleRefundRedeemableItemsEnabled!,
			})
			AnalyticsService.events.manualRefundTransactionCreated(dataRefund)
		}

		if (transactionType === TransactionType.RewardsOffer && rewardsOfferType === RewardsOfferType.MultiplePoc) {
			const dataBulk = createDataBulk(transactionType, formValues)
			AnalyticsService.events.bulkPointsTransactionCreated(dataBulk)
		}

		if (isPointsRemoval) {
			const dataPoints = createDataToPoints(transactionType, formValues, userInfo, rewardSelected)
			AnalyticsService.events.pointsTransactionCreated(dataPoints)
		}
	}

	return {
		handleSegmentationEvents,
	}
}

export default useTransactionSegmentationEvents
