import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import { setAppHeaderConfig } from 'admin-portal-shared-services'
import useCreateRulesHeaderPayload from '@/routes/rules/hooks/useCreateRulesHeaderPayload/useCreateRulesHeaderPayload'
import useEditMode from '@/routes/rules/hooks/useEditMode/useEditMode'
import useConfirmationModal from '@/hooks/useConfirmationModal/useConfirmationModal'
import { useDeleteEarningRule } from '@/hooks/useDeleteEarningRule/useDeleteEarningRule'
import { getRuleIdFromPathname } from '@/utils/getRuleIdFrompathName'
import { useCreateEarningRule } from '@/services/rules/hooks/useCreateEarningRule'
import { useUpdateEarningRule } from '@/services/rules/hooks/useUpdateEarningRule'
import PageLevelTemplate from '@/components/templates/PageLevelTemplate'
import { EnuStatusRule } from '@/services/rules/RulesService.types'
import { useHasPermissionRules } from '@/routes/rules/hooks/useHasPermissionRules/useHasPermissionRules'
import { useNavigateToRules } from '@/routes/rules/hooks/useNavigateToRules'
import { TierMessage } from '../../Components/TierMessage/TierMessage'
import { Settings } from './components/Settings/Settings'
import { SkuList } from './components/SkuList/SkuList'
import ActionButtonsWrapper from '../../Components/ActionButtonsWrapper/ActionButtonsWrapper'
import { useHandlersActionsButtons } from './hooks/useHandlersActionsButtons/useHandlersActionsButtons'
import { CreateEarningRuleFormData } from './context/CreateEarningRuleProvider/CreateEarningRuleProvider.types'
import * as Style from './CreateEarningRule.styles'

const CreateEarningRule: React.FC = () => {
	const { t } = useTranslation()
	const actionsButtonsProps = useHandlersActionsButtons()
	const { hasCreateEarningRulesPermission } = useHasPermissionRules()
	const { setEditAction, isEditMode } = useEditMode()
	const hasRuleId = getRuleIdFromPathname()
	const { postCreateEarningRule } = useCreateEarningRule()
	const { putEarningRule } = useUpdateEarningRule()
	const { deleteRule } = useDeleteEarningRule()
	const { goToRulesList } = useNavigateToRules()

	const {
		handleSubmit,
		watch,
		getValues,
		formState: { isValid },
	} = useFormContext<CreateEarningRuleFormData>()

	const hasSkus = !!watch('items')?.length
	const status = getValues('status')

	const { showModal: showConfimationExclusionModal } = useConfirmationModal({
		confirmLabel: t('rules:MODAL_EXCLUSION.CONFIRM_LABEL'),
		cancelLabel: t('rules:MODAL_EXCLUSION.CANCEL_LABEL'),
		title: t('rules:MODAL_EXCLUSION.TITLE'),
		body: t('rules:MODAL_EXCLUSION.BODY'),
		confirmVariant: 'destructive',
	})

	const { showModal: showCreateRuleModal } = useConfirmationModal({
		confirmLabel: t('rules:MODAL_CREATE.CONFIRM_LABEL'),
		cancelLabel: t('rules:MODAL_CREATE.CANCEL_LABEL'),
		title: t('rules:MODAL_CREATE.TITLE'),
		body: t('rules:MODAL_CREATE.BODY'),
	})

	const { showModal: showUpdateRuleModal } = useConfirmationModal({
		confirmLabel: t('rules:MODAL_UPDATE.CONFIRM_LABEL'),
		cancelLabel: t('rules:MODAL_UPDATE.CANCEL_LABEL'),
		title: t('rules:MODAL_UPDATE.TITLE'),
		body: t('rules:MODAL_UPDATE.BODY'),
	})

	/* istanbul ignore next */
	const handleDeleteRule = async () => {
		const confirmexclusion = await showConfimationExclusionModal()

		if (confirmexclusion) {
			deleteRule()
		}
	}

	const handleEditRule = /* istanbul ignore next */ () => {
		/* istanbul ignore next */
		setEditAction()
	}

	const headerPayload = useCreateRulesHeaderPayload({
		handleDeleteRule,
		handleEditRule,
		hasEditPermission: true,
		hasDeletePermission: hasCreateEarningRulesPermission,
		isOnEditPage: isEditMode && !!hasRuleId,
		earningRule: {
			amountSpent: getValues('amountSpent'),
			points: getValues('points'),
			ruleId: getValues('ruleId') ?? '',
			status,
		},
	})

	useEffect(() => {
		if (getValues()?.ruleId) setAppHeaderConfig(headerPayload)

		if (!hasCreateEarningRulesPermission && status === 'DRAFT') goToRulesList()

		return () => {
			setAppHeaderConfig({
				optionalButtons: [],
			})
		}
	}, [getValues, hasCreateEarningRulesPermission, status, headerPayload, goToRulesList])

	const onSaveAsDraft = (formData: CreateEarningRuleFormData) => {
		const payload = { ...formData, status: EnuStatusRule.draft }

		if (hasRuleId) {
			putEarningRule({ ...payload, ruleId: hasRuleId })
			return
		}

		postCreateEarningRule({ ...payload })
	}

	const onSelectModalAction = async () => {
		const formData = getValues()
		const payload = { ...formData, status: EnuStatusRule.active }

		if (hasRuleId) {
			const isToUpdate = await showUpdateRuleModal()

			/* istanbul ignore next */
			if (isToUpdate) {
				if (!hasCreateEarningRulesPermission) {
					putEarningRule({ items: payload.items, status: payload.status, ruleId: hasRuleId } as any)
				} else {
					putEarningRule({ ...payload, ruleId: hasRuleId })
				}
			}
			return
		}
		const isToConfirm = await showCreateRuleModal()

		/* istanbul ignore else */
		if (isToConfirm) {
			postCreateEarningRule({ ...payload })
		}
	}

	return (
		<>
			<Style.Container>
				<PageLevelTemplate />
				{isEditMode ? <TierMessage tierMessagePath="rules:CREATE_EARNING_RULES.EARNING_DESCRIPTION" /> : <br />}
				<Settings />
				<SkuList />
			</Style.Container>

			<ActionButtonsWrapper
				{...actionsButtonsProps}
				isSubmitButtonDisabled={!(hasSkus && isValid)}
				isSaveAndExitButtonDisabled={!isValid}
				onActionSaveAndExit={status === EnuStatusRule.draft && isEditMode ? handleSubmit(onSaveAsDraft) : undefined}
				onActionSubmit={isEditMode ? onSelectModalAction : undefined}
				isGoBackButtonDisabled={!isEditMode ? undefined : true}
			/>
		</>
	)
}

export default CreateEarningRule
