import React from 'react'
import { useStoreMap } from 'effector-react'
import EditProgramFormInputs from 'routes/programConfiguration/models/EditProgramFormInputs'
import { PermissionLevel } from '@/domains/enums'
import { useFeatureToggleV2 } from '@/hooks'
import useAuthorization from '@/hooks/authorization/useAuthorization'
import { ProgramConfigurationEvents, ProgramConfigurationState, ProgramConfigurationStore } from '@/stores'
import * as FeatureToggle from '@/utils/featureToggle'
import { Program } from '@/domains'
import { useUpdateProgram } from '@/hooks/program/useUpdateProgram'
import EditProgramConfigurationView from './EditProgramConfigurationView'

const EditProgramConfiguration: React.FC = () => {
	const { updateProgram } = useUpdateProgram()
	const { isFeatureAuthorized } = useAuthorization()
	const { showEditDialog, program } = useStoreMap({
		store: ProgramConfigurationStore,
		keys: [],
		fn: (state: ProgramConfigurationState) => ({ showEditDialog: state.showEditDialog, program: state.programData }),
	})

	const programConfigurationModuleToggle = useFeatureToggleV2(FeatureToggle.PROGRAM_CONFIGURATION_MODULE)

	const initialBalanceModule = isFeatureAuthorized(programConfigurationModuleToggle!, {
		feature: 'InitialBalance',
		permissionLevel: PermissionLevel.Delete,
	})

	const handleCancel = () => {
		ProgramConfigurationEvents.setShowEditDialog(false)
	}

	const handleSubmit = async (values: EditProgramFormInputs) => {
		/* istanbul ignore else */
		if (program) {
			const formValues: Program = {
				...program,
				name: values.name,
				initialBalance: values.initialBalance,
			}
			updateProgram(formValues)
		}
	}

	return showEditDialog ? (
		<EditProgramConfigurationView
			showEditDialog={showEditDialog}
			program={program}
			onSubmit={handleSubmit}
			onCancel={handleCancel}
			enabledInitialBalanceInput={initialBalanceModule}
		/>
	) : null
}
export default EditProgramConfiguration
