import { createEvent, createStore } from 'effector'

type DefaultCppState = {
	reloadAtTime: number
}

export const defaultCppInitialState: DefaultCppState = {
	reloadAtTime: Date.now(),
}

export const DefaultCppStoreEvents = {
	reload: createEvent(),
}

export const $defaultCppStore = createStore(defaultCppInitialState).on(DefaultCppStoreEvents.reload, (state) => ({
	...state,
	reloadAtTime: Date.now(),
}))
