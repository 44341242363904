import { getDefaultVendorIdOfCurrentZone } from '@/utils/getDefaultVendorId'
import { loadByAccountIdRewards } from '@/services/rewards/RewardsService'
import { AccountIdData } from '@/domains/account/AccountIdData'
import { AccountValidationResult } from '@/domains/enums'
import * as VerifyEnrollmentUseCase from '@/usecase/rewards/VerifyEnrollmentUseCase'
import { hasText } from '@/utils/string'

export async function execute({
	vendorAccountId,
	isToggleMultivendorValidateAccountEnabled,
}: {
	vendorAccountId: string
	isToggleMultivendorValidateAccountEnabled: boolean
}): Promise<string> {
	if (isToggleMultivendorValidateAccountEnabled) {
		const vendorId = getDefaultVendorIdOfCurrentZone()
		const account = (await loadByAccountIdRewards(vendorAccountId, vendorId)) || {}
		if (account?.accountId) {
			return account.accountId
		}
		throw new Error('Invalid accountId')
	}
	return vendorAccountId
}

export async function findValidAccountId({
	pocId,
	isToggleMultivendorValidateAccountEnabled,
}: {
	pocId: string
	isToggleMultivendorValidateAccountEnabled: boolean
}): Promise<AccountIdData> {
	if (!hasText(pocId)) {
		return {
			validationResult: AccountValidationResult.INVALID,
		}
	}
	const accountId = await execute({ vendorAccountId: pocId, isToggleMultivendorValidateAccountEnabled }).catch(
		() => null,
	)
	if (!accountId) {
		return {
			validationResult: AccountValidationResult.INVALID,
		}
	}
	const accountEnrolled = await VerifyEnrollmentUseCase.verifyPocEnroll(accountId)
	if (!accountEnrolled) {
		return {
			validationResult: AccountValidationResult.NOT_ENROLLED,
		}
	}
	return {
		accountId,
		validationResult: AccountValidationResult.OK,
	}
}
