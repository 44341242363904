import React from 'react'
import { Heading } from '@hexa-ui/components'
import { useTranslation } from 'react-i18next'
import { useCurrentTier } from '@/hooks/tier/useCurrentTier'
import { Styled } from './RuleTypeSelection.styles'
import { RuleTypeSelectionProps } from './RuleTypeSelection.types'
import useCardSelection from '../hooks/useCardSelection/useCardSelection'

const RuleTypeSelection: React.FC<RuleTypeSelectionProps> = ({ onCardSelect }) => {
	const { t } = useTranslation()
	const { currentTier } = useCurrentTier()
	const { selectedCard, handleCardSelect } = useCardSelection()
	const handleEarningRuleSelect = () => handleCardSelect('EARNING_RULE', onCardSelect)
	const handleExclusionRuleSelect = () => handleCardSelect('EXCLUSION_RULE', onCardSelect)

	return (
		<Styled.RuleTypeContainer border="medium" elevated="medium">
			<Styled.RuleTypeGap>
				<Heading size="H3">{t('rules:CREATE_RULE_PAGE.TITLE')}</Heading>
				<Styled.RuleTypeDescription>{t('rules:CREATE_RULE_PAGE.DESCRIPTION')}</Styled.RuleTypeDescription>
			</Styled.RuleTypeGap>
			<Styled.RuleTypeCard
				border="small"
				elevated="minimal"
				isSelected={selectedCard === 'EARNING_RULE'}
				onClick={handleEarningRuleSelect}
			>
				<Styled.RuleTypeGap>
					<Styled.RuleTypeTitle>{t('rules:CREATE_RULE_PAGE.EARNING_RULE_TITLE')}</Styled.RuleTypeTitle>
					<Styled.RuleTypeDescription>
						{t('rules:CREATE_RULE_PAGE.EARNING_RULE_DESCRIPTION', { tier: currentTier?.label })}
					</Styled.RuleTypeDescription>
				</Styled.RuleTypeGap>
			</Styled.RuleTypeCard>

			<Styled.RuleTypeCard
				border="small"
				elevated="minimal"
				isSelected={selectedCard === 'EXCLUSION_RULE'}
				onClick={handleExclusionRuleSelect}
			>
				<Styled.RuleTypeGap>
					<Styled.RuleTypeTitle>{t('rules:CREATE_RULE_PAGE.EXCLUSION_RULE_TITLE')}</Styled.RuleTypeTitle>
					<Styled.RuleTypeDescription>
						{t('rules:CREATE_RULE_PAGE.EXCLUSION_RULE_DESCRIPTION', { tier: currentTier?.label })}
					</Styled.RuleTypeDescription>
				</Styled.RuleTypeGap>
			</Styled.RuleTypeCard>
		</Styled.RuleTypeContainer>
	)
}

export default RuleTypeSelection
