import { SettingsLevel } from '@/domains/enums/settings'
import { createNewRule, CreateNewRuleParams } from '@/services/settings/SettingsService'
import { AxiosResponse } from 'axios'

export interface CreateRuleParams {
	vendorId: string
	country: string | null
	selectedTierKey: string | null
	amountSpent: number
	points: number
	categoryId: string
	description?: string
	ruleId: string
}
export async function execute({
	paramsUseCase,
	isToggleSettingsConfigByZoneEnabled,
}: {
	paramsUseCase: CreateRuleParams
	isToggleSettingsConfigByZoneEnabled: boolean
}): Promise<AxiosResponse> {
	const { selectedTierKey, categoryId = '', description = '', points, amountSpent, ruleId } = paramsUseCase
	const level = isToggleSettingsConfigByZoneEnabled ? SettingsLevel.ZONE : SettingsLevel.VENDOR
	const settingsId = isToggleSettingsConfigByZoneEnabled ? paramsUseCase.country : paramsUseCase.vendorId
	const params = {
		ruleId,
		level,
		settingsId,
		tier: selectedTierKey,
		rule: { amountSpent, points, categoryId, description },
	} as CreateNewRuleParams
	return createNewRule(params)
}
