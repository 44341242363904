import { getExclusionRuleById } from '@/services/rules/RulesService'
import { useQuery } from 'react-query'
import { getRuleIdFromPathname } from '@/utils/getRuleIdFrompathName'
import { useNavigateToRules } from '@/routes/rules/hooks/useNavigateToRules'
import useCurrentSettings from '../settings/useCurrentSettings'

export const useGetExclusionRuleById = () => {
	const settings = useCurrentSettings()
	const { vendorId } = settings
	const { goToRulesList } = useNavigateToRules()
	const ruleId = getRuleIdFromPathname()

	return useQuery({
		queryKey: ['exclusionRules', vendorId, ruleId],
		queryFn: async () => {
			const data = await getExclusionRuleById({
				vendorId,
				ruleId,
			})

			return data.content[0]
		},
		enabled: !!ruleId,
		onSuccess: (data) => {
			if (!data) {
				goToRulesList()
			}
		},
	})
}
