import React from 'react'
import { useTranslation } from 'react-i18next'
import { Alert } from '@hexa-ui/components'
import { useFeatureToggleV2 } from '@/hooks'
import * as FeatureToggle from '@/utils/featureToggle'
import { $redeemableItemLimitStore } from '@/stores/redeemableItems/RedeemableItemLimitStore'
import { useStore } from 'effector-react'
import LimitExceededCardStyle from './LimitExceededCardStyle'

export const LimitExceededCard = () => {
	const isRedeemableRestrictCppVisibilityEnabled = useFeatureToggleV2(FeatureToggle.REDEEMABLE_RESTRICT_CPP_VISIBILITY)

	const { t } = useTranslation()
	const css = LimitExceededCardStyle()

	const { limit, exceededLimitAmount, isLimitExceeded } = useStore($redeemableItemLimitStore)

	const redeemablesExceededNumber = exceededLimitAmount ?? 0

	const messageText =
		redeemablesExceededNumber > 1
			? 'redeemable:SETTINGS.REDEEMABLES_LIMIT_EXCEEDED.PLURAL'
			: 'redeemable:SETTINGS.REDEEMABLES_LIMIT_EXCEEDED.SINGULAR'

	if (!limit || !isLimitExceeded || !isRedeemableRestrictCppVisibilityEnabled) {
		return null
	}

	return (
		<Alert
			message={t(messageText, { limitExceeded: redeemablesExceededNumber })}
			type="warning"
			className={css.warningMessage}
		/>
	)
}
