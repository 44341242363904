import { getStatus } from 'utils/challengeStatus'
import { Challenge, ChallengeHistory, Group } from '@/domains'

interface CopiedFilters {
	groupsFilter?: { id: string; name?: string }[]
}

const copyFilters = ({ filter }: Challenge, groupMap: Map<string, string> | null): CopiedFilters => {
	const copy = {} as CopiedFilters
	if (filter) {
		if (filter.groupIds && groupMap) {
			const groupNames = filter.groupIds.map((group) => {
				return { id: group, name: groupMap.get(group) }
			})

			copy.groupsFilter = []
			groupNames.forEach((group) => {
				if (group.name && copy.groupsFilter) {
					copy.groupsFilter.push(group)
				}
			})
		}
	}
	return copy
}

const reduceToMap = (groups: Group[]): Map<string, string> => {
	return groups.reduce((map, group) => {
		map.set(group.id, group.name)
		return map
	}, new Map<string, string>())
}

const convert = (challenge: Challenge, groups: Group[] | null, groupMap?: Map<string, string>): ChallengeHistory => {
	const mapOfGroups = groups ? groupMap || reduceToMap(groups) : null

	const translates = challenge.translations?.map((translate) => ({
		[`title-${translate.languageId}`]: translate.title,
		[`description-${translate.languageId}`]: translate.description,
		[`image-${translate.languageId}`]: translate.image?.url,
		[`squareImage-${translate.languageId}`]: translate.squareImage?.url,
		[`goodPhotoSample-${translate.languageId}`]: translate.goodPhotoSample?.url,
		[`badPhotoSample-${translate.languageId}`]: translate.badPhotoSample?.url,
	}))

	return {
		id: challenge.id,
		campaignId: challenge.campaignId,
		title: challenge.title,
		description: challenge.description,
		detailedDescription: challenge.detailedDescription,
		quantityMin: challenge.quantityMin,
		points: challenge.points,
		executionMethod: challenge.executionMethod,
		image: challenge.image,
		squareImage: challenge.squareImage,
		goodPhotoSample: challenge.goodPhotoSample,
		badPhotoSample: challenge.badPhotoSample,
		status: getStatus(challenge),
		startDate: challenge.startDate,
		endDate: challenge.endDate,
		...copyFilters(challenge, mapOfGroups),
		skus: challenge.skus,
		items: challenge.items,
		mode: challenge.mode,
		individualTarget: challenge.individualTarget,
		...translates?.reduce((acc, translate) => ({ ...acc, ...translate }), {}),
	} as ChallengeHistory
}

const convertAll = (challenges: Challenge[], groups: Group[]): ChallengeHistory[] => {
	return challenges?.length >= 1 ? challenges.map((challenge) => convert(challenge, groups, reduceToMap(groups))) : []
}

export { convertAll }
export default convert
