/* eslint-disable no-unused-expressions */
import { Challenge } from '@/domains'
import { ChallengeExecutionMethod } from '@/domains/enums'
import { IAuditEntities, IAuditLog, IAuditOperations } from '@/hooks/useAdminAuditLog'
import { Account } from '@/stores/challengeCreation'

interface ItemChallengeProps {
	formData: Challenge
	individualTargetAccounts: Account[]
	groupName?: string
}

interface IParsedGroup {
	pocsIds: string
	points?: string
}

const parseIndividualTargetToGroup = (
	individualTargetAccounts: Account[],
	method: ChallengeExecutionMethod,
): string => {
	const targetToGroup = individualTargetAccounts.map((target): IParsedGroup => {
		const parsedObject = {
			pocsIds: target.accountId.toString(),
		}
		method === ChallengeExecutionMethod.VolumeFixed && Object.assign(parsedObject, { points: target.points.toString() })
		return parsedObject
	})
	return JSON.stringify(targetToGroup)
}

const parseGroup = (pocIds: string[] | undefined, name?: string): string => {
	const parsedObject =
		pocIds && pocIds.length > 0
			? {
					name,
					pocIds,
				}
			: []
	return JSON.stringify(parsedObject)
}

export const generateChallengeData = ({
	formData,
	individualTargetAccounts,
	groupName,
}: ItemChallengeProps): IAuditLog => {
	const challengeGoal = (): string => {
		if (formData?.executionMethod === ChallengeExecutionMethod.VolumeFixed) return 'Units'
		if (formData?.executionMethod === ChallengeExecutionMethod.SpendBased) return 'Money'
		return ''
	}

	const metaProps = {
		campaignId: formData.campaignId!,
		challengeType: formData.executionMethod,
		challengeTitle: formData.title,
		challengeDescription: formData.description,
		challengeStartDate: formData.startDate.toString(),
		challengeEndDate: formData.endDate.toString(),
		challengeGoal: challengeGoal(),
		challengeStatus: 'created',
		coverImage: formData.image!,
		thumbnailImage: formData.squareImage,
		creationDate: new Date().toString(),
		filter: JSON.stringify(formData.filter),
		group: formData.individualTarget
			? parseIndividualTargetToGroup(individualTargetAccounts, formData.executionMethod)
			: parseGroup(formData.filter.groupIds, groupName),
	}

	ChallengeExecutionMethod.VolumeFixed !== formData.executionMethod &&
		Object.assign(metaProps, { challengePoints: formData.points }) &&
		Object.assign(metaProps, { skus: JSON.stringify(formData.skus) })

	ChallengeExecutionMethod.VolumeFixed === formData.executionMethod &&
		Object.assign(metaProps, { volumeSkuQuantity: formData.quantityMin?.toString() }) &&
		formData.individualTarget !== null &&
		Object.assign(metaProps, { targetType: formData.individualTarget ? 'Individual' : 'General' })

	ChallengeExecutionMethod.TakePhoto === formData.executionMethod &&
		Object.assign(metaProps, { goodImage: formData.goodPhotoSample! }) &&
		Object.assign(metaProps, { badImage: formData.badPhotoSample! })

	return {
		metadata: metaProps,
		operation: IAuditOperations.INSERT,
		entity: IAuditEntities.CHALLENGES,
		entityId: formData.id,
	}
}
