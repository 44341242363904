import { useFeatureToggleV2 } from '@/hooks'
import { useAnalyticsServiceEventButtonClicked } from '@/hooks/analyticsService/useAnalyticsServiceEventButtonClicked'
import { CreateRedeemableItemStoreEvents } from '@/stores'
import * as FeatureToggle from '@/utils/featureToggle'
import { Button, Tooltip } from '@hexa-ui/components'
import React, { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { $redeemableItemLimitStore } from '@/stores/redeemableItems/RedeemableItemLimitStore'
import { useStore } from 'effector-react'

export const CreateRedeemableItemButton = React.forwardRef(() => {
	const { limit, totalRedeemableItems } = useStore($redeemableItemLimitStore)

	const { sendAnalyticsServiceEventButtonClickedEvent } = useAnalyticsServiceEventButtonClicked()
	const isNewTransactionLogsToggleEnabled = useFeatureToggleV2(FeatureToggle.NEW_TRANSACTION_LOGS)
	const isRedeemableRestrictCppVisibilityEnabled = useFeatureToggleV2(FeatureToggle.REDEEMABLE_RESTRICT_CPP_VISIBILITY)
	const { t } = useTranslation()

	const buttonI18nText = isNewTransactionLogsToggleEnabled
		? 'redeemable:BUTTON.ADD_NEW'
		: 'redeemable:BUTTON.CREATE_NEW'
	const tooltipText = limit ? 'redeemable:LIMIT:TOOLTIP_UPDATE' : 'redeemable:LIMIT:TOOLTIP_SET'
	const newlimit = limit ?? 0
	const isLimitSameOrExceeded = totalRedeemableItems >= newlimit

	const onClickHandler = useCallback(
		(evt: React.MouseEvent) => {
			evt.preventDefault()
			CreateRedeemableItemStoreEvents.onOpen()
			sendAnalyticsServiceEventButtonClickedEvent('Redeemables List', 'Create redeemable - Empty', 'Create redeemable')
		},
		[sendAnalyticsServiceEventButtonClickedEvent],
	)

	const renderButton = useMemo(() => {
		return (
			<Button
				size="medium"
				variant="primary"
				data-testid="redeemable-items-button-create"
				onClick={onClickHandler}
				disabled={isLimitSameOrExceeded && isRedeemableRestrictCppVisibilityEnabled}
			>
				{t(buttonI18nText)}
			</Button>
		)
	}, [isRedeemableRestrictCppVisibilityEnabled, isLimitSameOrExceeded, buttonI18nText, onClickHandler, t])

	return isLimitSameOrExceeded && isRedeemableRestrictCppVisibilityEnabled ? (
		<Tooltip placement="left" text={t(tooltipText)}>
			{renderButton}
		</Tooltip>
	) : (
		renderButton
	)
})
