import { useCallback, useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

interface UseEditModeProps {
	isEditMode: boolean
	setEditAction: () => void
	setViewAction: () => void
}

const PATHS = ['/membership/rules/exclusion-rule', '/membership/rules/earning-rule']

enum EAction {
	edit = 'edit',
	view = 'view',
}

const useEditMode = (): UseEditModeProps => {
	const [isEditMode, setEditMode] = useState(false)
	const location = useLocation()
	const history = useHistory()

	const setActionBase = useCallback(
		(actParam: EAction) => {
			const searchParams = new URLSearchParams(location.search)
			searchParams.set('action', actParam)
			history.push({ search: searchParams.toString() })
		},
		[history, location.search],
	)

	const setEditAction = useCallback(() => setActionBase(EAction.edit), [setActionBase])
	const setViewAction = useCallback(() => setActionBase(EAction.view), [setActionBase])

	useEffect(() => {
		const searchParams = new URLSearchParams(location.search)

		if (searchParams.get('action') === 'edit' || PATHS.includes(location.pathname)) {
			setEditMode(true)
		} else {
			setEditMode(false)
		}

		if (PATHS.includes(location.pathname) && searchParams.has('action')) {
			searchParams.delete('action')
			history.push({ search: searchParams.toString() })
		}
	}, [location.pathname, location.search])

	return {
		isEditMode,
		setEditAction,
		setViewAction,
	}
}

export default useEditMode
