export const getRuleIdFromPathname = () => {
	const fullUrl = window.location.href
	const url = new URL(fullUrl)
	const { pathname } = url
	const segments = pathname.split('/')
	const id = segments[segments.length - 1]

	const regex = /\b[a-f0-9]{24}\b/
	const isValidId = regex.test(id)
	const ruleId = isValidId ? id : undefined

	return ruleId
}
