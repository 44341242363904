import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ParAddtolist } from '@hexa-ui/icons'
import { IconProps } from '@admin-portal-shared-components/icons'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { getRuleIdFromPathname } from '@/utils/getRuleIdFrompathName'
import { useFormContext } from 'react-hook-form'
import { useHandleGoBackActions } from '@/routes/rules/hooks/useHandleGoBackActions/useHandleGoBackActions'
import { UseHandlersActionsButtonsReturn } from './useHandlersActionsButtons.types'
import { CreateEarningRuleFormData } from '../../context/CreateEarningRuleProvider/CreateEarningRuleProvider.types'

const VARIANT_COLOR = 'secondary'

export const useHandlersActionsButtons = (): UseHandlersActionsButtonsReturn => {
	const hasRuleId = getRuleIdFromPathname()
	const { t } = useTranslation()
	const userInfo = useGetUserInfo()
	const { getValues } = useFormContext<CreateEarningRuleFormData>()
	const { goBackHandler } = useHandleGoBackActions({ status: getValues('status') })

	const buttonLabelActionSaveAndExit = useMemo(() => t('rules:CREATE_EARNING_RULES.EARNING_SECTION.SAVE_AND_EXIT'), [t])
	const buttonLabelActionCancel = useMemo(() => t('rules:CREATE_EARNING_RULES.GO_BACK'), [t])
	const buttonLabelActionSubmit = useMemo(
		() => (hasRuleId ? t('rules:CREATE_EARNING_RULES.SAVE') : t('rules:CREATE_EARNING_RULES.CREATE_NEW_EARNING_RULE')),
		[t, hasRuleId],
	)

	const onActionCancel = useCallback(() => {
		AnalyticsService.events.buttonClicked({
			button_name: 'Cancel',
			button_label: 'Cancel New Earning Rule',
			screen_name: 'Earning Rules',
			...userInfo,
		})

		goBackHandler()
	}, [goBackHandler, userInfo])

	return {
		buttonLabelActionSaveAndExit,
		buttonLabelActionCancel,
		buttonLabelActionSubmit,
		onActionCancel,
		buttonVariantActionSaveAndExit: VARIANT_COLOR,
		buttonVariantActionCancel: VARIANT_COLOR,
		isSubmitButtonDisabled: true,
		isSaveAndExitButtonDisabled: true,
		iconLeadingPosition: true,
		icon: ParAddtolist as unknown as React.FunctionComponent<IconProps>,
		showLeaveConfirmationModal: true,
	}
}
