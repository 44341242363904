import React from 'react'
import * as Yup from 'yup'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { fieldNumberValidationRequiredMin1AndInteger } from '@/utils/validationObjects'
import { useTranslation } from 'react-i18next'
import { EarningRuleContent, EnuStatusRule } from '@/services/rules/RulesService.types'
import {
	useGetEarningRuleById,
	useGetEarningRuleByIdWithSkusNormalized,
} from '@/hooks/useGetEarningRuleById/useGetEarningRuleById'
import { useNavigateToRules } from '@/routes/rules/hooks/useNavigateToRules'
import { useLocation } from 'react-router-dom'
import { getRuleIdFromPathname } from '@/utils/getRuleIdFrompathName'
import * as I from './CreateEarningRuleProvider.types'

const CreateEarningRuleProvider: React.FC<I.CreateEarningRuleProviderProps> = ({ children }) => {
	const { data, isLoading } = useGetEarningRuleByIdWithSkusNormalized()
	const { data: earningRuleData } = useGetEarningRuleById()
	const { goToRulesList } = useNavigateToRules()
	const location = useLocation()
	const ruleId = getRuleIdFromPathname()
	const isEarningRulePath = location.pathname.includes('/membership/rules/earning-rule')

	if (isLoading) {
		return null
	}

	if (
		!earningRuleData &&
		isEarningRulePath &&
		ruleId === undefined &&
		location.pathname !== '/membership/rules/earning-rule'
	) {
		goToRulesList()
	}

	return <CreateEarningRuleConsumer data={data}>{children}</CreateEarningRuleConsumer>
}

const CreateEarningRuleConsumer = ({ data, children }: { data?: EarningRuleContent; children: React.ReactNode }) => {
	const { t } = useTranslation()

	const formSchema = useForm<I.CreateEarningRuleFormData>({
		mode: 'onChange',
		defaultValues: {
			points: data?.points,
			amountSpent: data?.amountSpent,
			description: data?.description,
			status: data?.status ?? EnuStatusRule.draft,
			items: data?.items,
			ruleId: data?.ruleId,
		},
		resolver: yupResolver(
			Yup.object().shape({
				points: fieldNumberValidationRequiredMin1AndInteger(),
				amountSpent: fieldNumberValidationRequiredMin1AndInteger(),
				description: Yup.string().required(t('ERROR_MESSAGE.EMPTY_FIELD')),
				items: Yup.array<string>(),
			}),
		),
	})

	return <FormProvider {...formSchema}> {children}</FormProvider>
}

export default CreateEarningRuleProvider
