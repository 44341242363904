import { styled } from '@bees/vision-tokens'

const Content = styled('div', {
	display: 'flex',
	flexDirection: 'column',
})
const Instructions = styled('div', {
	color: 'rgba(0,0,0,0.9)',
	fontFamily: 'Work Sans',
	fontSize: '14px',
	letterSpacing: '0.1px',
	lineHeight: '20px',
})

const UploadForm = styled('div', {
	width: '100%',
	display: 'flex',
	height: '40px',

	'& input[type="file"]': {
		display: 'none',
	},
	'& label': {
		padding: '18px 10px',
		textAlign: 'center',
		cursor: 'pointer',
		color: '#000',

		boxSizing: 'border-box',
		height: '40px',
		// width: '107px',
		border: ' 1px solid #000000',
		borderRadius: '20px',
		backgroundColor: '#FFFFFF',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',

		fontSize: '16px',
		fontWeight: '500',
	},

	'& label:hover': {
		backgroundColor: 'rgb(255 255 0)',
	},
})

const UploadIcon = styled('div', {
	width: '24px',
	height: '24px',
	borderRadius: '12px',
	backgroundColor: 'rgb(255 255 0)',
	marginRight: '8px',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
})

const InputFileName = styled('input', {
	width: '100%',
	boxSizing: 'border-box',
	border: '1px solid rgba(0,0,0,0.15)',
	borderRadius: '8px',
	backgroundColor: '#FFFFFF',
	marginRight: '16px',
	color: 'rgba(0,0,0,0.9)',
	fontFamily: 'Work Sans',
	fontSize: '16px',
	letterSpacing: '0.1px',
	lineHeight: '24px',
	paddingLeft: '16px',
})

const ErrorMessage = styled('span', {
	height: '18px',
	color: '#C9201D',
	fontFamily: 'Work Sans',
	fontSize: '12px',
	letterSpacing: '0.03px',
	lineHeight: '18px',
	marginBottom: '4px',
	display: 'flex',
	alignItems: 'center',
})

export default { Content, Instructions, UploadForm, UploadIcon, InputFileName, ErrorMessage }
