import React, { createContext, useContext, useState, ReactNode } from 'react'

interface RuleSelectedStatusContextProps {
	ruleSelectedStatus: string | null
	setRuleSelectedStatus: (status: string | null) => void
}

const RuleSelectedStatusContext = createContext<RuleSelectedStatusContextProps | undefined>(undefined)

export const RuleSelectedStatusProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const [ruleSelectedStatus, setRuleSelectedStatus] = useState<string | null>(null)

	const value = React.useMemo(() => ({ ruleSelectedStatus, setRuleSelectedStatus }), [ruleSelectedStatus])

	return <RuleSelectedStatusContext.Provider value={value}>{children}</RuleSelectedStatusContext.Provider>
}

export const useRuleSelectedStatus = (): RuleSelectedStatusContextProps => {
	const context = useContext(RuleSelectedStatusContext)
	if (context === undefined) {
		throw new Error('useRuleSelectedStatus must be used within a RuleSelectedStatusProvider')
	}
	return context
}
