import { Container, Grid, Typography } from '@material-ui/core'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { formatDate } from 'utils/date'
import { useFeatureToggleV2 } from '@/hooks'
import * as FeatureToggle from '@/utils/featureToggle'
import { useGetTransactionInfo } from '../../../TransactionForm/hooks/useGetTransactionInfo'
import styles from './styles'

const OrderSection: React.FC = () => {
	const classes = styles()
	const isToggleRefundRedeemableItemsEnabled = useFeatureToggleV2(FeatureToggle.CREATE_REFUND_REDEEMABLES)

	const { order, accountId, placementDate, status } = useGetTransactionInfo({
		isToggleRefundRedeemableItemsEnabled: isToggleRefundRedeemableItemsEnabled!,
	})
	const { t } = useTranslation()

	const renderDataRow = (title: string, value: string, id: string) => {
		return (
			<Grid container>
				<Grid item className={classes.column}>
					<Typography variant="h5" className={classes.dataTitle}>
						{title}
					</Typography>
				</Grid>
				<Grid item xs>
					<Typography id={id} variant="body2" className={classes.dataValue}>
						{value}
					</Typography>
				</Grid>
			</Grid>
		)
	}

	if (!order) {
		return null
	}

	return (
		<Container className={classes.subContainer} data-testid="refund-order-section">
			<Grid container direction="column" className={classes.dataContainer}>
				{renderDataRow(t('transaction:REFUND.POC_ID'), accountId, 'poc-id-value')}
				{renderDataRow(
					t('transaction:REFUND.ORDER_DATE'),
					formatDate(placementDate, t('transaction:REFUND.DATE_FORMAT')),
					'order-date-value',
				)}
				{renderDataRow(
					t('transaction:REFUND.STATUS'),
					t(`transaction:REFUND.ORDER_STATUS.${status}`),
					'order-status-value',
				)}
			</Grid>
		</Container>
	)
}

export default OrderSection
