import { ChallengeConverters } from '@/converters'
import { ButtonInteraction, ChallengeFiles, ScreenName } from '@/domains'
import { useAnalyticsServiceEventButtonClicked } from '@/hooks/analyticsService/useAnalyticsServiceEventButtonClicked'
import {
	createTranslationsArray,
	validateOptionalsTranslations,
} from '@/routes/challenge/componentsRedesign/ChallengeFormSetup/utils/translationsUtils'
import { OnSaveDialogFunction } from '@/routes/challenges/model/EditChallengeDialog'
import { ChallengeEvents, ChallengeSetupFormData, ChallengeStore } from '@/stores/challengeCreation'
import { getStatus } from '@/utils/challengeStatus'
import { useStore, useStoreMap } from 'effector-react'
import React from 'react'
import ChallengeFormSetupEditDialogView from './ChallengeFormSetupEditDialogView'

type Props = {
	onSaveDialog: OnSaveDialogFunction
}

const ChallengeFormSetupEditDialog: React.FC<Props> = ({ onSaveDialog }) => {
	const { optionalLanguagesUsed } = useStore(ChallengeStore)

	const { sendAnalyticsServiceEventButtonClickedEvent } = useAnalyticsServiceEventButtonClicked()
	const { challengeForEditing, challengeSetupFormData, isValidatingChallengeId, isValidatingOptionalFields } =
		useStoreMap({
			store: ChallengeStore,
			keys: [],
			fn: (state) => ({
				challengeForEditing: state.challengeForEditing,
				challengeSetupFormData: state.challengeSetupFormData,
				isValidatingChallengeId: state.validations.isValidatingChallengeId,
				isValidatingOptionalFields: state.validations.isValidatingOptionalFields,
			}),
		})

	const handleFormSubmit = (formData: ChallengeSetupFormData): void => {
		const translations = createTranslationsArray(formData, optionalLanguagesUsed)
		const isValidTranslations = validateOptionalsTranslations(translations)
		let updatedFormData = formData

		if (isValidTranslations) {
			updatedFormData = {
				...updatedFormData,
				translations,
			}
		}

		const { image, goodPhoto, badPhoto, squareImage } = formData
		const files: ChallengeFiles = {
			imageFile: image?.file,
			goodPhotoFile: goodPhoto?.file,
			badPhotoFile: badPhoto?.file,
			squareImageFile: squareImage?.file,
		}
		onSaveDialog(ChallengeConverters.challengeSetupFormDataToChallenge(updatedFormData, challengeForEditing!), files)
	}

	const handleCancelButtonClick = (buttonClicked: boolean): void => {
		const { title, executionMethod } = challengeSetupFormData!
		if (buttonClicked) {
			sendAnalyticsServiceEventButtonClickedEvent(
				`${ScreenName.EditChallenge} - ${title} - ${executionMethod}`,
				ButtonInteraction.Cancel,
				ButtonInteraction.Cancel,
			)
		}
		ChallengeEvents.closeEditChallengeDialog()
	}

	return (
		<ChallengeFormSetupEditDialogView
			challengeSetupFormData={challengeSetupFormData}
			onFormSubmit={handleFormSubmit}
			onCancelButtonClick={handleCancelButtonClick}
			challengeStatus={challengeForEditing ? getStatus(challengeForEditing) : undefined}
			isValidatingChallengeId={isValidatingChallengeId}
			isValidatingOptionalFields={isValidatingOptionalFields}
		/>
	)
}

export default ChallengeFormSetupEditDialog
