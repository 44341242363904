import { useStoreMap } from 'effector-react'
import React from 'react'
import { useTranslation } from 'react-i18next'

import { DataTableRef } from '@/components/tables/DataTable'
import { RecordType, Redeemable, ScreenName } from '@/domains'
import ButtonInteraction from '@/domains/analytics/ButtonInteraction'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { ComboEvents, ComboStore, GlobalEvents, PageLevelEvents } from '@/stores'
import { DeleteComboFromRedeemablesUseCase } from '@/usecase'

import { useAnalyticsServiceRecordDeleted } from '@/hooks/analyticsService/useAnalyticsServiceRecordDeleted'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import useAuditLogDTcombos from '@/hooks/useAuditLogInstance/BusinessLogicLayer/combosDT'
import RemoveRedeemableDialogView from './RemoveRedeemableDialogView'

type Props = {
	dataTableRef: React.RefObject<DataTableRef> | null
}

const RemoveRedeemableDialog: React.FC<Props> = ({ dataTableRef }: Props) => {
	const { t } = useTranslation()
	const userInfo = useGetUserInfo()
	const { sendAnalyticsServicerecordDeleted } = useAnalyticsServiceRecordDeleted()
	const { sendAuditLogDeleteDTCombos } = useAuditLogDTcombos()
	const { show, combosData, redeemables, rowIndexToDelete } = useStoreMap({
		store: ComboStore,
		keys: [],
		fn: (state) => ({
			show: state.showRemoveComboDialog,
			combosData: state.combosData,
			program: state.programData,
			redeemables: state.activeRedeemable,
			rowIndexToDelete: state.rowIndexToDelete,
		}),
	})

	if (!redeemables || !rowIndexToDelete) return <></>

	const singleDeletion = redeemables.length === 1
	const successMessage = t(`combo:REMOVE_REDEEMABLE_SUCCESS_MESSAGE.${singleDeletion ? 'SINGLE' : 'MULTIPLE'}` as const)

	const handleCancel = (buttonClicked: boolean) => {
		/* istanbul ignore else */
		if (buttonClicked) {
			AnalyticsService.events.buttonClicked({
				button_label: null,
				button_name: ButtonInteraction.Cancel,
				screen_name: ScreenName.RemoveDTComboModal,
				...userInfo,
			})
		}
		ComboEvents.setShowRemoveComboDialog(false)
		ComboEvents.setActiveCombo({ activeCombo: undefined })
	}

	const deleteCallback = (deletedCombo?: Redeemable[]) => {
		if (combosData && deletedCombo) {
			const redeemablesMapCopy = {
				...combosData.redeemablesMap,
			}
			deletedCombo.forEach(({ redeemableId }) => {
				delete redeemablesMapCopy[redeemableId]
			})
			ComboEvents.setCombos({
				...combosData,
				redeemablesMap: redeemablesMapCopy,
			})
		}
	}

	const handleConfirm = async () => {
		GlobalEvents.load()
		ComboEvents.setShowRemoveComboDialog(false)

		const redeemableIds = redeemables.map((r) => encodeURI(r.redeemableId))
		const vendorComboIds = redeemables.map((redeemable) => redeemable.redeemableId)
		const deleted = await DeleteComboFromRedeemablesUseCase.execute(redeemableIds)
		ComboEvents.setActiveRedeemable({ activeRedeemable: undefined })

		if (deleted) {
			dataTableRef?.current?.handleDelete(rowIndexToDelete, deleteCallback)
			PageLevelEvents.showNotification({
				route: '/membership/combos',
				notificationType: 'success',
				message: successMessage,
			})
			sendAuditLogDeleteDTCombos(vendorComboIds.toString())
			if (redeemables.length === 1) {
				const comboStatus = redeemables[0].status === 'PUBLISHED' ? 'Published' : 'Unpublished'
				sendAnalyticsServicerecordDeleted({
					recordId: redeemables[0].vendorComboId!,
					recordType: RecordType.DTCombo,
					screenName: `DT Combo Management - ${comboStatus} - Remove Modal`,
				})
			}
		} else {
			PageLevelEvents.showNotification({
				route: '/membership/combos',
				notificationType: 'error',
				message: t('ERROR_MESSAGE.GENERIC_ERROR'),
			})
		}

		GlobalEvents.loadDone()
	}

	return (
		<RemoveRedeemableDialogView
			show={show}
			onCancel={handleCancel}
			onConfirm={handleConfirm}
			singleDeletion={singleDeletion}
		/>
	)
}

export default RemoveRedeemableDialog
