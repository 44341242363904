import { styled } from '@hexa-ui/theme'
import { Download } from '@admin-portal-shared-components/icons'

const DownloadCSV = styled('button', {
	all: 'unset',
	display: 'flex',
	alignItems: 'center',
	height: '24px',
	color: '#0F7FFA',
	fontFamily: 'Work Sans',
	fontSize: '16px',
	fontWeight: 500,
	letterSpacing: 0,
	lineHeight: '24px',
	margin: '31px 0px 40px 0px',
	'& a': {
		textDecoration: 'none',
		color: '#0F7FFA',
	},
})
const DownloadIcon = styled(Download, {
	color: '#0F7FFA',
	marginRight: '10px',
})

export default { DownloadCSV, DownloadIcon }
