import { getMask, unformat } from '@/utils/currencyHelper'
import { onlyNumbers } from '@/utils/string'
import { Input, InputProps } from '@hexa-ui/components'
import { OutlinedInputProps } from '@material-ui/core'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { conformToMask } from 'react-text-mask'
import { useTranslation } from 'react-i18next'
import { useRedeemableItems } from '@/context/RedeemableItems/RedeemableItemsContext'

export type InputCurrencyProps = {
	id: string
	label: string
	optionalLabel?: string
	helperText?: string
	errorText?: string
} & OutlinedInputProps

const useMaskedValue = (initialValue: string) => {
	const [formattedValue, setFormattedValue] = useState(initialValue)

	const maskValue = useCallback((value: string) => {
		const mask = getMask()
		const { conformedValue } = conformToMask(value, mask)

		const isEmpty = !onlyNumbers(value).length
		const maskedValue = isEmpty ? '' : conformedValue.replace(/_/g, '')

		setFormattedValue(maskedValue)

		return isEmpty ? undefined : conformedValue.replace(/_/g, '')
	}, [])

	return { formattedValue, maskValue, setFormattedValue }
}
export const InputCurrency = React.forwardRef(
	({ onChange: onChangeFromProps, ...otherProps }: InputProps, forwardedRef: React.ForwardedRef<HTMLInputElement>) => {
		const { t } = useTranslation()
		const { formattedValue, maskValue } = useMaskedValue('')
		const hasError = useRef<boolean | undefined>(false)
		const errorText = useRef<string>('')
		const { isInvalidInput, setIsInvalidInput } = useRedeemableItems()

		useEffect(() => {
			hasError.current = isInvalidInput
		}, [isInvalidInput])

		const onInputChangedHandler = useCallback(
			(evt: React.ChangeEvent<HTMLInputElement>) => {
				const isControlled = onChangeFromProps && typeof onChangeFromProps === 'function'
				if (isControlled) {
					onChangeFromProps(evt)
				}

				const { value } = evt.target
				maskValue(`${value}`)

				// Validation logic
				if (value.replace(/[^0-9.,]/g, '') === '' || unformat(value) === 0) {
					setIsInvalidInput(true)

					const text = value.replace(/[^0-9.,]/g, '') === '' ? 'REQUIRED_VALUE' : 'ZERO_VALUE'
					errorText.current = text

					return
				}

				setIsInvalidInput(false)
			},
			[maskValue, onChangeFromProps],
		)

		useEffect(() => {
			if (otherProps.value !== undefined && !unformat(`${otherProps.value}`)) {
				return
			}
			maskValue(`${otherProps.value || ''}`)
		}, [maskValue, otherProps.value])

		return (
			<Input
				{...otherProps}
				ref={forwardedRef}
				value={formattedValue}
				hasError={hasError.current}
				onChange={onInputChangedHandler}
				errorText={t(`redeemable:UPDATE_CPP.${errorText.current}`)}
			/>
		)
	},
)
