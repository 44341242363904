import { downloadCSV } from '@/services/transactionV2/TransactionService'

export const execute = async (accountId: string, id: string, status: string): Promise<null | Blob> => {
	try {
		const response = await downloadCSV(accountId, id, status)
		return response
	} catch (error) {
		console.error('Download error', error)
		return null
	}
}
