import { useCallback, useState } from 'react'
import { UseModalProps } from './useModal.types'

export const useModal = (): UseModalProps => {
	const [isModalOpened, setIsModalOpened] = useState(false)

	const openModalHandler = useCallback(() => setIsModalOpened(true), [])
	const closeModalHandler = useCallback(() => setIsModalOpened(false), [])

	return {
		isModalOpened,
		openModalHandler,
		closeModalHandler,
	}
}
