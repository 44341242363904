import React, { useState, useEffect, useMemo } from 'react'
import { useStoreMap } from 'effector-react'
import { useTranslation } from 'react-i18next'
import { useFormContext } from 'react-hook-form'
import { Box, FormControlLabel, Grid, Radio, RadioGroup } from '@material-ui/core'
import { BreadCrumbLinkProps, Paper } from '@/components/core'
import Container from '@/components/templates/Container'
import { ComboFormType } from '@/domains/enums'
import { ComboEvents, ComboStore } from '@/stores'
import PageLevelTemplate from '@/components/templates/PageLevelTemplate'
import useIsCombosBulkUpdateEnabled from '@/hooks/combo/useIsCombosBulkUpdateEnabled'
import useIsCombosBulkSelectionEnabled from '@/hooks/combo/useIsCombosBulkSelectionEnabled'
import SingleCombo from '../SingleCombo/SingleCombo'
import useStyles from './ComboFormStyles'
import ComboSubmitDialog from '../ComboSubmitDialog/ComboSubmitDialog'
import MultipleCombo from '../MultipleCombo/MultipleCombo'
import ComboFormSubmitButton from './ComboFormSubmitButton'
import FileUploadControls from './FileUploadControls'

type ComboFormViewProps = {
	onClickBreadcrumbBack: () => void
}

const ComboFormView: React.FC<ComboFormViewProps> = ({ onClickBreadcrumbBack }) => {
	const { t } = useTranslation()
	const classes = useStyles()
	const [selectedFile, setSelectedFile] = useState<Array<JSX.Element> | null>(null)
	const isCombosBulkUpdateEnabled = useIsCombosBulkUpdateEnabled()
	const isCombosBulkSelectionEnabled = useIsCombosBulkSelectionEnabled()
	const isComboTypeSelectionEnabled = isCombosBulkUpdateEnabled && isCombosBulkSelectionEnabled

	const { formType, isValidatingComboId, fileValidation } = useStoreMap({
		store: ComboStore,
		keys: [],
		fn: (state) => ({
			formType: state.formType ?? undefined,
			isValidatingComboId: state.isValidatingComboId,
			fileValidation: state.fileValidation,
		}),
	})

	const {
		formState: { errors, isValidating },
		clearErrors,
	} = useFormContext()

	const disabledSubmitButton = useMemo(
		() => !formType || isValidating || isValidatingComboId || fileValidation?.isValidating,
		[formType, isValidating, isValidatingComboId, fileValidation?.isValidating],
	)

	const breadCrumbLinkOptions: BreadCrumbLinkProps = {
		displayName: t('combo:LIST.TITLE_VIEW'),
		onBack: onClickBreadcrumbBack,
	}

	const handleChangeSelectedItem = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { value } = e.currentTarget
		ComboEvents.setFormType(value as ComboFormType)
	}

	useEffect(() => {
		clearErrors()
		if (formType !== ComboFormType.Multiple) {
			ComboEvents.setFileValidationError(null)
			setSelectedFile(null)
			ComboEvents.setIsValidatingFile(false)
		}
	}, [clearErrors, formType])

	useEffect(() => {
		const { csvFile } = errors
		const errorMessage = fileValidation?.errorMessage ?? csvFile?.message
		if (errorMessage && fileValidation.errorMessage !== errorMessage) {
			ComboEvents.setFileValidationError(errorMessage)
		}
	}, [errors, fileValidation.errorMessage])

	useEffect(() => {
		if (
			isCombosBulkUpdateEnabled !== undefined &&
			isCombosBulkSelectionEnabled !== undefined &&
			!isComboTypeSelectionEnabled
		) {
			ComboEvents.setFormType(ComboFormType.OneByOne)
		}
	}, [isComboTypeSelectionEnabled, isCombosBulkUpdateEnabled, isCombosBulkSelectionEnabled])

	const customRadioClasses = {
		root: classes.radioButton,
	}

	return (
		<>
			<ComboSubmitDialog />
			<Container size={{ lg: 7, xl: 7 }} title={t('combo:TITLE')} breadCrumbLink={breadCrumbLinkOptions}>
				<PageLevelTemplate />
				<Paper className={classes.container}>
					{isComboTypeSelectionEnabled && (
						<>
							<label className={classes.selectOptionLabel} htmlFor="form-type-options">
								{t('combo:FORM.SELECT_OPTION_LABEL')}
							</label>
							<RadioGroup id="form-type-options" name="radio-group-items" onChange={handleChangeSelectedItem}>
								<Box flex="100%" flexDirection="row" flexWrap="wrap" display="flex" alignContent="center">
									<FormControlLabel
										control={<Radio classes={customRadioClasses} />}
										label={t('combo:FORM.MULTIPLE')}
										value={ComboFormType.Multiple}
									/>
									{formType === ComboFormType.Multiple && (
										<>
											<MultipleCombo onSelectedFile={setSelectedFile} />
											{(selectedFile || fileValidation.errorMessage) && (
												<FileUploadControls selectedFile={selectedFile} />
											)}
										</>
									)}
								</Box>
								<FormControlLabel
									control={<Radio classes={customRadioClasses} />}
									label={t('combo:FORM.ONE_BY_ONE')}
									value={ComboFormType.OneByOne}
								/>
								{formType === ComboFormType.OneByOne && <SingleCombo />}
							</RadioGroup>
						</>
					)}
					{!isComboTypeSelectionEnabled && formType === ComboFormType.OneByOne && <SingleCombo />}
				</Paper>
				{formType && (
					<Grid container justifyContent="flex-end">
						<ComboFormSubmitButton disabled={disabledSubmitButton} formType={formType} />
					</Grid>
				)}
			</Container>
		</>
	)
}
export default ComboFormView
