import { RefundItemFormik } from '@/routes/transaction/model/RefundItemFormik'
import { TransactionEvents, TransactionStore } from '@/stores'
import TransactionType from 'domains/enums/TransactionTypeEnum'
import { useStoreMap } from 'effector-react'
import { useField, useFormikContext } from 'formik'
import React, { ReactNode, useCallback, useEffect } from 'react'
import { useFeatureToggleV2 } from '@/hooks'
import * as FeatureToggle from '@/utils/featureToggle'
import useRemoveSuccessMessage from '../../hooks/useRemoveSuccessMessage'

export enum TransactionFormKey {
	combos = 'combos',
	redeemables = 'redeemables',
	referenceMonth = 'referenceMonth',
	referenceYear = 'referenceYear',
	category = 'category',
}

type Props = {
	children: ReactNode
}

const TransactionFields: React.FC<Props> = ({ children }: Props) => {
	const removeSuccessMessage = useRemoveSuccessMessage()
	const isToggleRefundRedeemableItemsEnabled = useFeatureToggleV2(FeatureToggle.CREATE_REFUND_REDEEMABLES)

	const { resetForm, dirty } = useFormikContext()
	const [combos] = useField<Array<RefundItemFormik>>(TransactionFormKey.combos)
	const [redeemables] = useField<Array<RefundItemFormik>>(TransactionFormKey.redeemables)

	const { resetFormStore, resetNewValues } = useStoreMap({
		store: TransactionStore,
		keys: [],
		fn: (state) => ({
			resetFormStore: state.resetForm,
			resetNewValues: state.resetNewValues,
			pageLevelNotificationType: state.pageLevelNotificationType,
		}),
	})
	const transactionType = useStoreMap({
		store: TransactionStore,
		keys: [],
		fn: (state) => state.transactionType,
	})

	const resetFormWithNewValues = useCallback(() => {
		resetForm({
			errors: {},
			values: {
				[TransactionFormKey.combos]: [],
				[TransactionFormKey.redeemables]: [],
				...resetNewValues,
			},
		})
	}, [resetForm, resetNewValues])

	const handleNotificationMessage = () => {
		if (dirty) {
			removeSuccessMessage()
		}
	}

	useEffect(() => {
		if (resetFormStore) {
			resetFormWithNewValues()
		}
	}, [resetFormStore, resetFormWithNewValues])

	// eslint-disable-next-line react-hooks/exhaustive-deps
	useEffect(handleNotificationMessage, [dirty])

	const value = isToggleRefundRedeemableItemsEnabled ? redeemables.value : combos.value

	useEffect(() => {
		if (transactionType !== TransactionType.Refund) {
			return
		}

		if (value) {
			TransactionEvents.setIsSubmitEnabled(value.some((redeemableItem) => redeemableItem.checked))
		}
	}, [transactionType, combos.value, redeemables.value, value])

	return <>{children}</>
}

export default TransactionFields
