import useAdminAuditLog, { IAuditOperations } from '@/hooks/useAdminAuditLog'
import {
	generateLogCreateRedeemables,
	generateLogSettingsRedeemables,
} from '@/hooks/useAuditLogInstance/BusinessLogicLayer/redeembles'
import { $pricePerPointConfigurationStore, GlobalStore } from '@/stores'
import { useStore } from 'effector-react'
import { useCallback } from 'react'

type auditLogProps = {
	pricePerPoint?: number
	SKU: string
	operation: IAuditOperations
}

type auditLogSettingsProp = {
	defaultPricePerPoint: number
	operation: IAuditOperations
}

type auditLogRedeemables = {
	sendAuditLogRedeemables: ({ pricePerPoint, SKU, operation }: auditLogProps) => void
	sendSettingsAuditLogRedeemables: ({ defaultPricePerPoint, operation }: auditLogSettingsProp) => void
}

const useAuditLogRedeemables = (): auditLogRedeemables => {
	const { auditLogInstance } = useAdminAuditLog()
	const { pricePerPoint: defaulPricePerPoint } = useStore($pricePerPointConfigurationStore)
	const { selectedVendorId } = useStore(GlobalStore)

	const sendAuditLogRedeemables = useCallback(
		({ pricePerPoint, SKU, operation }) => {
			const actualPricePerpoint = !pricePerPoint || pricePerPoint === 0 ? defaulPricePerPoint : pricePerPoint
			auditLogInstance(generateLogCreateRedeemables({ pricePerPoint: actualPricePerpoint ?? 0, SKU, operation }))
		},
		[auditLogInstance, defaulPricePerPoint],
	)

	const sendSettingsAuditLogRedeemables = useCallback(
		({ defaultPricePerPoint, operation }) => {
			auditLogInstance(generateLogSettingsRedeemables({ defaultPricePerPoint, operation, vendorId: selectedVendorId! }))
		},
		[auditLogInstance, selectedVendorId],
	)

	return { sendAuditLogRedeemables, sendSettingsAuditLogRedeemables }
}

export default useAuditLogRedeemables
