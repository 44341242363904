import { CurrentTransactionStatusEnum, RedeemableItem } from '@/domains/redeemable/RedeemableItem'
import { useFormatCurrency } from '@/hooks/useFormatNumber'
import { $pricePerPointConfigurationStore } from '@/stores/redeemableItems/PricePerPointConfigurationStore'
import Typography from '@material-ui/core/Typography'
import { useStore } from 'effector-react'
import React from 'react'
import { isCppOutOfLimits } from '@/usecase/redeemableItems/CriticalPricePerPointUseCaseV2'
import { useTranslation } from 'react-i18next'
import { styles } from './styles'
import { AlertsTooltipIcon } from '../AlertsTooltipIcon/AlertsTooltipIcon'

export const RedeemableItemPricePerPoint = ({ item }: { item: RedeemableItem }) => {
	const { t } = useTranslation()
	const css = styles()
	const { pricePerPoint, cppLimits, currentTransaction } = item
	const pppFormattedValue = useFormatCurrency(pricePerPoint, '')
	const { pricePerPoint: defaultPricePerPoint } = useStore($pricePerPointConfigurationStore)
	const defaultPPPFormatedValue = useFormatCurrency(defaultPricePerPoint)

	const minimumPrice = useFormatCurrency(cppLimits?.min)
	const maximumPrice = useFormatCurrency(cppLimits?.max)

	const currentTransactionPricePerPoint = useFormatCurrency(currentTransaction?.pricePerPoint)

	const shouldShowCustomPricePerPointContainer = pricePerPoint === defaultPricePerPoint || pricePerPoint === null

	const shouldShowWarning = !pricePerPoint || !cppLimits ? false : isCppOutOfLimits(pricePerPoint, cppLimits)
	const shouldShowPending = currentTransaction?.status === CurrentTransactionStatusEnum.PROCESSING
	const shouldShowRejected = currentTransaction?.status === CurrentTransactionStatusEnum.REJECTED

	return (
		<div data-testid="redeemable-item-price-per-point">
			{shouldShowCustomPricePerPointContainer ? (
				<div className={css.customPricePerPointContainer}>
					<Typography
						data-testid="default-price-per-point-value"
						variant="inherit"
						color="primary"
						className={css.defaultPricePerPoint}
					>
						{defaultPPPFormatedValue}
					</Typography>

					{shouldShowPending && (
						<AlertsTooltipIcon
							type="pending"
							text={t('redeemable:ITEMS_LIST_TABLE.PENDING_APPROVAL_TOOLTIP', {
								CPP: currentTransactionPricePerPoint,
							})}
						/>
					)}
					{shouldShowRejected && (
						<AlertsTooltipIcon type="rejected" rejectionReason={currentTransaction?.reason ?? ''} />
					)}
				</div>
			) : (
				<div className={css.container}>
					<Typography
						data-testid="default-price-per-point-disabled-value"
						variant="inherit"
						color="primary"
						className={css.disabledDefaultPricePerPoint}
					>
						{defaultPPPFormatedValue}
					</Typography>
					<div className={css.customPricePerPointContainer}>
						<Typography
							data-testid="custom-price-per-point-value"
							variant="h6"
							color="primary"
							className={css.customPricePerPoint}
						>
							{pppFormattedValue}
						</Typography>

						{shouldShowWarning && !shouldShowPending && !shouldShowRejected && (
							<AlertsTooltipIcon type="warning" minimumPrice={minimumPrice} maximumPrice={maximumPrice} />
						)}
						{shouldShowPending && (
							<AlertsTooltipIcon
								data-testid="pending-approval-tooltip"
								type="pending"
								text={t('redeemable:ITEMS_LIST_TABLE.PENDING_APPROVAL_TOOLTIP', {
									CPP: currentTransactionPricePerPoint,
								})}
							/>
						)}
						{shouldShowRejected && (
							<AlertsTooltipIcon type="rejected" rejectionReason={currentTransaction?.reason ?? ''} />
						)}
					</div>
				</div>
			)}
		</div>
	)
}
