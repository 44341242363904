import { AccountValidationResult, RewardsOfferType, TransactionType } from '@/domains/enums'
import { TransactionEvents, TransactionStore } from '@/stores'
import { useStoreMap } from 'effector-react'
import { FormikValues, useFormikContext } from 'formik'
import { ParseResult } from 'papaparse'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as VerifyEnrollmentUseCase from 'usecase/rewards/VerifyEnrollmentUseCase'
import { MAX_SIZE_FILE_5MB } from 'utils/constants'
import { fileValidationCsv } from 'utils/validationObjects'
import { ValidationError } from 'yup'
import { useFeatureToggleV2 } from '@/hooks'
import * as FeatureToggle from '@/utils/featureToggle'
import PocValidationParams from '../../model/PocValidationParams'
import RewardsOfferView from './RewardsOfferView'

type Props = {
	showError: boolean
	onError?: (id: string, message: string) => void
}

const RewardsOffer: React.FC<Props> = ({ showError, onError }) => {
	const { t } = useTranslation()

	const [processFileError, setProcessFileError] = useState<Partial<string>>()
	const [validationFileError, setValidationFileError] = useState<Partial<string>>()
	const [selectProductCategoryValue, setSelectProductCategoryValue] = useState([])
	const { values } = useFormikContext() as FormikValues

	const selectedRewardsOffer = useStoreMap({
		store: TransactionStore,
		keys: [],
		fn: (state) => state.rewardsOfferType,
	})

	const multiplePocsFileError = processFileError ?? (showError ? validationFileError : undefined)

	const rewardsOfferEnabled = useFeatureToggleV2(FeatureToggle.DEV_MULTIVENDOR_REWARDSOFFER_BULK)

	const validatePocId = async ({ validationResult, accountIdTranslated }: PocValidationParams): Promise<string> => {
		const enrolled = await VerifyEnrollmentUseCase.verifyPocEnroll(accountIdTranslated)
		if (!enrolled) {
			return t('ERROR_MESSAGE.INVALID_POC_ID')
		}
		if (AccountValidationResult.NO_PERMISSION === validationResult) {
			return t('ERROR_MESSAGE.NO_PERMISSION')
		}
		return ''
	}

	const fileValidationSchema = fileValidationCsv(
		{
			required: t('ERROR_MESSAGE.UNSUPPORTED_CSV_REWARDS_OFFERS_MULTIPLE'),
			fileSize: t('ERROR_MESSAGE.MAX_SIZE_5MB'),
			fileType: t('ERROR_MESSAGE.UNSUPPORTED_CSV_REWARDS_OFFERS_MULTIPLE'),
		},
		MAX_SIZE_FILE_5MB,
	)

	const validateFile = (files: File[]): string => {
		try {
			fileValidationSchema.validateSync(files)
			setValidationFileError('')
			return ''
		} catch (e) {
			if (e instanceof ValidationError) {
				setValidationFileError(e.errors[0])
				return e.errors[0]
			}
			throw e
		}
	}

	const handleRewardsOfferChange = React.useCallback(
		(event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
			TransactionEvents.setRewardsOfferType(event.target.value as RewardsOfferType)

			TransactionEvents.resetForm({
				transactionType: TransactionType.RewardsOffer,
				rewardsOfferType: event.target.value as RewardsOfferType,
				category: values.category,
				referenceMonth: values.referenceMonth,
				referenceYear: values.referenceYear,
				description: values.description,
				productCategory: values.productCategory,
			})
		},
		[values.category, values.description, values.productCategory, values.referenceMonth, values.referenceYear],
	)

	const multiplePocsFileParseTo = [
		{ from: 'poc_id', to: 'accountId' },
		{ from: 'campaign_id', to: 'campaignId' },
	]

	const handleMultiplePocsFileParse = React.useCallback(
		(results: ParseResult<unknown>) => {
			const { data, meta, errors } = results
			const defaultErrorMessage = t('ERROR_MESSAGE.UNSUPPORTED_CSV_REWARDS_OFFERS_MULTIPLE')
			const defaultError = { errors: [defaultErrorMessage] }

			/**
			 * 1 - Lines > 0 and <= 100000
			 */
			if (data.length === 0 || data.length > 100000) {
				setProcessFileError(defaultErrorMessage)
				return defaultError
			}

			/**
			 * 2 - Must have minimum two columns filled
			 */
			if (errors?.find((error) => error.code === 'TooFewFields')?.message.includes('parsed 1')) {
				setProcessFileError(defaultErrorMessage)
				return defaultError
			}

			/**
			 * 3 - Must have tree columns
			 */
			if (meta.fields?.length !== 2 && meta.fields?.length !== 3) {
				setProcessFileError(defaultErrorMessage)
				return defaultError
			}

			/**
			 * 4 - Columns must have POC_ID; POINTS;
			 */
			if (!(meta.fields?.includes('accountId') && meta.fields?.includes('points'))) {
				setProcessFileError(defaultErrorMessage)
				return defaultError
			}

			// Put values on effector here

			setProcessFileError(undefined)
			return { errors: undefined }
		},
		[t],
	)

	useEffect(() => {
		setSelectProductCategoryValue(values.productCategory)
	}, [values.productCategory])

	useEffect(() => {
		setProcessFileError(undefined)
		setValidationFileError(undefined)
	}, [selectedRewardsOffer])

	useEffect(() => {
		if (rewardsOfferEnabled) {
			TransactionEvents.setRewardsOfferType(RewardsOfferType.MultiplePoc)
		} else {
			TransactionEvents.setRewardsOfferType(RewardsOfferType.SinglePoc)
		}

		TransactionEvents.setIsSubmitVisible(true)
	}, [rewardsOfferEnabled])

	return (
		<RewardsOfferView
			selectProductCategoryValue={selectProductCategoryValue}
			showError={showError}
			rewardsTypeSelected={selectedRewardsOffer}
			multiplePocsFileParseTo={multiplePocsFileParseTo}
			multiplePocsFileError={multiplePocsFileError}
			onPocValidation={validatePocId}
			handleRewardsOfferChange={handleRewardsOfferChange}
			handleMultiplePocsFileParse={handleMultiplePocsFileParse}
			validateFile={validateFile}
			showMultiplePocsFields={!!rewardsOfferEnabled}
			onError={onError}
		/>
	)
}

export default RewardsOffer
