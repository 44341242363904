import React, { useEffect, useMemo } from 'react'

import { SKUProcess } from '@/domains/sku/SkuStep'
import Styled from '../../SkuModal.styles'
import { SkuModalComponent } from '../Modal/components'
import { RadioCards } from '../RadioCards/RadioCards'
import { SkuSearchTable } from '../SkuSearchTable/SkuSearchTable'
import SkuFileUploader from '../SkuFileUploader/SkuFileUploader'
import { SkuModalValueType, useSkuModalContext } from '../../context/SkuModalContext'

interface SkuModalProps {
	readonly isModalOpened: boolean
	readonly closeModal: () => void
	readonly onAddValues: (value: SkuModalValueType) => void
}

export const SkuModalConsumer = ({ isModalOpened, closeModal, onAddValues }: SkuModalProps) => {
	const { addType, setSkuModalValue, setAddType } = useSkuModalContext()

	useEffect(() => {
		return () => {
			if (!isModalOpened) {
				setSkuModalValue({ items: [], fileName: '' })
				setAddType({ continue: false })
			}
		}
	}, [isModalOpened])

	const onCloseModal = () => closeModal()

	const continueToSkuTable = useMemo(() => addType.type === SKUProcess.SINGLE_SKU && addType.continue, [addType])
	const continueToUploadCsv = useMemo(() => addType.type === SKUProcess.MULTIPLE_SKU && addType.continue, [addType])

	return (
		<SkuModalComponent.Root
			isOpened={isModalOpened}
			onCloseModal={onCloseModal}
			actions={
				<Styled.GridActions>
					<SkuModalComponent.Back onClose={onCloseModal} />
					<SkuModalComponent.Continue onConfirm={onAddValues} />
				</Styled.GridActions>
			}
		>
			<Styled.Content isSkuTable={continueToSkuTable}>
				{!addType.continue && <RadioCards />}
				{continueToSkuTable && <SkuSearchTable />}
				{continueToUploadCsv && <SkuFileUploader />}
			</Styled.Content>
		</SkuModalComponent.Root>
	)
}
