import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { IconProps, ParAddtolist } from '@hexa-ui/icons'
import { getRuleIdFromPathname } from '@/utils/getRuleIdFrompathName'
import { useFormContext } from 'react-hook-form'
import { useHandleGoBackActions } from '@/routes/rules/hooks/useHandleGoBackActions/useHandleGoBackActions'
import { UseHandlersExclusionActionsButtonsReturn } from './useHandlersExclusionActionsButtons.types'
import { CreateEarningRuleFormData } from '../../../CreateEarningRule/context/CreateEarningRuleProvider/CreateEarningRuleProvider.types'

const EXCLUSION_VARIANT_COLOR = 'secondary'

export const useHandlersExclusionActionsButtons = (): UseHandlersExclusionActionsButtonsReturn => {
	const hasRuleId = getRuleIdFromPathname()

	const { t } = useTranslation()
	const userInfo = useGetUserInfo()
	const { getValues } = useFormContext<CreateEarningRuleFormData>()
	const { goBackHandler } = useHandleGoBackActions({ status: getValues('status') })

	const exclusionFlowButtonLabelActionSaveAndExit = useMemo(
		() => t('rules:CREATE_EXCLUSION_RULES.EXCLUSION_SECTION.SAVE_AND_EXIT'),
		[t],
	)
	const exclusionFlowButtonLabelActionCancel = useMemo(() => t('rules:CREATE_EXCLUSION_RULES.GO_BACK'), [t])
	const exclusionFlowButtonLabelActionSubmit = useMemo(
		() =>
			hasRuleId
				? t('rules:CREATE_EXCLUSION_RULES.SAVE_EXCLUSION_RULE')
				: t('rules:CREATE_EXCLUSION_RULES.CREATE_NEW_EXCLUSION_RULE'),
		[t, hasRuleId],
	)

	const exclusionFlowOnActionCancel = useCallback(() => {
		AnalyticsService.events.buttonClicked({
			button_name: 'Cancel',
			button_label: 'Cancel New Exclusion Rule',
			screen_name: 'Earning Rules',
			...userInfo,
		})

		goBackHandler()
	}, [goBackHandler, userInfo])

	return {
		buttonLabelActionSaveAndExit: exclusionFlowButtonLabelActionSaveAndExit,
		buttonLabelActionCancel: exclusionFlowButtonLabelActionCancel,
		buttonLabelActionSubmit: exclusionFlowButtonLabelActionSubmit,
		onActionCancel: exclusionFlowOnActionCancel,
		buttonVariantActionSaveAndExit: EXCLUSION_VARIANT_COLOR,
		buttonVariantActionCancel: EXCLUSION_VARIANT_COLOR,
		isSubmitButtonDisabled: true,
		isSaveAndExitButtonDisabled: true,
		iconLeadingPosition: true,
		icon: ParAddtolist as unknown as React.FunctionComponent<IconProps>,
		showLeaveConfirmationModal: true,
	}
}
