import { CurrentTransactionStatusEnum, RedeemableItem } from '@/domains/redeemable/RedeemableItem'
import { IconButton, TextButton, Tooltip } from '@hexa-ui/components'
import { Edit2, RotateCw } from '@hexa-ui/icons'
import React, { useCallback, useState } from 'react'
import useScopeAuthorization from '@/hooks/useScopeAuthorization/useScopeAuthorization'
import { MANAGE_REDEEMABLE } from '@/utils/constants'
import { useTranslation } from 'react-i18next'
import { CancelRequestDialog } from '@/routes/redeemableItems/components/CancelRequestDialog/CancelRequestDialog'
import { usePricePerPointConfiguration } from '@/routes/redeemableItems/hooks/usePricePerPointConfiguration'
import RedeemableItemsListStyle from '../../../../RedeemableItemsListStyle'
import { useRedeemableItemActions } from '../../../../hooks/useRedeemableItemActions'
import DeleteRedeemableItems from '../../../DeleteRedeemableItens/DeleteRedeemableItems'

export const RedeemableItemActions = ({ item }: { item: RedeemableItem }) => {
	const { t } = useTranslation()
	const css = RedeemableItemsListStyle()
	const { buildRedeemableAction, shouldDisableActions: shouldDisabledPPP } = useRedeemableItemActions()
	const hasManageRedeemablePermission = useScopeAuthorization([MANAGE_REDEEMABLE])

	const actions = buildRedeemableAction(item)
	const disabled = shouldDisabledPPP(item)

	const [isOpened, setIsOpened] = useState(false)
	const isPending = item.currentTransaction?.status === CurrentTransactionStatusEnum.PROCESSING

	const { data } = usePricePerPointConfiguration()
	const defaultCpp = data?.pricePerPoint

	return (
		<div className={css.actions} data-testid="redeemable-item-actions">
			<CancelRequestDialog
				isOpened={isOpened}
				requestInfo={{
					id: item?.currentTransaction?.transactionId ?? '',
					pricePerPoint: item?.pricePerPoint ?? defaultCpp ?? 0,
					updatedPricePerPoint: item?.currentTransaction?.pricePerPoint ?? 0,
					type: 'SKU',
				}}
				onClose={() => setIsOpened(false)}
			/>
			{actions.onReload ? (
				<ReloadItemButton action={actions.onReload} disabled={disabled} tooltipText={t('BUTTON.RELOAD')} />
			) : (
				!hasManageRedeemablePermission && (
					<EditItemButton
						action={isPending ? () => setIsOpened(true) : actions.onEdit}
						disabled={disabled}
						tooltipText={t('BUTTON.EDIT')}
					/>
				)
			)}
			<Tooltip placement="bottom" text={t('BUTTON.REMOVE')}>
				<DeleteRedeemableItems item={item} />
			</Tooltip>
		</div>
	)
}

type ButtonProps = {
	action: () => void
	tooltipText: string
	disabled: boolean
}

const EditItemButton = ({ action, disabled, tooltipText }: ButtonProps) => {
	const icon = useCallback(() => <Edit2 size="medium" fr={undefined} />, [])

	return (
		<Tooltip placement="bottom" text={tooltipText}>
			<IconButton
				data-testid="edit-redeemable-item"
				disabled={disabled}
				variant="tertiary"
				size="small"
				icon={icon}
				onClick={action}
			/>
		</Tooltip>
	)
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const ReloadItemButton = ({ action, tooltipText, disabled }: ButtonProps) => {
	const css = RedeemableItemsListStyle()

	const icon = useCallback(() => <RotateCw size="medium" fr={undefined} />, [])

	return (
		<Tooltip placement="bottom" text={tooltipText}>
			<TextButton
				data-testid="reload-redeemable-item"
				className={css.reloadButton}
				iconPosition="leading"
				size="medium"
				icon={icon}
				onClick={action}
			/>
		</Tooltip>
	)
}
