import { useAuthenticationService } from 'admin-portal-shared-services'
import { useEffect, useState } from 'react'
import { useGetFeatureToggleInstance } from './useFeatureToggle'
import useCurrentZone from '../zone/useCurrentZone'

const createFeaturesObjectByToggleName = (
	toggles: string[],
	results: { status: string; value: boolean }[],
): { [key: string]: boolean } => {
	return toggles.reduce(
		(acc, toggle, index) => {
			acc[toggle] = results[index].value
			return acc
		},
		{} as { [key: string]: boolean },
	)
}

export const useGetMultiplesFeatureToggles = (featureTogglesList: string[]) => {
	const { isReady, featureToggleService } = useGetFeatureToggleInstance()
	const userId = useAuthenticationService().getUserId()
	const selectedZone = useCurrentZone()
	const [togglesState, setTogglesState] = useState<{
		togglesReady: boolean
		togglesValues: PromiseSettledResult<boolean | undefined>[]
		featuresListByToggleName: {
			[key: string]: boolean
		}
	}>({
		togglesReady: false,
		togglesValues: [],
		featuresListByToggleName: {},
	})

	useEffect(() => {
		if (isReady && !togglesState.togglesReady) {
			const promiseList = featureTogglesList.map((toggle) =>
				featureToggleService?.isFeatureEnabledAudiences(toggle, userId, { COUNTRY: selectedZone }),
			)

			Promise.allSettled(promiseList).then((response) => {
				const result = response as { status: string; value: boolean }[]

				const featuresListByToggleName = createFeaturesObjectByToggleName(featureTogglesList, result)

				setTogglesState({ togglesReady: true, togglesValues: response, featuresListByToggleName })
			})
		}
	}, [isReady, userId, selectedZone, featureToggleService, featureTogglesList, togglesState.togglesReady])

	return togglesState
}
