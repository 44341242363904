import { RouteInstance } from '@/domains/route/RouteInstance'

type RemoveRouteParams = {
	list: Array<RouteInstance>
	keyToKeep: string
	keyToRemove: string
	routes: Record<string, RouteInstance>
}

const keysToRemoveBasedOnOtherRoute = ({
	isToggleRedeemableItemsEnabled,
}: {
	isToggleRedeemableItemsEnabled: boolean
}) => {
	const getRedeemableItemsOrCombosDTRoute = isToggleRedeemableItemsEnabled
		? {
				keyToKeep: 'REDEEMABLE_ITEMS_ROUTE',
				keyToRemove: 'COMBO_ROUTE',
			}
		: {
				keyToKeep: 'COMBO_ROUTE',
				keyToRemove: 'REDEEMABLE_ITEMS_ROUTE',
			}

	return [
		{
			keyToKeep: 'SELECT_TIER_ROUTE',
			keyToRemove: 'SELECT_PROGRAM_ROUTE',
		},
		{
			keyToKeep: 'ZONE_CONFIGURATION_ROUTE',
			keyToRemove: 'PROGRAM_CONFIGURATION_ROUTE',
		},
		getRedeemableItemsOrCombosDTRoute,
	]
}

const removeRouteIfOtherIsAvailable = ({
	list,
	keyToKeep,
	keyToRemove,
	routes,
}: RemoveRouteParams): Array<RouteInstance> => {
	if (routes[keyToKeep] && routes[keyToRemove]) {
		const baseRoute = list.find((route) => route.id === routes[keyToKeep].id)
		if (baseRoute) {
			return list.filter((route) => route.id !== routes[keyToRemove].id)
		}
	}
	return list
}

export const removeRoutesIfNecessary = ({
	list,
	isToggleRedeemableItemsEnabled,
	routes,
}: {
	list: Array<RouteInstance>
	isToggleRedeemableItemsEnabled: boolean
	routes: Record<string, RouteInstance>
}): Array<RouteInstance> => {
	let copy = [...list]
	keysToRemoveBasedOnOtherRoute({ isToggleRedeemableItemsEnabled }).forEach((item) => {
		copy = removeRouteIfOtherIsAvailable({
			list: copy,
			keyToKeep: item.keyToKeep,
			keyToRemove: item.keyToRemove,
			routes,
		})
	})
	return copy
}
