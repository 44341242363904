import { EnuStatusRule } from '@/services/rules/RulesService.types'
import { useLocation } from 'react-router-dom'
import { useCallback } from 'react'
import { useHasPermissionRules } from '../useHasPermissionRules/useHasPermissionRules'
import useEditMode from '../useEditMode/useEditMode'
import { useNavigateToRules } from '../useNavigateToRules'

export const useHandleGoBackActions = ({
	status,
}: {
	status: EnuStatusRule
}): {
	goBackHandler: () => void
} => {
	const location = useLocation()
	const { setViewAction } = useEditMode()
	const { hasCreateExclusionListPermission, hasCreateEarningRulesPermission } = useHasPermissionRules()

	const isEarningRule = location.pathname.includes('earning-rule')
	const isExclusion = location.pathname.includes('exclusion-rule')
	const permission = isEarningRule ? hasCreateExclusionListPermission : hasCreateEarningRulesPermission

	const { goToRulesList, goToCreateRule } = useNavigateToRules()

	const goBackHandler = useCallback(() => {
		const searchParams = new URLSearchParams(location.search)
		const action = searchParams.get('action')

		const actionHandlers: { [key: string]: () => void } = {
			edit: status === EnuStatusRule.draft ? goToRulesList : setViewAction,
			default: (isEarningRule && permission) || (isExclusion && permission) ? goToCreateRule : goToRulesList,
		}

		const handleAction = actionHandlers[action as string] || actionHandlers.default

		handleAction()
	}, [location.search, status, goToRulesList, setViewAction, goToCreateRule, isEarningRule, isExclusion, permission])

	return {
		goBackHandler,
	}
}
