import { RefundOrderItem } from '@/domains'
import { TransactionType } from '@/domains/enums'
import useTransactionSegmentationEvents from '@/hooks/TransactionSegmentationEvents/useTransactionSegmentationEvents'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import useAdminAuditLog from '@/hooks/useAdminAuditLog'
import {
	generateLogPointsRemoval,
	generateLogPointsTransfer,
	generateLogRefund,
	generateLogRewardsOffer,
	generateLogServiceUtility,
} from '@/hooks/useAuditLogInstance'
import { auditLogRewardsOfferData } from '@/hooks/useAuditLogInstance/BusinessLogicLayer/utils/auditLogRewardsOfferData'
import useTransactionFormTranslateAccountIds, {
	FormValuesMap,
} from '@/routes/transaction/hooks/useTransactionFormTranslateAccountIds'
import { TransactionEvents, TransactionStore } from '@/stores'
import { TransactionUseCase } from '@/usecase'
import { formatDate } from '@/utils/date'
import { useStoreMap } from 'effector-react'
import { FormikHelpers, FormikValues } from 'formik'
import _ from 'lodash'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSkus } from '@/context/SkusContext/SkusContext'
import * as FeatureToggle from '@/utils/featureToggle'
import { useFeatureToggleV2 } from '@/hooks'
import { useGetTransactionInfo } from './useGetTransactionInfo'

interface IHandleSubmitForm {
	handleSubmitForm: (values: FormikValues, { setSubmitting, resetForm }: FormikHelpers<FormikValues>) => void
	newTransactionId: string | undefined
}

export const useHandleSubmitForm = (): IHandleSubmitForm => {
	const { t } = useTranslation()
	const userInfo = useGetUserInfo()
	const [newTransactionId, setNewTransactionId] = useState<string | undefined>()
	const { handleSegmentationEvents } = useTransactionSegmentationEvents()
	const isDimensionEnabled = useFeatureToggleV2(FeatureToggle.IDENTIFY_DIMENSION)
	const isToggleRefundRedeemableItemsEnabled = useFeatureToggleV2(FeatureToggle.CREATE_REFUND_REDEEMABLES)
	const isDevMultivendorCombosEnabled = useFeatureToggleV2(FeatureToggle.DEV_MULTI_VENDOR_COMBOS)
	const isNewTransactionLogsToggleEnabled = useFeatureToggleV2(FeatureToggle.NEW_TRANSACTION_LOGS)
	const isPointsRemovalAndCategorization = useFeatureToggleV2(FeatureToggle.BULK_POINTS_REMOVAL)

	const { originAccountBalance, rewardsOfferType, transactionType } = useStoreMap({
		store: TransactionStore,
		keys: [],
		fn: (state) => ({
			selectedOrder: state.selectedOrder,
			transactionType: state.transactionType,
			originAccountBalance: state.originAccountBalance,
			rewardsOfferType: state.rewardsOfferType,
		}),
	})
	const {
		order: selectedOrder,
		accountId,
		placementDate,
		status,
	} = useGetTransactionInfo({ isToggleRefundRedeemableItemsEnabled: isToggleRefundRedeemableItemsEnabled! })

	const { auditLogInstance } = useAdminAuditLog()
	const { setSelectedSkus } = useSkus()
	const translateAccountIds = useTransactionFormTranslateAccountIds()

	const getFormValues = (values: FormikValues): FormValuesMap => {
		const orderDate = selectedOrder ? formatDate(placementDate, t('FORMAT_DATE_SHORT_SEGMENT')) : ''
		const orderStatus = status
		const points = transactionType === TransactionType.PointsTransferFrom ? originAccountBalance : values.points
		const vendorAccountId = transactionType === TransactionType.RewardsOffer ? values.pocId : ''

		const payloadData = { ...values }
		if (isToggleRefundRedeemableItemsEnabled && payloadData.redeemables) {
			payloadData.redeemables = payloadData.redeemables.map((redeemableItem: RefundOrderItem) => {
				return {
					..._.omit(redeemableItem, 'refundableQuantity'),
					quantity: redeemableItem.refundableQuantity,
				}
			})
		}

		const formValues: Record<string, string | undefined> = {
			...payloadData,
			accountId,
			orderDate,
			orderStatus,
			points,
			vendorAccountId,
		}
		return translateAccountIds(formValues)
	}

	const handleSubmitForm = (values: FormikValues, { setSubmitting, resetForm }: FormikHelpers<FormikValues>): void => {
		const formValues = getFormValues(values) as FormikValues
		TransactionUseCase.execute({
			transactionType: transactionType as TransactionType,
			rewardsOfferType,
			values: formValues,
			analyticsProps: userInfo,
			isIdentifyDimensionEnabled: isDimensionEnabled!,
			isToggleRefundRedeemableItemsEnabled: isToggleRefundRedeemableItemsEnabled!,
			isDevMultivendorCombosEnabled: isDevMultivendorCombosEnabled!,
			isNewTransactionLogsToggleEnabled: isNewTransactionLogsToggleEnabled!,
			isPointsRemovalAndCategorization: isPointsRemovalAndCategorization!,
		})
			.then(async (result) => {
				// Store Rewards Offer Multiple transaction id
				setNewTransactionId(typeof result === 'string' ? result : undefined)
				handleSegmentationEvents(formValues)
				if (transactionType === TransactionType.PointsTransferFrom) {
					auditLogInstance(generateLogPointsTransfer(formValues))
					resetForm()
					TransactionEvents.setOriginAccountBalance('—')
				} else if ((transactionType as TransactionType) === TransactionType.PointsRemoval) {
					TransactionEvents.setPointsRemoval({
						currentPointsBalance: '—',
						newPointsBalance: '—',
					})
					auditLogInstance(generateLogPointsRemoval(formValues))
					resetForm()
				} else if ((transactionType as TransactionType) === TransactionType.RewardsOffer) {
					const { rewardsOfferMetadataObj, pocs } = auditLogRewardsOfferData(
						formValues,
						typeof result === 'string' ? result : undefined,
					)
					await generateLogRewardsOffer(rewardsOfferMetadataObj, pocs, auditLogInstance)
					resetForm()
					setSelectedSkus([])
				} else if ((transactionType as TransactionType) === TransactionType.ServiceUtility) {
					TransactionEvents.setRemovalForServiceUtility({
						currentPointsBalance: '—',
						newPointsBalance: '—',
					})
					auditLogInstance(generateLogServiceUtility(formValues))
					resetForm()
				} else if ((transactionType as TransactionType) === TransactionType.Refund) {
					auditLogInstance(
						generateLogRefund({
							formValues,
							isToggleRefundRedeemableItemsEnabled: isToggleRefundRedeemableItemsEnabled!,
						}),
					)
				}
			})
			.catch(() => {
				// do nothing!
			})
			.finally(() => {
				window.scrollTo({ behavior: 'smooth', top: 0 })
				setSubmitting(false)
			})
	}

	return { handleSubmitForm, newTransactionId }
}
