import { setPortalConfig } from '@/services/host/HostService'
import { useAuthenticationService as authenticationService } from 'admin-portal-shared-services'
import { useEffect, useState } from 'react'

type useSetupTestEnvProps = {
	isLoading: boolean
}

const shouldStartServerMock = (): boolean =>
	window.location.hostname.includes('localhost') && !process.env.DISABLE_RW_ADMIN_SERVER_MOCK

const defineTestEnv = async (): Promise<void> => {
	const startServer = await import('../../../server')
	const token = startServer.default()
	setPortalConfig()

	authenticationService().setAuthHeader(`Bearer ${token}`)
}

const useSetupTestEnv = (): useSetupTestEnvProps => {
	const [isLoading, setIsLoading] = useState(shouldStartServerMock())

	useEffect(() => {
		if (shouldStartServerMock()) {
			defineTestEnv().then(() => {
				setIsLoading(false)
			})
		}
	}, [])

	return {
		isLoading,
	}
}

export { useSetupTestEnv }
