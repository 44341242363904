import { getTransaction } from '@/services/transactionV2/TransactionService'
import { TransactionResponseV2 } from '@/domains/transaction/TransactionResponse'
import { useLogService as logService } from 'admin-portal-shared-services'
import { getDefaultVendorIdOfCurrentZone } from '@/utils/getDefaultVendorId'

const log = logService()

export const execute = async (transactionId: string): Promise<TransactionResponseV2> => {
	try {
		const vendorId = getDefaultVendorIdOfCurrentZone()

		const result = await getTransaction(vendorId, transactionId)

		return result
	} catch (error) {
		log.error(`Error load: transactions`, error)
		throw error
	}
}
