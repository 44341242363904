import { useCallback, useState } from 'react'
import { useLoadSelectedVendor } from '@/hooks/vendor/useLoadSelectedVendor'
import { GlobalEvents } from '@/stores'

interface IMainContentResponse {
	hasError: boolean
	init: () => Promise<void>
}

const useMainContent = (): IMainContentResponse => {
	const [hasError, setHasError] = useState(false)
	const { defineSelectedVendorAndSettings } = useLoadSelectedVendor()

	const init = useCallback(async () => {
		try {
			GlobalEvents.setPageReady(false)
			setHasError(false)
			await defineSelectedVendorAndSettings()
		} catch (error) {
			setHasError(true)
		} finally {
			GlobalEvents.setPageReady(true)
		}
	}, [defineSelectedVendorAndSettings])

	return { hasError, init }
}

export default useMainContent
