import { useQuery } from 'react-query'
import useCurrentSettings from '@/hooks/settings/useCurrentSettings'
import { SettingsLevel, SettingsType } from '@/domains/enums/settings'

import { useCurrentTier } from '@/hooks/tier/useCurrentTier'
import { loadSettings } from '../SettingsService'

interface LoadAllSettingsResult {
	settings?: {
		toggles: {
			findItemBySku: boolean
		}
	}[]
}

export const useGetLoadAllSettings = (): LoadAllSettingsResult => {
	const settings = useCurrentSettings()
	const { selectedTierKey } = useCurrentTier()

	const { settingsId } = settings
	const { data } = useQuery(['load-settings'], () =>
		loadSettings<{ toggles: { findItemBySku: boolean } }>({
			levels: SettingsLevel.VENDOR,
			settingIds: settingsId,
			tiers: selectedTierKey ? [selectedTierKey] : ['CLUB_B'],
			types: SettingsType.TOGGLES,
		}),
	)

	return {
		settings: data,
	}
}
