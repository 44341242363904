import { fieldCurrencyValidationNotRequired, fieldCurrencyValidationRequired } from '@/utils/validationObjects'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormProvider, useForm } from 'react-hook-form'
import React from 'react'
import * as Yup from 'yup'

const generateValidationSchema = (formFields: (string | undefined)[]) => {
	const shape: Record<string, Yup.StringSchema<string | undefined, object>> = {}
	formFields
		.filter((field): field is string => field !== undefined)
		.forEach((field) => {
			shape[field] = fieldCurrencyValidationRequired()
		})
	return Yup.object().shape(shape)
}

const generateValidationSchemaNotRequired = (formFields: (string | undefined)[]) => {
	const shape: Record<string, Yup.StringSchema<string | undefined, object>> = {}
	formFields
		.filter((field): field is string => field !== undefined)
		.forEach((field) => {
			shape[field] = fieldCurrencyValidationNotRequired()
		})
	return Yup.object().shape(shape)
}

const AddRedeemableItemFormProvider: React.FC<{ formFields: (string | undefined)[]; notRequired: boolean }> = ({
	children,
	formFields,
	notRequired,
}) => {
	const validationSchema = notRequired
		? generateValidationSchemaNotRequired(formFields)
		: generateValidationSchema(formFields)
	const formMethods = useForm({
		resolver: yupResolver(validationSchema),
		mode: 'all',
		shouldFocusError: true,
	})

	return (
		<FormProvider {...formMethods}>
			<form>{children}</form>
		</FormProvider>
	)
}

export default AddRedeemableItemFormProvider
