import SkuForm from '@/domains/sku/SkuForm'
import { addItemInRule, AddItemInRuleParams } from '@/services/settings/SettingsService'

export type UpdateSettingRuleUseCaseProps = {
	selectedTierKey: string
	ruleId: string
	categoryId: string
	skuFormValues: SkuForm
	settingsId: string
	vendorId: string
	level: string
}

export async function execute(paramsRules: UpdateSettingRuleUseCaseProps): Promise<boolean> {
	const { id, itemId, sku } = paramsRules.skuFormValues
	const params: AddItemInRuleParams = {
		level: paramsRules.level,
		ruleId: paramsRules.ruleId,
		settingId: paramsRules.settingsId,
		tier: paramsRules.selectedTierKey,
		categoryId: paramsRules.categoryId,
		itemId,
		item: {
			vendorId: paramsRules.vendorId,
			vendorItemId: id,
			sku,
		},
	}
	try {
		await addItemInRule(params)
		return true
	} catch {
		return false
	}
}
