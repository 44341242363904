import React from 'react'
import * as Yup from 'yup'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useGetExclusionRuleById } from '@/hooks/useGetExclusionRuleById/useGetExclusionRuleById'
import { useNavigateToRules } from '@/routes/rules/hooks/useNavigateToRules'
import { useLocation } from 'react-router-dom'
import { getRuleIdFromPathname } from '@/utils/getRuleIdFrompathName'
import { EnuStatusRule, ExclusionRule } from '../../../../../../../services/rules/RulesService.types'
import { CreateExclusionRuleFormData } from './CreateExclusionListProvider.types'

const CreateExclusionRuleProvider: React.FC = ({ children }) => {
	const { data, isLoading } = useGetExclusionRuleById()
	const { goToRulesList } = useNavigateToRules()
	const location = useLocation()
	const ruleId = getRuleIdFromPathname()
	const isExclusionRulePath = location.pathname.includes('/membership/rules/exclusion-rule')

	if (isLoading) {
		return null
	}

	if (
		!data &&
		isExclusionRulePath &&
		ruleId === undefined &&
		location.pathname !== '/membership/rules/exclusion-rule'
	) {
		goToRulesList()
	}

	return <CreateExclusionRuleConsumer data={data}>{children}</CreateExclusionRuleConsumer>
}
export default CreateExclusionRuleProvider

const CreateExclusionRuleConsumer = ({ data, children }: { data?: ExclusionRule; children: React.ReactNode }) => {
	const formSchema = useForm<CreateExclusionRuleFormData>({
		mode: 'onChange',
		defaultValues: {
			description: data?.description,
			audienceId: data?.audience,
			name: data?.name,
			audienceName: data?.audienceName,
			status: data?.status ?? EnuStatusRule.draft,
			estimatedPocCount: data?.estimatedPocCount,
			ruleId: data?.ruleId,
		},
		resolver: yupResolver(
			Yup.object().shape({
				description: Yup.string().required(),
			}),
		),
	})

	return <FormProvider {...formSchema}>{children}</FormProvider>
}
