import * as TransactionServiceV2 from '@/services/transactionV2/TransactionService'
import { getDefaultVendorIdOfCurrentZone } from '@/utils/getDefaultVendorId'

type AccountItem = {
	poc_id: string
	points: string
	campaignId: string
}

const REMOVAL_REASON = {
	SUSPICIOUS_ACTIVITY: 'Suspicious activity',
	POINTS_ADDED_INCORRECTLY: 'Points added incorrectly',
	SYSTEM_ERROR_CORRECTION: 'System error correction',
	REFUND_ERROR_CORRECTION: 'Refund error correction',
	EXPIRED_POINTS: 'Expired points',
	OTHER: 'Other',
}

async function execute(values: Record<string, unknown>): Promise<void> {
	const { points, pocId, accounts, description, removeReason, file } = values
	const vendorId = getDefaultVendorIdOfCurrentZone()

	const formattedMultipleAccounts = (accounts as AccountItem[])?.map((item) => ({
		vendorAccountId: item.poc_id,
		points: item.points,
		campaignId: '',
	}))

	const formattedAccounts = [{ vendorAccountId: pocId as string, points: points as string, campaignId: '' }]

	const newAccounts = formattedMultipleAccounts?.length > 1 ? formattedMultipleAccounts : formattedAccounts

	const upperCaseReason = (removeReason as string)?.toUpperCase()
	const formattedRemoveReason = REMOVAL_REASON[upperCaseReason as keyof typeof REMOVAL_REASON]

	const pointsRemovalData = {
		vendorId,
		accounts: newAccounts.length > 1 ? [] : formattedAccounts,
		quantity: newAccounts.length,
		description: description as string,
		category: formattedRemoveReason,
	}

	const pointsRemoval = TransactionServiceV2.pointsRemoval(pointsRemovalData, file as File)

	return pointsRemoval
}

export { execute }
