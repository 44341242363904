import { AcceptableTypes } from '@/components/form'

import { Upload, AlertOctagon } from '@hexa-ui/icons'
import React from 'react'
import { FieldValues, UseFormRegister } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { DownloaderFile } from '@/components/core'
import { Alert } from '@hexa-ui/components'
import Styled from '../StepMultipleSkuComponent/styles'
import { useTriggerSegmentsCsvSku } from '../hooks/useTriggerSegmentsCsvSku'

export const UploadSkuCsvFile = ({
	errors,
	fileName,
	hookFormRegister,
	inputName,
	handleSubmitFile,
	errorMessage,
}: {
	errors: boolean
	errorMessage?: string
	fileName: string
	hookFormRegister?: UseFormRegister<FieldValues>
	inputName: string
	handleSubmitFile: (event: React.ChangeEvent<HTMLInputElement>) => Promise<void> | void
}) => {
	const { t } = useTranslation()
	const { handleDownloadCSVClick } = useTriggerSegmentsCsvSku()

	return (
		<>
			<Alert
				css={{ width: '100%', border: '1px solid #D5E9FE' }}
				message={t('rules:MODAL_ADD_SKU.CSV_UPLOAD_INSTRUCTION')}
			/>
			<DownloaderFile
				onClick={handleDownloadCSVClick}
				url="https://cdn-b2b-abi.global.ssl.fastly.net/admin/SKUs_to_Earning_Rule_Example.csv"
				label={t('common:BUTTON.DOWNLOAD_CSV_TEMPLATE')}
			/>

			{errors && (
				<Styled.ErrorMessage data-testid="error-span">
					<AlertOctagon size="tiny" style={{ color: '#C9201D', marginRight: '4px' }} />
					{errorMessage}
				</Styled.ErrorMessage>
			)}
			<Styled.UploadForm>
				<Styled.InputFileName disabled placeholder={t('common:BUTTON.UPLOAD_CSV_FILE')} defaultValue={fileName ?? ''} />
				<label htmlFor="file">
					<Styled.UploadIcon>
						<Upload size="medium" />
					</Styled.UploadIcon>
					{t('common:BUTTON.UPLOAD')}
				</label>
				<input
					data-testid="file-upload-input"
					id="file"
					type="file"
					accept={AcceptableTypes.CSV}
					{...(hookFormRegister ? hookFormRegister(inputName) : {})}
					onChange={handleSubmitFile}
				/>
			</Styled.UploadForm>
		</>
	)
}
