import { styled } from '@hexa-ui/theme'

const Content = styled('div', {
	width: 900,
	paddingTop: 8,
	paddingBottom: 8,

	display: 'flex',
	flexDirection: 'column',

	'> div': {
		display: 'flex',
		flexDirection: 'column',
		flex: 1,
	},

	variants: {
		isSkuTable: {
			true: {
				height: 370,
			},
		},
	},
})

const GridActions = styled('div', {
	display: 'flex',
	justifyContent: 'flex-end',
	gap: 16,
})

const Header = styled('div', {
	display: 'flex',
	justifyContent: 'space-between',
})

const CloseBtn = styled('div', {
	position: 'relative',
	left: 'var(--space-8)',
})

export default {
	GridActions,
	Content,
	Header,
	CloseBtn,
}
