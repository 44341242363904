import { ChallengeExecutionMethod, EditDialog } from '@/domains/enums'
import { Challenge, Group } from '@/domains'
import ItemChallenge from '@/domains/challenge/ItemChallenge'
import { ImageFile, OptionalTranslate } from '@/domains/Challenge'

export type ChallengePageLevelNotificationType = 'none' | 'success' | 'error' | 'previousStep'

export interface SkuBoxErrors {
	isDuplicate: boolean
	isNotExist: boolean
}
export interface SkuBox {
	sku: string
	quantity?: number
	itemId?: string
	vendorId?: string
	vendorItemId?: string
	errorsInput?: SkuBoxErrors
}

export interface Account {
	accountId: string | number
	points: string | number
	quantityMin?: string | number
	currencyMin?: string | number
	challengeId?: string | number
}

export interface IndividualAccountGroup {
	[groupId: string]: {
		accounts: Array<{
			accountId: string
			points: string
			quantityMin?: string
			currencyMin?: string
		}>
	}
}

export interface CurrentStep {
	stepIndex: number
}

export interface ChallengeSetupFormData {
	title?: string
	executionMethod: ChallengeExecutionMethod
	id?: string
	campaignId?: string
	description?: string
	image?: ImageFile | null
	squareImage?: ImageFile | null
	goodPhoto?: ImageFile | null
	badPhoto?: ImageFile | null
	groupName?: string
	file?: File
	translations?: Array<OptionalTranslate>
	visionId?: string
	purchaseType: string
}

export interface ChallengeFilterFormData {
	startDate?: string
	endDate?: string
	filters: Array<{ filter: string; value: string[] }>
	individualTarget?: boolean
	hasFilter?: boolean
}

export interface ChallengeDetailsFormData {
	points: number
	quantity?: number
	challengeGoal?: string
	purchaseType?: string
	singlePurchase?: boolean
	currencyMin?: number
	skus?: SkuBox[]
	items?: ItemChallenge[]
}

export interface ChallengePageLevelNotification {
	type: ChallengePageLevelNotificationType
	message?: string
}

interface ChallengeValidations {
	isValidatingChallengeId?: boolean
	isValidatingSku?: boolean
	isValidatingOptionalFields?: boolean
}

interface ChallengeState {
	currentStep: CurrentStep
	isDuplicating: boolean
	showSubmitDialog: boolean
	successfullySubmitForm: boolean
	showAccountGroupModal: boolean
	challengeSetupFormData?: ChallengeSetupFormData
	challengeFilterFormData?: ChallengeFilterFormData
	challengeDetailsFormData?: ChallengeDetailsFormData
	pageLevelNotification: ChallengePageLevelNotification
	newlyCreatedGroupId: string | null
	validations: ChallengeValidations
	activeEditDialog: EditDialog
	challengeForEditing: Challenge | null
	individualAccountGroup?: IndividualAccountGroup
	groupOptionsMap: Record<string, Group>
	optionalLanguagesUsed?: Array<string>
}

export interface DuplicateChallengePayload {
	challengeSetupFormData: ChallengeSetupFormData
	challengeFilterFormData: ChallengeFilterFormData
	challengeDetailsFormData: ChallengeDetailsFormData
}

export interface EditChallengePayload {
	activeEditDialog: EditDialog
	challengeForEditing: Challenge
}

export const emptyFilterFormData = { filter: '', value: [] }

export const challengeInitialState: ChallengeState = {
	currentStep: { stepIndex: 0 },
	isDuplicating: false,
	showSubmitDialog: false,
	successfullySubmitForm: false,
	pageLevelNotification: {
		type: 'none',
		message: '',
	},
	showAccountGroupModal: false,
	challengeFilterFormData: {
		filters: [
			{
				...emptyFilterFormData,
			},
		],
	},
	newlyCreatedGroupId: null,
	validations: {
		isValidatingChallengeId: false,
		isValidatingSku: false,
		isValidatingOptionalFields: false,
	},
	activeEditDialog: EditDialog.None,
	challengeForEditing: null,
	individualAccountGroup: {},
	groupOptionsMap: {},
}

export default ChallengeState
