import React, { createContext, useContext, useState, ReactNode, useMemo, useRef } from 'react'

type RedeemableItemsContextType = {
	inputValuePPP: number | undefined
	setInputValuePPP: React.Dispatch<React.SetStateAction<number | undefined>>
	valuePPP: React.MutableRefObject<number | undefined>
	isInputEmpty: boolean | undefined
	setIsInputEmpty: React.Dispatch<React.SetStateAction<boolean | undefined>>
	isInvalidInput: boolean | undefined
	setIsInvalidInput: React.Dispatch<React.SetStateAction<boolean | undefined>>
	isDisabledButton: boolean | undefined
	setIsDisabledButton: React.Dispatch<React.SetStateAction<boolean | undefined>>
}

const RedeemableItemsContext = createContext<RedeemableItemsContextType | undefined>(undefined)

export const RedeemableItemsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const valuePPP = useRef<number | undefined>()
	const [inputValuePPP, setInputValuePPP] = useState<number | undefined>()
	const [isInputEmpty, setIsInputEmpty] = useState<boolean | undefined>()
	const [isInvalidInput, setIsInvalidInput] = useState<boolean | undefined>(true)
	const [isDisabledButton, setIsDisabledButton] = useState<boolean | undefined>(true)

	const value = useMemo(
		() => ({
			valuePPP,
			inputValuePPP,
			setInputValuePPP,
			isInputEmpty,
			setIsInputEmpty,
			isInvalidInput,
			setIsInvalidInput,
			isDisabledButton,
			setIsDisabledButton,
		}),
		[inputValuePPP, isInputEmpty, isInvalidInput, isDisabledButton],
	)

	return <RedeemableItemsContext.Provider value={value}>{children}</RedeemableItemsContext.Provider>
}

export const useRedeemableItems = (): RedeemableItemsContextType => {
	const context = useContext(RedeemableItemsContext)
	if (!context) {
		throw new Error('useRedeemableItems must be used within a RedeemableItemsProvider')
	}
	return context
}
