import { PointsTransferTransactionResponse as PointsTransferResponse, TransactionResponse } from '@/domains'
import { RewardsOfferType, TransactionType } from '@/domains/enums'
import { UserDataResult } from '@/hooks/getUserInfo/useGetUserInfo'
import { RefundItemFormik } from '@/routes/transaction/model/RefundItemFormik'
import * as RewardsOfferMultipleUseCase from '@/usecase/transaction/RewardsOfferMultipleUseCase'
import transactionFormValuesToRefund from 'converters/TransactionFormValuesToRefund'
import transactionFormValuesToRewardsOffer, {
	transactionFormValuesToRewardsOfferV2,
} from 'converters/TransactionFormValuesToRewardsOffer'
import transactionFormValuesToRewardsOfferMultiple, {
	transactionFormValuesToRewardsOfferMultipleV2,
} from 'converters/transaction/TransactionFormValuesToRewardsOfferMultiple'
import FailureReason from 'domains/analytics/FailureReason'
import ScreenName from 'domains/analytics/ScreenName'
import AnalyticsService from 'services/analytics/AnalyticsService'
import * as TransactionEvents from 'stores/transaction/TransactionEvents'
import * as GlobalUseCase from 'usecase/global/GlobalUseCase'
import * as LoadOrderWithComboUseCase from 'usecase/order/LoadOrderWithComboUseCase'
import * as PointsTransferUseCase from 'usecase/transaction/PointsTransferUseCase'
import * as RefundTransactionUseCase from 'usecase/transaction/RefundTransactionUseCase'
import * as RewardsOfferTransactionUseCase from 'usecase/transaction/RewardsOfferTransactionUseCase'
import { LoadRefundOrderUseCase } from '../order'
import * as PointsRemovalUseCase from './PointsRemovalUseCase'
import * as BulkPointsRemovalUseCase from './BulkPointsRemovalUseCase'
import * as PointsRemovalUseCaseV2 from '../transactionV2/PointsRemovalUseCase'
import * as RemovalForServiceUtilityUseCase from './RemovalForServiceUtilityUseCase'

async function executeUseCase({
	transactionType,
	values,
	analyticsProps,
	rewardsOfferType = null,
	isIdentifyDimensionEnabled,
	isToggleRefundRedeemableItemsEnabled,
	isNewTransactionLogsToggleEnabled,
	isPointsRemovalAndCategorization,
}: {
	transactionType: TransactionType
	rewardsOfferType: RewardsOfferType | null
	values: Record<string, unknown>
	analyticsProps: UserDataResult
	isIdentifyDimensionEnabled: boolean
	isToggleRefundRedeemableItemsEnabled: boolean
	isNewTransactionLogsToggleEnabled: boolean
	isPointsRemovalAndCategorization: boolean
}): Promise<TransactionResponse | PointsTransferResponse[] | string | void> {
	if (transactionType === TransactionType.Refund) {
		return RefundTransactionUseCase.execute({
			accountId: values.accountId as string,
			isToggleRefundRedeemableItemsEnabled,
			refundTransaction: transactionFormValuesToRefund({ values, isToggleRefundRedeemableItemsEnabled }),
		}).catch((reason) => {
			AnalyticsService.events.error({
				screen_name: ScreenName.RefundCreatePointsTransaction,
				failure_reason: FailureReason.TransactionSubmitError,
				form_name: null,
				...analyticsProps,
			})
			throw reason
		})
	}
	if (transactionType === TransactionType.RewardsOffer && rewardsOfferType === RewardsOfferType.SinglePoc) {
		const rewardsOfferTransaction = isIdentifyDimensionEnabled
			? transactionFormValuesToRewardsOfferV2({
					singlePOC: true,
					...values,
				})
			: transactionFormValuesToRewardsOffer(values)

		return RewardsOfferTransactionUseCase.execute({
			accountId: values.pocId as string,
			isIdentifyDimensionEnabled,
			rewardsOfferTransaction,
			isNewTransactionLogsToggleEnabled,
		})
	}
	if (transactionType === TransactionType.RewardsOffer && rewardsOfferType === RewardsOfferType.MultiplePoc) {
		const multiplePocsFile = values.multiplePocsFile as Array<File>
		const rewardsOfferMultipleTransaction = isIdentifyDimensionEnabled
			? transactionFormValuesToRewardsOfferMultipleV2({
					singlePOC: false,
					...values,
				})
			: transactionFormValuesToRewardsOfferMultiple(values)

		return RewardsOfferMultipleUseCase.execute({
			multiplePocsFile: multiplePocsFile[0],
			rewardsOfferMultipleTransaction,
			isIdentifyDimensionEnabled,
			isNewTransactionLogsToggleEnabled,
		})
	}
	if (transactionType === TransactionType.PointsTransferFrom) {
		return PointsTransferUseCase.execute(values.pocIdFrom as string, values.pocIdTo as string, values.reason as string)
	}
	if (transactionType === TransactionType.PointsRemoval) {
		return executePointsRemoval(values, isNewTransactionLogsToggleEnabled, isPointsRemovalAndCategorization)
	}
	if (transactionType === TransactionType.ServiceUtility) {
		return RemovalForServiceUtilityUseCase.execute(
			values.pocId as string,
			parseInt(values.points as string, 10),
			values.description as string,
			values.category as string,
		)
	}
	// There is no use case related to this transactionType
	return Promise.reject(new Error('Transaction does not exist'))
}

const executePointsRemoval = (
	values: Record<string, unknown>,
	isNewTransactionLogsToggleEnabled: boolean,
	isPointsRemovalAndCategorization: boolean,
): Promise<TransactionResponse | void> => {
	if (isNewTransactionLogsToggleEnabled && isPointsRemovalAndCategorization) {
		return PointsRemovalUseCaseV2.execute(values)
	}

	if (isPointsRemovalAndCategorization) {
		return BulkPointsRemovalUseCase.execute(values)
	}

	return PointsRemovalUseCase.execute(
		values.pocId as string,
		parseInt(values.points as string, 10),
		values.description as string,
	)
}

async function reLoadOrder({
	orderId,
	isToggleRefundRedeemableItemsEnabled,
	isDevMultivendorCombosEnabled,
}: {
	orderId: string
	isToggleRefundRedeemableItemsEnabled: boolean
	isDevMultivendorCombosEnabled: boolean
}): Promise<string> {
	try {
		let order
		if (isToggleRefundRedeemableItemsEnabled) {
			order = await LoadRefundOrderUseCase.execute(orderId)
		} else {
			order = await LoadOrderWithComboUseCase.execute({ orderId, isDevMultivendorCombosEnabled })
		}

		TransactionEvents.selectOrder(order)
	} catch (err) {
		TransactionEvents.selectOrder(null)
	}

	return Promise.resolve('')
}

async function execute({
	transactionType,
	rewardsOfferType,
	values,
	analyticsProps,
	isIdentifyDimensionEnabled,
	isToggleRefundRedeemableItemsEnabled,
	isDevMultivendorCombosEnabled,
	isNewTransactionLogsToggleEnabled,
	isPointsRemovalAndCategorization,
}: {
	transactionType: TransactionType
	rewardsOfferType: RewardsOfferType | null
	values: Record<string, unknown>
	analyticsProps: UserDataResult
	isIdentifyDimensionEnabled: boolean
	isToggleRefundRedeemableItemsEnabled: boolean
	isDevMultivendorCombosEnabled: boolean
	isNewTransactionLogsToggleEnabled: boolean
	isPointsRemovalAndCategorization: boolean
}): Promise<TransactionResponse | PointsTransferResponse[] | string | void> {
	GlobalUseCase.load()

	return executeUseCase({
		transactionType,
		rewardsOfferType,
		values,
		analyticsProps,
		isIdentifyDimensionEnabled,
		isToggleRefundRedeemableItemsEnabled,
		isNewTransactionLogsToggleEnabled,
		isPointsRemovalAndCategorization,
	})
		.then(async (result) => {
			if (transactionType === TransactionType.Refund) {
				const trackValues = values as unknown as {
					accountId: string
					orderId: string
					combos: Array<RefundItemFormik>
					orderDate: string
					orderStatus: string
				}

				await reLoadOrder({
					orderId: trackValues.orderId,
					isToggleRefundRedeemableItemsEnabled,
					isDevMultivendorCombosEnabled,
				})
			}
			TransactionEvents.showNotificationForTransaction('success')
			return result
		})
		.catch((reason) => {
			TransactionEvents.showNotificationForTransaction('error')
			throw reason
		})
		.finally(() => {
			if (transactionType === TransactionType.RewardsOffer) {
				TransactionEvents.resetForm({
					transactionType,
					rewardsOfferType,
				})
			}
			GlobalUseCase.loadDone()
			TransactionEvents.setShowErrorForTransaction(false)
		})
}

export { execute }
