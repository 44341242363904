import React from 'react'
import { SettingsCardExclusion } from '@/routes/rules/RulesRedesign/Components/SettingsCard/SettingsCardExclusion'
import { SettingsCardViewExclusion } from '@/routes/rules/RulesRedesign/Components/SettingsCard/SettingsCardViewExclusion'
import useEditMode from '@/routes/rules/hooks/useEditMode/useEditMode'

export const SettingsExclusion = () => {
	const { isEditMode } = useEditMode()

	return isEditMode ? <SettingsCardExclusion /> : <SettingsCardViewExclusion />
}
