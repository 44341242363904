import { useFormatCurrency } from '@/hooks/useFormatNumber'
import { usePricePerPointConfiguration } from '@/routes/redeemableItems/hooks/usePricePerPointConfiguration'
import { PageLevelEvents } from '@/stores'
import * as currencyHelper from '@/utils/currencyHelper'
import { useIsTogglePricePerPointSettingsEnabled } from '@/utils/featureToggle/featureToggleHelper'
import { Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { GridContainer, GridItem } from '@/components/templates/GridTemplate'
import { useZoneConfiguration } from '../../hooks/useZoneConfiguration'
import EditButton from '../EditButton'
import Section from '../Section'
import TableLoading from '../TableLoading'
import useStyles from './styles'

enum ViewState {
	LOADING = 'LOADING',
	ERROR = 'ERROR',
	DONE = 'DONE',
}

const Table: React.FC = () => {
	const { isLoading, hasError, settingsData, loadData } = useZoneConfiguration()
	const pppSettings = usePricePerPointConfiguration()
	const isPricePerPointOnSettingsEnabled = useIsTogglePricePerPointSettingsEnabled()
	const userInfo = useGetUserInfo()
	const { t } = useTranslation()
	const classes = useStyles()
	const [viewState, setViewState] = useState<ViewState>(ViewState.LOADING)

	useEffect(() => {
		if (isLoading || pppSettings.isLoading) {
			setViewState(ViewState.LOADING)
			return
		}

		if (hasError || pppSettings.hasError) {
			setViewState(ViewState.ERROR)
			return
		}

		setViewState(ViewState.DONE)
	}, [isLoading, hasError, pppSettings.isLoading, pppSettings.hasError])

	useEffect(() => {
		if (!isPricePerPointOnSettingsEnabled) return
		pppSettings.loadData()
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [pppSettings.loadData, isPricePerPointOnSettingsEnabled])

	useEffect(() => {
		AnalyticsService.events.pageViewed({
			screen_name: 'Tier Configuration',
			...userInfo,
		})
	}, [userInfo])

	if (viewState === 'ERROR') {
		PageLevelEvents.showNotification({
			notificationType: 'error',
			message: t('settings:NOTIFICATION.EDIT.ERROR'),
		})
	}

	const pricePerPoint = useFormatCurrency(
		pppSettings.data?.pricePerPoint,
		t('redeemable:SETTINGS.PPP_MISSING_DEFAULT_VALUE'),
	)

	return (
		<Section>
			{viewState === ViewState.LOADING && <TableLoading />}
			{viewState === ViewState.DONE && (
				<>
					<GridContainer>
						<GridItem
							xs={3}
							style={{
								justifyContent: 'space-between',
								paddingBottom: '16px',
							}}
						>
							<Typography className={classes.title}>{t('settings:LABEL_NAME.INITIAL_BALANCE')}</Typography>
						</GridItem>
						<GridItem xs={9} className={classes.inline}>
							<Typography className={classes.content}>{settingsData?.initialBalance}</Typography>
							<EditButton
								onAfterSubmit={() => {
									loadData()
									pppSettings.loadData()
								}}
							/>
						</GridItem>
					</GridContainer>
					<GridContainer>
						<GridItem xs={3}>
							<Typography className={classes.title}>{t('settings:LABEL_NAME.REDEEM_LIMIT')}</Typography>
						</GridItem>
						<GridItem xs={9}>
							<Typography className={classes.content}>{settingsData?.redeemLimit}</Typography>
						</GridItem>
					</GridContainer>
					{!!isPricePerPointOnSettingsEnabled && (
						<GridContainer>
							<GridItem xs={3}>
								<Typography data-testid="settings-ppp-field" className={classes.title}>
									{t('settings:LABEL_NAME.PRICE_PER_POINT', {
										currencySymbol: currencyHelper.getSymbol(),
									})}
								</Typography>
							</GridItem>
							<GridItem xs={9}>
								<Typography data-testid="settings-ppp-value" className={classes.content}>
									{pricePerPoint}
								</Typography>
							</GridItem>
						</GridContainer>
					)}
				</>
			)}
		</Section>
	)
}

export default Table
