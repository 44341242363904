import * as SegmentationService from '@/services/segmentation/SegmentationService'
import Group from '@/domains/Group'

async function execute({ isToggleLoadGroupName }: { isToggleLoadGroupName: boolean }): Promise<Array<Group>> {
	if (isToggleLoadGroupName) {
		return SegmentationService.loadAll().catch(() => {
			return []
		})
	}
	return []
}

export { execute }
