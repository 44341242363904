import { ONLY_NUMBER_REGEX } from '@/utils/constants'

export const handleInputTypeNumber = (val: string) => {
	const isNumber = ONLY_NUMBER_REGEX.test(val)
	const value = isNumber ? parseInt(val, 10) : undefined

	return {
		isNumber,
		value,
	}
}
