import { SettingsLevel, SettingsType } from '@/domains/enums/settings'
import { SettingDefaultConfiguration, SettingsLoadDefaultConfig } from '@/domains/settings'
import { LoadDefaultConfigParams, loadSettings } from '@/services/settings/SettingsService'
import { getDefaultVendorIdOfCurrentZone } from '@/utils/getDefaultVendorId'
import { getUserCountry } from '@/utils/tokenB2CHelper'

const getParams = (selectedTierKey: string, level: SettingsLevel): LoadDefaultConfigParams => {
	const baseParams = {
		types: SettingsType.DEFAULT_CONFIGURATION,
		tiers: [selectedTierKey],
		levels: level,
	}
	if (level === SettingsLevel.ZONE) {
		return {
			...baseParams,
			settingIds: getUserCountry(),
		}
	}
	return {
		...baseParams,
		settingIds: getDefaultVendorIdOfCurrentZone(),
	}
}

const loadSettingsByLevel = async (
	selectedTierKey: string,
	level: SettingsLevel,
): Promise<SettingDefaultConfiguration> => {
	const response = await loadSettings<SettingsLoadDefaultConfig>(getParams(selectedTierKey, level))
	const hasContentConfiguration = response?.[0]
	if (level === SettingsLevel.ZONE && !hasContentConfiguration) {
		throw new Error(`No data found! ${response}`)
	}
	const result = response[0]
	return result
}

export async function execute({
	selectedTierKey,
	isToggleSettingsConfigByZoneEnabled,
}: {
	selectedTierKey: string
	isToggleSettingsConfigByZoneEnabled: boolean
}): Promise<SettingDefaultConfiguration> {
	const isSettingsConfigByZone = isToggleSettingsConfigByZoneEnabled
	try {
		const settingsByZone = await loadSettingsByLevel(selectedTierKey, SettingsLevel.ZONE)
		if (isSettingsConfigByZone) {
			return settingsByZone
		}

		const settingByVendor = await loadSettingsByLevel(selectedTierKey, SettingsLevel.VENDOR)
		return {
			...settingsByZone,
			...settingByVendor,
			initialBalance: settingsByZone.initialBalance,
		}
	} catch {
		throw new Error('No data found!')
	}
}
