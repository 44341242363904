import React from 'react'
import { Button, Modal } from '@hexa-ui/components'
import { ParAddtolist } from '@hexa-ui/icons'
import { useTranslation } from 'react-i18next'
import { SkuModalValueType, useSkuModalContext } from '../../../../context/SkuModalContext'

interface SkuModalContinueProps {
	readonly onConfirm: (value: SkuModalValueType) => void
}

export function SkuModalContinue({ onConfirm }: SkuModalContinueProps) {
	const { addType, setAddType, skuModalValue } = useSkuModalContext()

	const handleConfirmMode = () => setAddType({ ...addType, continue: true })

	const { t } = useTranslation()
	return (
		<Modal.Action>
			{!addType.continue ? (
				<Button
					data-testid="btn-continue"
					size="medium"
					variant="primary"
					disabled={!addType.type}
					onClick={handleConfirmMode}
				>
					{t('rules:CREATE_EARNING_RULES.CONTINUE')}
				</Button>
			) : (
				<Button
					data-testid="btn-add"
					icon={() => <ParAddtolist size="large" />}
					leading
					size="medium"
					variant="primary"
					disabled={!skuModalValue.items.length}
					onClick={() => onConfirm(skuModalValue)}
				>
					{t('rules:CREATE_EARNING_RULES.ADD')}
				</Button>
			)}
		</Modal.Action>
	)
}
