export enum StatusEnum {
	DRAFT = 'DRAFT',
	ACTIVE = 'ACTIVE',
}

export type StatusColorStyleIndex =
	| 'semanticErrorText'
	| 'semanticSuccessText'
	| 'semanticInfoText'
	| 'semanticWarningBackground'

export interface StatusBadgeColumnProps {
	status: StatusEnum
}
