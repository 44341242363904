import React from 'react'
import { Bullet, Container, Text } from './settingsCardStatus.styles'
import { useTranslation } from 'react-i18next'

interface StatusIndicatorProps {
	status: 'active' | 'inactive' | 'pending' | 'disabled'
}

const StatusIndicator = ({ status }: StatusIndicatorProps) => {
	const upperStatus = status.toUpperCase()
	const { t } = useTranslation()
	return (
		<Container state={status}>
			<Bullet state={status} fr={undefined} size="tiny" />
			<Text state={status}>{t(`rules:EARNING_RULES_TABLE.${upperStatus}`)}</Text>
		</Container>
	)
}

export default StatusIndicator
