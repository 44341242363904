import React from 'react'
import useEditMode from '@/routes/rules/hooks/useEditMode/useEditMode'
import { AudienceExclusionViewData } from './AudienceExclusionViewData/AudienceExclusionViewData'
import { AudienceExclusionEditData } from './AudienceExclusionEditData/AudienceExclusionEditData'

const AudienceExclusion = () => {
	const { isEditMode } = useEditMode()

	return isEditMode ? <AudienceExclusionEditData /> : <AudienceExclusionViewData />
}

export default AudienceExclusion
