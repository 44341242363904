import { LIMIT_CHANGE_STORAGE_KEY } from '@/utils/constants'
import { getStorageItemString, setStorageItem } from '@/utils/storageUtil'
import { createEvent, createStore } from 'effector'

export interface RedeemableItemLimitState {
	limit: number | undefined
	errorOnUpdateLimit: boolean
	isOpened?: boolean
	isLimitExceeded: boolean
	totalRedeemableItems: number
	exceededLimitAmount?: number
	limitChange: {
		limit: number | undefined
		isLimitUpdate: boolean
	}
}

export interface RedeemableLimitChangeState {
	id: string
	limit: number | undefined
	isLimitUpdate: boolean
}

export const initialState: RedeemableItemLimitState = {
	limit: undefined,
	errorOnUpdateLimit: false,
	isOpened: false,
	isLimitExceeded: false,
	totalRedeemableItems: 0,
	exceededLimitAmount: undefined,
	limitChange: {
		limit: undefined,
		isLimitUpdate: false,
	},
}

export const $redeemableItemLimitStore = createStore<RedeemableItemLimitState>(initialState)

export const RedeemableItemLimitStoreEvents = {
	updateLimit: createEvent<{ limit: number; id: string }>(),
	updateLimitSuccess: createEvent(),
	updateLimitError: createEvent(),
	updateTotalRedeemableItems: createEvent<number>(),
	resetLimit: createEvent(),
	onOpen: createEvent('onOpen'),
	onClose: createEvent('onClose'),
}

const calculateLimitStatus = (
	totalRedeemableItems: number,
	limit: number | undefined,
): { exceededLimitAmount: number; isLimitExceeded: boolean } => {
	const exceededLimitAmount = Math.max(0, totalRedeemableItems - (limit ?? 0))
	const isLimitExceeded = totalRedeemableItems > (limit ?? 0)
	return { exceededLimitAmount, isLimitExceeded }
}

const updateLimit = (
	state: RedeemableItemLimitState,
	{ limit, id }: { limit: number; id: string },
): RedeemableItemLimitState => {
	const limitChange = updateLimitChange(limit, id)

	return {
		...state,
		limit,
		limitChange,
		errorOnUpdateLimit: false,
		...calculateLimitStatus(state.totalRedeemableItems, limit),
	}
}

const updateLimitSuccess = (state: RedeemableItemLimitState): RedeemableItemLimitState => ({
	...state,
	errorOnUpdateLimit: false,
})

const updateLimitError = (state: RedeemableItemLimitState): RedeemableItemLimitState => ({
	...state,
	errorOnUpdateLimit: true,
})

const updateTotalRedeemableItems = (
	state: RedeemableItemLimitState,
	totalRedeemableItems: number,
): RedeemableItemLimitState => ({
	...state,
	totalRedeemableItems,
	...calculateLimitStatus(totalRedeemableItems, state.limit),
})

const onOpen = (state: RedeemableItemLimitState): RedeemableItemLimitState => ({ ...state, isOpened: true })
const onClose = (state: RedeemableItemLimitState): RedeemableItemLimitState => ({ ...state, isOpened: false })

const updateLimitChange = (limit: number | undefined, id: string): RedeemableLimitChangeState => {
	const storedLimitString = getStorageItemString(LIMIT_CHANGE_STORAGE_KEY)
	const storedLimit = storedLimitString ? JSON.parse(storedLimitString) : []
	const storedLimitArray = Array.isArray(storedLimit) ? storedLimit : []

	const currentLimit = limit
	const currentId = id

	const existingLimit = storedLimitArray.find((item: RedeemableLimitChangeState) => item.id === id)?.limit

	const limitChange = {
		id: currentId,
		limit: Number(currentLimit),
		isLimitUpdate: existingLimit !== limit,
	}

	const updatedLimits =
		currentLimit === 0 || currentLimit === null ? [...storedLimitArray] : [...storedLimitArray, limitChange]

	const uniqueLimits = [...updatedLimits]
		.reverse()
		.filter((item, index, self) => index === self.findIndex((t) => t.id === item.id))
		.reverse()

	setStorageItem(LIMIT_CHANGE_STORAGE_KEY, JSON.stringify(uniqueLimits))

	return limitChange
}

$redeemableItemLimitStore
	.on(RedeemableItemLimitStoreEvents.updateLimit, updateLimit)
	.on(RedeemableItemLimitStoreEvents.updateLimitSuccess, updateLimitSuccess)
	.on(RedeemableItemLimitStoreEvents.updateLimitError, updateLimitError)
	.on(RedeemableItemLimitStoreEvents.updateTotalRedeemableItems, updateTotalRedeemableItems)
	.on(RedeemableItemLimitStoreEvents.onOpen, onOpen)
	.on(RedeemableItemLimitStoreEvents.onClose, onClose)
	.reset(RedeemableItemLimitStoreEvents.resetLimit)
