import { Paragraph } from '@hexa-ui/components'
import { AlertOctagon } from '@hexa-ui/icons'
import React, { useRef } from 'react'
import HexaUploadButton from '@/components/hexa-form/HexaUploadButton/HexaUploadButton'
import { AcceptableTypes } from '@/components/formik/FileUpload'
import { Container } from './styles'

export type fieldType = 'image' | 'goodPhoto' | 'badPhoto' | 'file' | 'squareImage'

interface IUpload {
	label: string
	uploadText: string
	name: string
	description?: string
	onChange: (event: React.ChangeEvent<HTMLInputElement>, field: string) => void
	onRemove: (field: string, files: File) => void
	onClick?: () => void
	error?: string
	acceptTypes: AcceptableTypes
}

export const RedesignUploadButton: React.ForwardRefRenderFunction<React.Ref<HTMLInputElement>, IUpload> = ({
	label,
	uploadText,
	name,
	description,
	onChange,
	onRemove,
	onClick,
	error,
	acceptTypes,
}) => {
	const hiddenFileInput = useRef<HTMLInputElement>(null)
	const [files, setFiles] = React.useState<File | null>(null)

	const handleClick = () => {
		if (hiddenFileInput.current) {
			hiddenFileInput.current.click()
		}
		if (onClick) onClick()
	}

	const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const fileUploaded = event.target.files
		if (!fileUploaded?.length) return

		setFiles(fileUploaded[0])
		onChange(event, name)
	}

	const handleClearFile = () => {
		if (files && hiddenFileInput.current) {
			onRemove(name, files)
			setFiles(null)
			hiddenFileInput.current.value = ''
		}
	}

	return (
		<Container style={{ marginTop: '0px' }}>
			<Paragraph weight="semibold" size="small">
				{label}
			</Paragraph>
			<Paragraph colortype="secondary" size="xsmall">
				{description}
			</Paragraph>
			{error && (
				<Paragraph colortype="error" size="xsmall">
					<AlertOctagon size="tiny" style={{ marginRight: '5.5', alignSelf: 'end' }} />
					{error}
				</Paragraph>
			)}
			<HexaUploadButton
				uploadText={uploadText}
				acceptTypes={acceptTypes}
				name={name}
				handleClick={handleClick}
				hiddenFileInput={hiddenFileInput}
				handleChange={handleChange}
				handleRemove={handleClearFile}
				files={files}
			/>
		</Container>
	)
}
