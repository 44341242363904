import { createStore } from 'effector'
import { ChallengeExecutionMethod, EditDialog } from '@/domains/enums'
import { Group } from '@/domains'
import * as ChallengeEvents from './ChallengeEvents'
import ChallengeState, {
	CurrentStep,
	challengeInitialState,
	ChallengeSetupFormData,
	ChallengeDetailsFormData,
	ChallengeFilterFormData,
	ChallengePageLevelNotification,
	DuplicateChallengePayload,
	EditChallengePayload,
	IndividualAccountGroup,
} from './ChallengeState'

const onSetCurrentStep = (state: ChallengeState, currentStep: CurrentStep): ChallengeState => ({
	...state,
	currentStep,
})

const onShowSubmitDialog = (state: ChallengeState, showSubmitDialog: boolean): ChallengeState => ({
	...state,
	showSubmitDialog,
})

const onSetChallengeSetupFormData = (
	state: ChallengeState,
	challengeSetupFormData: ChallengeSetupFormData,
): ChallengeState => {
	return {
		...state,
		challengeSetupFormData,
	}
}

const onSetChallengeDetailsFormData = (
	state: ChallengeState,
	challengeDetailsFormData: ChallengeDetailsFormData,
): ChallengeState => ({
	...state,
	challengeDetailsFormData,
})

const onShowPageLevelNotification = (
	state: ChallengeState,
	notification: ChallengePageLevelNotification,
): ChallengeState => ({
	...state,
	pageLevelNotification: { ...notification },
})

const onHidePageLevelNotification = (state: ChallengeState): ChallengeState => ({
	...state,
	pageLevelNotification: { type: 'none', message: '' },
})

const onSetChallengeFilterFormData = (
	state: ChallengeState,
	challengeFilterFormData: ChallengeFilterFormData,
): ChallengeState => ({
	...state,
	challengeFilterFormData,
})

const onSetShowAccountGroupModal = (state: ChallengeState, showAccountGroupModal: boolean): ChallengeState => ({
	...state,
	showAccountGroupModal,
})

const onSetNewlyCreatedGroup = (state: ChallengeState, group: Group | null): ChallengeState => {
	if (!group) {
		return {
			...state,
			newlyCreatedGroupId: null,
		}
	}
	return {
		...state,
		newlyCreatedGroupId: group.id,
		groupOptionsMap: {
			...state.groupOptionsMap,
			[group.id]: group,
		},
	}
}

const onUpdateGroupOptionsMap = (state: ChallengeState, groupOptionsMap: Record<string, Group>): ChallengeState => {
	return {
		...state,
		groupOptionsMap,
	}
}

const onSetIsValidatingSku = (state: ChallengeState, isValidatingSku: boolean): ChallengeState => ({
	...state,
	validations: {
		...state.validations,
		isValidatingSku,
	},
})

const onSetIsValidatingOptionalFields = (
	state: ChallengeState,
	isValidatingOptionalFields: boolean,
): ChallengeState => ({
	...state,
	validations: {
		...state.validations,
		isValidatingOptionalFields,
	},
})

const onSetIsValidatingChallengeId = (state: ChallengeState, isValidatingChallengeId: boolean): ChallengeState => ({
	...state,
	validations: {
		...state.validations,
		isValidatingChallengeId,
	},
})

const onDuplicateChallenge = (
	state: ChallengeState,
	{ challengeSetupFormData, challengeFilterFormData, challengeDetailsFormData }: DuplicateChallengePayload,
): ChallengeState => ({
	...state,
	isDuplicating: true,
	challengeSetupFormData,
	challengeFilterFormData,
	challengeDetailsFormData,
})

const onCloseChallengeForm = (state: ChallengeState): ChallengeState => ({
	...challengeInitialState,
	pageLevelNotification:
		state.pageLevelNotification.type === 'success'
			? state.pageLevelNotification
			: challengeInitialState.pageLevelNotification,
})

const onEditChallenge = (
	state: ChallengeState,
	{ activeEditDialog, challengeForEditing }: EditChallengePayload,
): ChallengeState => ({
	...state,
	activeEditDialog,
	challengeForEditing,
})

const onCloseEditChallengeDialog = (state: ChallengeState): ChallengeState => ({
	...state,
	activeEditDialog: EditDialog.None,
	challengeForEditing: null,
})

const onSuccessfullySubmitForm = (state: ChallengeState, successfullySubmitForm: boolean): ChallengeState => ({
	...state,
	successfullySubmitForm,
})

const onAppendToGroupOptionsMap = (state: ChallengeState, optionsMap: Record<string, Group>): ChallengeState => ({
	...state,
	groupOptionsMap: {
		...state.groupOptionsMap,
		...optionsMap,
	},
})

const onAddIndividualAccountGroup = (
	state: ChallengeState,
	individualAccountGroup: IndividualAccountGroup,
): ChallengeState => ({
	...state,
	individualAccountGroup: {
		...state.individualAccountGroup,
		...individualAccountGroup,
	},
})

const onSetChallengeId = (state: ChallengeState, challengeId: string): ChallengeState => {
	const prevFormData = state.challengeSetupFormData
	if (!prevFormData) {
		throw new Error('You have to create the challengeSetupFormData before!')
	}
	return {
		...state,
		challengeSetupFormData: {
			...prevFormData,
			id: challengeId,
		},
	}
}

const onSetChallengeGroupName = (state: ChallengeState, groupName: string): ChallengeState => {
	const prevFormData = state.challengeSetupFormData
	if (!prevFormData) {
		throw new Error('You have to create the challengeSetupFormData before!')
	}
	return {
		...state,
		challengeSetupFormData: {
			...prevFormData,
			groupName,
		},
	}
}

const removeFilters = (state: ChallengeState): ChallengeState => ({
	...state,
	challengeFilterFormData: {
		...state.challengeFilterFormData,
		filters: [{ filter: '', value: [] }],
		individualTarget: undefined,
		hasFilter: undefined,
	},
	individualAccountGroup: {},
	newlyCreatedGroupId: null,
})

const onClearNextStepsData = (state: ChallengeState): ChallengeState => {
	let stateCopy = { ...state }
	if (stateCopy.challengeFilterFormData) {
		stateCopy = removeFilters(state)
	}
	if (stateCopy.challengeDetailsFormData) {
		stateCopy.challengeDetailsFormData = undefined
	}
	return stateCopy
}

const onSetExecutionMethod = (state: ChallengeState, executionMethod: ChallengeExecutionMethod): ChallengeState => ({
	...state,
	challengeSetupFormData: {
		...state.challengeSetupFormData,
		executionMethod,
	},
})

const onSetIndividualTarget = (state: ChallengeState, individualTarget: boolean): ChallengeState => ({
	...state,
	challengeFilterFormData: {
		...state.challengeFilterFormData,
		filters: state.challengeFilterFormData?.filters || [{ filter: '', value: [] }],
		individualTarget,
	},
})

const onSetOptionalLanguagesUsed = (state: ChallengeState, optionalLanguagesUsed: Array<string>): ChallengeState => ({
	...state,
	optionalLanguagesUsed,
})

const ChallengeStore = createStore(challengeInitialState)
	.on(ChallengeEvents.setCurrentStep, onSetCurrentStep)
	.on(ChallengeEvents.setShowSubmitDialog, onShowSubmitDialog)
	.on(ChallengeEvents.setChallengeSetupFormData, onSetChallengeSetupFormData)
	.on(ChallengeEvents.setChallengeDetailsFormData, onSetChallengeDetailsFormData)
	.on(ChallengeEvents.showPageLevelNotification, onShowPageLevelNotification)
	.on(ChallengeEvents.hidePageLevelNotification, onHidePageLevelNotification)
	.on(ChallengeEvents.setChallengeFilterFormData, onSetChallengeFilterFormData)
	.on(ChallengeEvents.setShowAccountGroupModal, onSetShowAccountGroupModal)
	.on(ChallengeEvents.setNewlyCreatedGroup, onSetNewlyCreatedGroup)
	.on(ChallengeEvents.updateGroupOptionsMap, onUpdateGroupOptionsMap)
	.on(ChallengeEvents.setIsValidatingSku, onSetIsValidatingSku)
	.on(ChallengeEvents.setIsValidatingOptionalFields, onSetIsValidatingOptionalFields)
	.on(ChallengeEvents.setIsValidatingChallengeId, onSetIsValidatingChallengeId)
	.on(ChallengeEvents.duplicateChallenge, onDuplicateChallenge)
	.on(ChallengeEvents.closeChallengeForm, onCloseChallengeForm)
	.on(ChallengeEvents.editChallenge, onEditChallenge)
	.on(ChallengeEvents.closeEditChallengeDialog, onCloseEditChallengeDialog)
	.on(ChallengeEvents.setSuccessfullySubmitForm, onSuccessfullySubmitForm)
	.on(ChallengeEvents.addIndividualAccountGroup, onAddIndividualAccountGroup)
	.on(ChallengeEvents.appendToGroupOptionsMap, onAppendToGroupOptionsMap)
	.on(ChallengeEvents.setChallengeId, onSetChallengeId)
	.on(ChallengeEvents.clearNextStepsData, onClearNextStepsData)
	.on(ChallengeEvents.setChallengeGroupName, onSetChallengeGroupName)
	.on(ChallengeEvents.setExecutionMethod, onSetExecutionMethod)
	.on(ChallengeEvents.setIndividualTarget, onSetIndividualTarget)
	.on(ChallengeEvents.setOptionalLanguagesUsed, onSetOptionalLanguagesUsed)
	.reset(ChallengeEvents.reset)

export default ChallengeStore
