import { ChallengeSetupFormData, ChallengeStore } from '@/stores/challengeCreation'
import { useStore } from 'effector-react'
import React from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import useSupportedLanguages from '@/hooks/useSupportedLanguages/useSupportedLanguages'
import * as FeatureToggle from '@/utils/featureToggle'

import { useFeatureToggleV2 } from '@/hooks'
import { getValidationObject } from './validationObject'

const ChallengeFormSetupProvider: React.FC = ({ children }) => {
	const { challengeSetupFormData } = useStore(ChallengeStore)
	const { supportedLanguages } = useSupportedLanguages() ?? {}
	const isVisionId = useFeatureToggleV2(FeatureToggle.DEV_CHALLENGE_VISIONID)
	const isThumbnailAvailable = useFeatureToggleV2(FeatureToggle.DEV_CHALLENGE_THUMBNAIL)

	const formMethods = useForm<ChallengeSetupFormData>({
		defaultValues: challengeSetupFormData || undefined,
		resolver: yupResolver(getValidationObject(supportedLanguages, isVisionId, isThumbnailAvailable)),
		mode: 'all',
		shouldFocusError: false,
	})

	return <FormProvider {...formMethods}>{children}</FormProvider>
}

export default ChallengeFormSetupProvider
