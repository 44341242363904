import React, { ReactNode } from 'react'
import { Heading, IconButton, Modal } from '@hexa-ui/components'
import { X } from '@hexa-ui/icons'
import Styled from '../../../../SkuModal.styles'
import { useTranslation } from 'react-i18next'

interface SkuModalProps {
	readonly onCloseModal: () => void
	readonly isOpened: boolean
	readonly children: ReactNode
	readonly actions: ReactNode
}

export function SkuModalRoot({ onCloseModal, isOpened, children, actions }: SkuModalProps) {
	const { t } = useTranslation()
	return (
		<Modal.Root
			open={isOpened}
			onEscapeKeyDown={() => onCloseModal()}
			title={
				<Styled.Header>
					<Heading size="H3" data-testid="sku-modal-title">
						{t('rules:CREATE_EARNING_RULES.EARNING_SECTION.ADD_SKUS')}
					</Heading>
					<Styled.CloseBtn>
						<Modal.Cancel>
							<IconButton
								data-testid="btn-close-modal"
								icon={X}
								variant="inherit"
								fr="s"
								onClick={() => onCloseModal()}
							/>
						</Modal.Cancel>
					</Styled.CloseBtn>
				</Styled.Header>
			}
			actions={actions}
		>
			{children}
		</Modal.Root>
	)
}
