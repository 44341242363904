import React from 'react'
import CreateRule from './RulesRedesign/Pages/CreateRule/CreateRule'
import CreateRuleProvider from './RulesRedesign/Pages/CreateRule/CreateRuleProvider'
import RulesProvider from './providers/RulesProvider'

const CreateRuleRouter: React.FC = () => (
	<RulesProvider>
		<CreateRuleProvider>
			<CreateRule />
		</CreateRuleProvider>
	</RulesProvider>
)

export default CreateRuleRouter
