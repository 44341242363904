import React from 'react'
import { useTranslation } from 'react-i18next'
import { Badge, Dot, Wrapper } from './StatusBadgeColumn.styles'
import { StatusBadgeColumnProps, StatusColorStyleIndex, StatusEnum } from './StatusBadgeColumn.types'

export const BADGE_DATA_BY_STATUS: {
	[key in StatusEnum]?: { backgroundColor: StatusColorStyleIndex; copyDeckId: string }
} & { default: { backgroundColor: StatusColorStyleIndex; copyDeckId: string } } = {
	ACTIVE: {
		backgroundColor: 'semanticSuccessText',
		copyDeckId: 'rules:EARNING_RULES_TABLE.ACTIVE',
	},
	DRAFT: {
		backgroundColor: 'semanticWarningBackground',
		copyDeckId: 'rules:EARNING_RULES_TABLE.DRAFT',
	},
	default: {
		backgroundColor: 'semanticSuccessText',
		copyDeckId: 'rules:EARNING_RULES_TABLE.ACTIVE',
	},
}

const StatusBadgeColumn = ({ status }: StatusBadgeColumnProps) => {
	const { t } = useTranslation()
	const { backgroundColor, copyDeckId } = BADGE_DATA_BY_STATUS[status] || BADGE_DATA_BY_STATUS.default

	return (
		<Wrapper statusColor={backgroundColor}>
			<Dot statusColor={backgroundColor} />
			<Badge statusColor={backgroundColor} data-testid="badgeColor" label={t(copyDeckId)} />
		</Wrapper>
	)
}

export default StatusBadgeColumn
