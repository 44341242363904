import { useCallback, useEffect, useMemo } from 'react'
import { ComboEvents, ComboStore } from '@/stores'
import { useStoreMap } from 'effector-react'
import { CombosDisposition, CombosListType } from '@/domains/combo/Combo'
import { RedeemableStatus } from '@/domains/enums'
import useLoadRedeemablesAndCombos from '@/hooks/combo/useLoadRedeemablesAndCombos'
import { useIsMountedRef } from '@/hooks/mountedRef/useIsMountedRef'
import { useFeatureToggleV2 } from '@/hooks'
import * as FeatureToggle from '@/utils/featureToggle'

export const useCombos = (status: RedeemableStatus): CombosDisposition => {
	const { loadRedeemableByStatus } = useLoadRedeemablesAndCombos()
	const isMountedRef = useIsMountedRef()
	const { combosData, isLoading } = useStoreMap({
		store: ComboStore,
		keys: [],
		fn: (state) => ({
			combosData: state.combosData,
			isLoading: state.isLoading || !state.isCombosPageReady,
		}),
	})

	const isToggleMultivendorRedeemablesByVendorEnabled = useFeatureToggleV2(
		FeatureToggle.DEV_MULTIVENDOR_REDEEMABLES_BY_VENDOR,
	)

	const combos = useMemo<CombosListType>(() => {
		const redeemables = Object.values(combosData.redeemablesMap)
		return redeemables.filter((redeemable) => redeemable.status === status)
	}, [combosData.redeemablesMap, status])

	const setLoading = useCallback(
		(value: boolean): void => {
			if (isMountedRef.current) {
				ComboEvents.setIsLoading(value)
			}
		},
		[isMountedRef],
	)

	useEffect(() => {
		if (isToggleMultivendorRedeemablesByVendorEnabled) {
			setLoading(true)
			loadRedeemableByStatus([status]).finally(() => {
				setLoading(false)
			})
		}
	}, [loadRedeemableByStatus, setLoading, status, isToggleMultivendorRedeemablesByVendorEnabled])

	return {
		combos,
		isLoading,
		showErrorCombos: combosData.showError,
	}
}
