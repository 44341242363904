import { useQuery } from 'react-query'
import { loadExclusionRules } from '@/services/rules/RulesService'
import useCurrentSettings from '../settings/useCurrentSettings'
import { useHasPermissionRules } from '@/routes/rules/hooks/useHasPermissionRules/useHasPermissionRules'

export function useExclusionRulesData(page = 0, pageSize = 25) {
	const settings = useCurrentSettings()
	const { hasCreateExclusionListPermission } = useHasPermissionRules()
	const { vendorId } = settings

	const queryFn = async () => {
		const data = await loadExclusionRules(vendorId, page, pageSize)

		const content = data.content
			?.filter((item) => hasCreateExclusionListPermission || item.status !== 'DRAFT')
			.map((item) => ({
				...item,
				audienceName: item.audienceName || '-',
				description: item.description || '-',
				updatedAt: item.updatedAt || '-',
				status: item.status,
			}))

		return {
			...data,
			content,
		}
	}

	return useQuery({
		queryKey: ['exclusionRules', page, pageSize, vendorId],
		queryFn,
		staleTime: 5000,
	})
}
