import { PermissionLevel } from '@/domains/enums'
import { Permission } from './createEarningRuleButtonEnabled'

type Props = {
	isFeatureAuthorized: (featureToggle: boolean, requiredPermission?: Permission) => boolean
	isToggledShowExclusionListEnabled: boolean
}

export const createExclusionListEnabled = ({
	isFeatureAuthorized,
	isToggledShowExclusionListEnabled,
}: Props): boolean => {
	const isEnableCreateExclusionList = isFeatureAuthorized(isToggledShowExclusionListEnabled, {
		feature: 'CreateExclusionList',
		permissionLevel: PermissionLevel.Delete,
	})

	return isEnableCreateExclusionList
}
