import { ChallengeExecutionMethod } from '@/domains/enums'
import * as featureToggle from '@/utils/featureToggle'
import { useFeatureToggleV2 } from '../toggle/useFeatureToggle'

type AllowedExecutionMethods = {
	[key: string]: boolean
}

type Props = {
	executionMethod?: ChallengeExecutionMethod
} | null

export const useIsTargetForChallengeEnabled = (props: Props): boolean => {
	const { executionMethod } = props || {}
	const isTargetChallengeVolumeFixedEnabled = useFeatureToggleV2(featureToggle.TARGET_CHALLENGE_VOLUME_FIXED)
	const allowedExecutionMethods: AllowedExecutionMethods = {
		[ChallengeExecutionMethod.VolumeFixed]: isTargetChallengeVolumeFixedEnabled!,
	}

	return !!(executionMethod && allowedExecutionMethods[executionMethod])
}
