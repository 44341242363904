import React, { useCallback, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { TableProps } from '@hexa-ui/components'
import { CreateEarningRuleFormData } from '@/routes/rules/RulesRedesign/Pages/CreateEarningRule/context/CreateEarningRuleProvider/CreateEarningRuleProvider.types'
import { useFormContext } from 'react-hook-form'
import { SkuRuleItem } from '@/services/rules/RulesService.types'
import { SkuPreview } from '../../Components/customRender/SkuPreview/SkuPreview'
import { SkuDelete } from '../../Components/customRender/SkuDelete/SkuDelete'
import { EmptyState } from '../../Components/EmptyState/EmptyState'
import { UseTableHandlersProps } from './useTableHandlers.types'
import { SkuModalValueType } from '../../../SkuModal/context/SkuModalContext'

export const useTableHandlers = ({ hasTableSearch, isEditMode }: UseTableHandlersProps) => {
	const { t } = useTranslation()
	const { getValues, setValue, register } = useFormContext<CreateEarningRuleFormData>()

	useEffect(() => {
		register('items')
	}, [])

	const handleRemoveSku = useCallback(
		(itemId: string) => {
			const currentItems = getValues('items')
			const result = currentItems?.filter((item) => item.itemId !== itemId)
			setValue('items', result, { shouldTouch: true, shouldValidate: true, shouldDirty: true })
		},
		[getValues, setValue],
	)

	const addSkuHandler = useCallback(
		(skusFromModal: SkuModalValueType) => {
			/* istanbul ignore else */
			if (skusFromModal) {
				const parsedToSkuRuleItem: SkuRuleItem[] = skusFromModal.items.map((skuFromModalTable) => ({
					itemId: skuFromModalTable.sku,
					sku: skuFromModalTable.sku,
					vendorItemId: skuFromModalTable.id,
					image: skuFromModalTable?.image,
					name: skuFromModalTable.name,
				}))
				const prev = getValues('items') ?? /* istanbul ignore next */ []
				const existingSkuIds = new Set(prev?.map((sku) => sku.itemId))
				const uniqueNewSkus = parsedToSkuRuleItem.filter((sku) => !existingSkuIds.has(sku.itemId))
				setValue('items', [...uniqueNewSkus, ...prev])
			}
		},
		[getValues, setValue],
	)

	const columns = useMemo(
		() => [
			{
				Header: t('rules:SKUS.LIST.COLUMN_PREVIEW'),
				accessor: 'image',
				disableSortBy: true,
				customRender: SkuPreview,
				style: { width: '10%' },
			},
			{
				Header: t('rules:SKUS.LIST.COLUMN_PRODUCT'),
				accessor: 'name',
				disableSortBy: true,
				style: { width: '44%' },
			},
			{
				Header: t('rules:SKUS.LIST.COLUMN_SKU'),
				accessor: 'sku',
				disableSortBy: true,
				style: { width: '44%' },
			},
			{
				Header: '',
				accessor: 'delete',
				disableSortBy: true,
				style: { width: '2%' },
				...(isEditMode
					? {
							customRender: (_: any, rowData: SkuRuleItem) => (
								<SkuDelete rowData={rowData} handleClick={handleRemoveSku} />
							),
						}
					: {}),
			},
		],
		[t, handleRemoveSku, isEditMode],
	)
	const tableProps: TableProps<SkuRuleItem> = {
		columns,
		data: getValues('items') ?? /* istanbul ignore next */ [],
		search: hasTableSearch,
		searchPlaceholder: t('rules:SKUS.LIST.SEARCH'),
		emptyMessage: <EmptyState />,
	}

	return {
		tableProps,
		addSkuHandler,
		handleRemoveSku,
	}
}
