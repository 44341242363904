import React, { useMemo } from 'react'
import { useHistory } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { isValid } from 'date-fns'
import { BASE_ROUTE } from '@/utils/constants'
import { formatDate } from '@/utils/date'
import { useExclusionRulesData } from '@/hooks/exclusionRules/useExclusionRulesData'
import { useRuleSelectedStatus } from '@/context/RuleSelectedStatusContext/RuleSelectedStatusContext'
import GenericRulesList from '../components/GenericRuleList/GenericRuleList'
import { RenderStatusColumn } from '../components/GenericRuleList/components/customRender/StatusColumn/StatusColumn'

const ExclusionRulesList: React.FC = () => {
	const history = useHistory()
	const { t } = useTranslation()
	const { data: exclusionRules, isLoading } = useExclusionRulesData()
	const { setRuleSelectedStatus } = useRuleSelectedStatus()

	const handleRowClick = (ruleId: string, status: string) => {
		setRuleSelectedStatus(status ?? null)
		history.push({
			pathname: `${BASE_ROUTE}/rules/exclusion-rule/${ruleId}`,
			search: status === 'DRAFT' ? '?action=edit' : '?action=view',
		})
		localStorage.setItem('defaultValueTab', 'tab-exclusionList')
	}

	const columns = useMemo(
		() => [
			{
				Header: t('rules:EARNING_RULES_TABLE.RULE_DESCRIPTION'),
				accessor: 'description',
				disableSortBy: false,
				style: { width: '30%' },
			},
			{
				Header: t('rules:EARNING_RULES_TABLE.AUDIENCE'),
				accessor: 'audienceName',
				disableSortBy: false,
				style: { width: '20%' },
			},
			{
				Header: t('rules:EARNING_RULES_TABLE.EDITED_ON'),
				accessor: 'updatedAt',
				disableSortBy: false,
				Cell: ({ value }: { value: Date }) => (isValid(new Date(value)) ? formatDate(value, 'DD MMMM, YYYY') : '-'),
				style: {
					width: '10%',
				},
			},
			{
				Header: t('rules:EARNING_RULES_TABLE.STATUS'),
				accessor: 'status',
				disableSortBy: true,
				style: { width: '10%' },
				customRender: RenderStatusColumn,
			},
		],
		[t],
	)

	return (
		<GenericRulesList
			columns={columns}
			data={exclusionRules?.content ?? []}
			loading={isLoading}
			description="rules:EARNING_RULES_TABLE.EXCLUSION_LIST_DESCRIPTION"
			search
			searchPlaceholder={t('rules:EARNING_RULES_TABLE.SEARCH_BY_DESCRIPTION')}
			onRow={(rowData) => ({
				onClick: () => handleRowClick(rowData.ruleId, rowData?.status),
				style: { cursor: 'pointer' },
			})}
		/>
	)
}

export default ExclusionRulesList
