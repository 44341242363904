import { PointsRemovalTransactionResponse } from '@/domains'
import * as TransactionService from '@/services/transaction/TransactionService'
import { getDefaultVendorIdOfCurrentZone } from '@/utils/getDefaultVendorId'

async function execute(values: Record<string, unknown>): Promise<PointsRemovalTransactionResponse> {
	const { points, pocId, accounts, description, removeReason, fileName } = values
	const vendorId = getDefaultVendorIdOfCurrentZone()

	const formattedMultipleAccounts = (
		accounts as {
			poc_id: string
			points: number
		}[]
	)?.map((item) => ({
		accountId: item.poc_id,
		points: Number(item.points),
	}))

	const formattedAccounts = [{ accountId: pocId as string, points: Number(points) }]

	const newAccounts = formattedMultipleAccounts?.length > 1 ? formattedMultipleAccounts : formattedAccounts

	const pointsRemovalData = {
		removalReason: removeReason as string,
		description: description as string,
		fileName: fileName as string,
		quantity: newAccounts.length,
		vendorId,
		preventNegativeBalance: true,
		singlePOC: newAccounts.length === 1,
		accounts: newAccounts,
	}

	return TransactionService.bulkPointsRemoval(pointsRemovalData)
}

export { execute }
