export interface ExclusionRule {
	ruleId: string
	vendorId: string
	description: string
	audience: string
	audienceName: string
	estimatedPocCount: number
	updatedAt: string
	status: EnuStatusRule
	name?: string
}

export interface AudienceSelectorTypes {
	audienceId: string
	audienceName: string
}

export interface ExclusionListResponse {
	content: ExclusionRule[]
	pagination: {
		page: number
		pageSize: number
		hasNextPage: boolean
	}
}

export interface EarningRulesResponse {
	content: EarningRuleContent[]
	pagination: Pagination
}

export interface SkuRuleItem {
	vendorId?: string
	vendorItemId: string
	sku: string
	itemId: string
	image?: string
	name?: string
}

export enum EnuStatusRule {
	active = 'ACTIVE',
	draft = 'DRAFT',
}
export interface EarningRuleContent {
	vendorId: string
	tier: string
	ruleId: string
	description: string
	points: number
	amountSpent: number
	items: SkuRuleItem[]
	updatedAt: string
	status: EnuStatusRule
}

interface Pagination {
	page: number
	pageSize: number
	hasNextPage: boolean
}

export interface GetEarningRuleByIdProps {
	page?: number
	pageSize?: number
	ruleIds?: string
	tier: string | null
	vendorId: string
}

export interface GetEarningRuleByIdResponse {
	content: EarningRuleContent[]
}

export interface GetExclusionRuleByIdProps {
	vendorId: string
	page?: number
	pageSize?: number
	ruleId?: string
}

export interface GetExclusionRuleByIdResponse {
	content: ExclusionRule[]
}

export interface CreateEarningRuleResponse {
	ruleId: string
	name: string
	description: string
	vendorId: string
	tier: string
	isDefaultRule: boolean
	isExclusionRule: boolean
	isDeleted: boolean
	status: string
	points: number
	amountSpent: number
	createdAt: string
}

export interface CreateEarningRuleDTO {
	amountSpent: number
	points: number
	description: string
	status: EnuStatusRule
	items?: SkuRuleItem[]
	name?: string
	vendorId?: string
	tier?: string | null
}

export interface UpdateEarningRuleDTO extends CreateEarningRuleDTO {
	ruleId: string
}

export interface CreateExclusionRuleDTO {
	description: string
	name?: string
	audienceId?: string
	status: EnuStatusRule
	vendorId?: string
}

export interface UpdateExclusionRuleDTO extends CreateExclusionRuleDTO {
	ruleId?: string
}
