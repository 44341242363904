import { useGetMultiplesFeatureToggles } from '@/hooks/toggle/useGetMultiplesFeatureToggles'
import * as FeatureToggle from '@/utils/featureToggle'

import { IuseGetFeatureTogglesValuesToSelectedInfoResult } from './useGetFeatureTogglesValuesToSelectedInfo.type'

export const useGetFeatureTogglesValuesToSelectedInfo = (): IuseGetFeatureTogglesValuesToSelectedInfoResult => {
	const { featuresListByToggleName } = useGetMultiplesFeatureToggles([
		FeatureToggle.SELECT_TIERS,
		FeatureToggle.SELECT_PROGRAM,
		FeatureToggle.SELECT_TIERS,
	])

	return {
		isSelectTiersEnabled: featuresListByToggleName[FeatureToggle.SELECT_TIERS],
		featureToggleProgram: featuresListByToggleName[FeatureToggle.SELECT_PROGRAM],
		featureToggleTiers: featuresListByToggleName[FeatureToggle.SELECT_TIERS],
	}
}
