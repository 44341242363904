import React from 'react'
import { useTranslation } from 'react-i18next'
import { Heading, Paragraph } from '@hexa-ui/components'
import { useFormContext } from 'react-hook-form'
import { Card, ContentCardViewMode, ItemStyleExcusionViewMode, ItemStyleViewMode } from './SettingsCard.styles'
import StatusIndicator from './SettingsCardStatus/settingsCardStatus'

import { CreateExclusionRuleFormData } from '../../Pages/CreateExclusionPageRule/context/CreateExclusionListProvider/CreateExclusionListProvider.types'

export function SettingsCardViewExclusion() {
	const { t } = useTranslation()

	const { getValues: getExclusionValues } = useFormContext<CreateExclusionRuleFormData>()

	const ruleDescriptionExclusion = getExclusionValues('description')

	return (
		<Card border="medium" elevated="small" data-testid="settings-card-view">
			<ContentCardViewMode>
				<Heading size="H3">{t('rules:CREATE_EARNING_RULES.EARNING_SECTION.SETTINGS')}</Heading>

				<ItemStyleExcusionViewMode>
					<div style={{ width: '50%' }}>
						<Paragraph weight="semibold">{t('rules:CREATE_EARNING_RULES.EARNING_SECTION.RULE_DESCRIPTION')}</Paragraph>
						<Paragraph data-testid="exclusion-rule-description-value"> {ruleDescriptionExclusion} </Paragraph>
					</div>
					<ItemStyleViewMode>
						<Paragraph weight="semibold">{t('rules:EARNING_RULES_TABLE.STATUS')}</Paragraph>
						<Paragraph>
							<StatusIndicator status="active" />
						</Paragraph>
					</ItemStyleViewMode>
				</ItemStyleExcusionViewMode>
			</ContentCardViewMode>
		</Card>
	)
}
