import { syncCombo } from '@/services/redeemables/RedeemablesService'
import { $createRedeemableItemStore } from '@/stores'
// import { isToggleSyncRedeemableEnabled, isNewTransactionsLogsEnabled } from '@/utils/featureToggle/featureToggleHelper'
import { Grid } from '@hexa-ui/components'
import { Sync } from '@hexa-ui/icons'
import { TextButton } from '@hexa-ui/text-button'
import { TypeToast, useToast } from 'admin-portal-shared-services'
import { useStore } from 'effector-react'
import React, { HTMLAttributes, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import useScopeAuthorization from '@/hooks/useScopeAuthorization/useScopeAuthorization'
import { REDEEMABLE_SKU_CPP } from '@/utils/constants'
import { useFeatureToggleV2 } from '@/hooks'
import * as FeatureToggle from '@/utils/featureToggle'
import { CreateRedeemableItem } from '../CreateRedeemableItem/CreateRedeemableItem'
import { EditRedeemableItem } from '../EditRedeemableItem/EditRedeemableItem'
import RedeemableItemsListStyle from './RedeemableItemsListStyle'
import { ViewState } from './RedeemableItemsListViewState'
import { CreateRedeemableItemButton } from './components/CreateRedeemableItemButton/CreateRedeemableItemButton'
import { ExportRedeemableItemButton } from './components/ExportRedeemableItemButton/ExportRedeemableItemButton'
import { RedeemableItemsEmptyState } from './components/RedeemableItemsEmptyState/RedeemableItemsEmptyState'
import { RedeemableItemsTable } from './components/RedeemableItemsTable/RedeemableItemsTable'
import { useRedeemableItemActions } from './useRedeemableItemActions'
import { useRedeemableItemsList } from './useRedeemableItemsList'
import { CriticalPricePerPoint } from '../CriticalPricePerPoint/CriticalPricePerPoint'

export const RedeemableItemsList = (props: HTMLAttributes<HTMLDivElement>) => {
	const { isLoading, hasError, data, ...useProps } = useRedeemableItemsList()
	const { genericToastError } = useRedeemableItemActions()
	const { t } = useTranslation()
	const [isFirstLoad, setIsFirstLoad] = useState(true)
	const [viewState, setViewState] = useState(ViewState.LOADING)
	const { isOpened } = useStore($createRedeemableItemStore)
	const is3pd = useFeatureToggleV2(FeatureToggle.REDEEMABLE_SYNC_3PD)

	const toast = useToast()

	const hasSKUCPPPermission = useScopeAuthorization([REDEEMABLE_SKU_CPP])

	const syncRedeemClick = async () => {
		try {
			const response = await syncCombo()
			if (response.status === 200 || response.status === 201) {
				toast.notify({
					type: TypeToast.SUCCESS,
					message: t('redeemable:REFRESH.SUCCESS'),
				})
			} else {
				toast.notify({
					type: TypeToast.ERROR,
					message: t('redeemable:REFRESH.FAIL'),
				})
			}
		} catch (error) {
			toast.notify({
				type: TypeToast.ERROR,
				message: t('redeemable:REFRESH.FAIL'),
			})
		}
	}

	useEffect(() => {
		if (isLoading) {
			setViewState(ViewState.LOADING)
			return
		}

		if (hasError) {
			setViewState(ViewState.ERROR)
			return
		}

		if (isFirstLoad && data.length <= 0) {
			setViewState(ViewState.EMPTY)
			return
		}

		setIsFirstLoad(false)
		setViewState(ViewState.DONE)
	}, [isLoading, hasError, data, viewState, isFirstLoad])

	useEffect(() => {
		if (viewState !== ViewState.ERROR) {
			return
		}
		genericToastError()
	}, [viewState, genericToastError])

	const css = RedeemableItemsListStyle()

	if (isFirstLoad && [ViewState.LOADING, ViewState.EMPTY, ViewState.ERROR].includes(viewState)) {
		return (
			<Grid.Item data-testid="redeemable-items-empty-state" className={css.mainWrapper} {...props}>
				<RedeemableItemsEmptyState viewState={viewState} />
			</Grid.Item>
		)
	}

	return (
		<Grid.Item data-testid="redeemable-items-list" className={css.mainWrapper} {...props}>
			<CriticalPricePerPoint />
			<EditRedeemableItem />
			<Grid.Item className={css.headerWrapper}>
				<ExportRedeemableItemButton />
				{hasSKUCPPPermission && <CreateRedeemableItem isOpened={isOpened} trigger={<CreateRedeemableItemButton />} />}
			</Grid.Item>
			<RedeemableItemsTable viewState={viewState} data={data} {...useProps} />
			{is3pd && (
				<TextButton
					onClick={syncRedeemClick}
					icon={Sync}
					iconPosition="leading"
					style={{
						marginTop: '-41px',
						alignSelf: 'flex-start',
						marginLeft: '14px',
					}}
					data-testid="redeemable-items-list-refresh-button"
				>
					{t('redeemable:REFRESH.BUTTON')}
				</TextButton>
			)}
		</Grid.Item>
	)
}
