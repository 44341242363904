import { deleteComboFromProgram, bulkCombosDelete } from '@/services/program/ProgramService'

async function execute({
	programId,
	comboIds,
	comboBulkUpdateToggle,
}: {
	programId: string
	comboIds: string[]
	comboBulkUpdateToggle: boolean
}): Promise<boolean> {
	const servicePromise = comboBulkUpdateToggle
		? bulkCombosDelete(programId, comboIds)
		: deleteComboFromProgram(programId, comboIds[0])

	try {
		await servicePromise
		return true
	} catch (e) {
		return false
	}
}

export { execute }
