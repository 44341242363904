import React, { useMemo } from 'react'
import { Dot } from '@hexa-ui/icons'
import TransparencyStatusEnum from '@/domains/enums/TransparencyStatusEnum'
import { useTranslation } from 'react-i18next'
import makeStyles from './styles'

export interface TransactionStatusBadgeProps {
	type: keyof typeof TransparencyStatusEnum
}

const TransactionStatusBadge = ({ type }: TransactionStatusBadgeProps) => {
	const classes = makeStyles()
	const { t } = useTranslation()
	const lowerCaseType = type?.toLowerCase()
	const text = useMemo(() => t(`transaction:STATUS:${type}`), [t, type])

	return (
		<div data-testid={`transaction-status-badge-${type}`}>
			<span className={`${classes.statusBadge} ${classes[lowerCaseType as keyof typeof classes]}`}>
				<Dot className={classes.dot} size="tiny" fr={1} />
				{text}
			</span>
		</div>
	)
}

export default TransactionStatusBadge
