import React, { useMemo, useState } from 'react'
import { useStoreMap } from 'effector-react'
import { RulesStore } from '@/stores'
import Rule from '@/domains/rule/Rule'
import ButtonView from '../RulesRedesign/Components/ButtonView/ButtonView'
import RuleNameColumn from '../RulesRedesign/Components/RuleNameColumn/RuleNameColumn'
import { useHasPermissionRules } from './useHasPermissionRules/useHasPermissionRules'

export interface RuleDataTableResult {
	dataRules: Rule[]
	setRuleFilter: React.Dispatch<React.SetStateAction<string>>
}

const useRuleDataTable = (): RuleDataTableResult => {
	const [ruleFilter, setRuleFilter] = useState('')
	const { hasCreateEarningRulesPermission } = useHasPermissionRules()

	const rulesList = useStoreMap({
		store: RulesStore,
		keys: [],
		fn: (state) => state.rules,
	})

	const dataRules = useMemo(() => {
		if (!rulesList) return []

		return rulesList
			.filter((rule) => {
				if (!hasCreateEarningRulesPermission && rule.status === 'DRAFT') return false
				return rule.name.includes(ruleFilter) || rule?.description?.includes(ruleFilter)
			})
			.map((rule) => ({
				...rule,
				nameColumn: <RuleNameColumn rule={rule} />,
				action: <ButtonView rule={rule} />,
			}))
	}, [rulesList, ruleFilter, hasCreateEarningRulesPermission])

	return { dataRules, setRuleFilter }
}

export default useRuleDataTable
