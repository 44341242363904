import {
	$redeemableItemLimitStore,
	RedeemableItemLimitStoreEvents,
} from '@/stores/redeemableItems/RedeemableItemLimitStore'
import { Button } from '@hexa-ui/components'
import { useStore } from 'effector-react'
import React, { useCallback } from 'react'
import { useTranslation } from 'react-i18next'

export const UpdateLimitButton = React.forwardRef(() => {
	const { t } = useTranslation()
	const { limit } = useStore($redeemableItemLimitStore)

	const onClickHandler = useCallback((evt: React.MouseEvent) => {
		evt.preventDefault()
		RedeemableItemLimitStoreEvents.onOpen()
	}, [])

	const buttonI18nText = limit ? 'redeemable:LIMIT.UPDATE_LIMIT' : 'redeemable:LIMIT.SET_LIMIT'

	return (
		<Button size="medium" variant="secondary" onClick={onClickHandler} data-testid="redeemable-limit-button">
			{t(buttonI18nText)}
		</Button>
	)
})
