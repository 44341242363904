import React, { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { Alert, Button, Dialog } from '@hexa-ui/components'
import { useTranslation } from 'react-i18next'
import * as currencyHelper from '@/utils/currencyHelper'
import { InputCurrency } from '@/components/hook-form/InputCurrencyV2' // TO DO: Check if we'll update to V2 in all scenarios
import { useRedeemableItems } from '@/context/RedeemableItems/RedeemableItemsContext'
import { UpdateDefaultCppUseCase } from '@/usecase/redeemableItemsV2'
import { unformat } from '@/utils/currencyHelper'
import { TypeToast, useToast } from 'admin-portal-shared-services'
import { DefaultCppStoreEvents } from '@/stores/redeemableItems/DefaultCPPStore'
import useStyle from './EditDefaultCPPDialogStyle'
import { usePricePerPointConfiguration } from '../../hooks/usePricePerPointConfiguration'

interface ActionsProps {
	onClose: () => void
	onUpdate: () => void
	open: boolean | undefined
}

interface EditDefaultCppDialogProps extends ActionsProps {
	open: boolean
}

export const EditDefaultCppDialog: React.FC<EditDefaultCppDialogProps> = ({ open, onClose, onUpdate }) => {
	const { t } = useTranslation()
	const toast = useToast()
	const css = useStyle()
	const { data } = usePricePerPointConfiguration()

	const [currencyValue, setCurrencyValue] = useState<string>('')

	const handleCurrencyChange = (event: ChangeEvent<HTMLInputElement>) => {
		setCurrencyValue(event.target.value)
	}

	const title = <h1>{t('redeemable:UPDATE_CPP.TITLE')}</h1>

	const defaultPricePerPoint = data?.pricePerPoint
	const placeholder = currencyHelper.format(defaultPricePerPoint)

	const onSubmit = useCallback(async () => {
		try {
			if (defaultPricePerPoint && currencyValue) {
				const unformattedCurrencyValue: number = unformat(currencyValue)
				await UpdateDefaultCppUseCase.execute(unformattedCurrencyValue, defaultPricePerPoint).then(() => onUpdate())
				DefaultCppStoreEvents.reload()
				onClose()
			}
		} catch (error) {
			toast.notify({
				type: TypeToast.ERROR,
				message: t('ERROR_MESSAGE.GENERIC_ERROR'),
			})
		}
	}, [currencyValue, defaultPricePerPoint])

	return (
		<Dialog.Root
			closeButton
			title={title}
			open={open}
			onClose={onClose}
			actions={<Actions onClose={onClose} onUpdate={onSubmit} open={open} />}
		>
			<p>{t('redeemable:UPDATE_CPP.DESCRIPTION')}</p>
			<InputCurrency
				name="pricePerPoint"
				value={currencyValue}
				onChange={handleCurrencyChange}
				label="Default CPP"
				placeholder={placeholder}
				width="100%"
			/>

			<Alert className={css.alert} type="warning" message={t('redeemable:UPDATE_CPP.WARNING')} />
		</Dialog.Root>
	)
}

const Actions = ({ onClose, onUpdate, open }: ActionsProps) => {
	const { isInvalidInput, isDisabledButton, setIsDisabledButton } = useRedeemableItems()
	const { t } = useTranslation()
	const css = useStyle()

	useEffect(() => {
		setIsDisabledButton(open)
	}, [])

	useEffect(() => {
		setIsDisabledButton(isInvalidInput)
	}, [setIsDisabledButton, isInvalidInput])

	return (
		<div className={css.actionsWrapper} data-testid="ppp-editor-actions-wrapper">
			<Dialog.Close>
				<Button size="large" variant="secondary" onClick={onClose}>
					{t('common:BUTTON.CANCEL')}
				</Button>
			</Dialog.Close>
			<Button
				data-testid="ppp-editor-save-button"
				size="large"
				variant="primary"
				type="submit"
				onClick={onUpdate}
				disabled={isDisabledButton}
			>
				{t('common:BUTTON.UPDATE')}
			</Button>
		</div>
	)
}
