import { ImageFile } from '@/domains/Challenge'
import {
	FileUploaderType,
	FileValidated,
	FileUploader as HexaFileUploader,
	Paragraph,
	CustomValidateFileResponse,
} from '@hexa-ui/components'
import React, { useEffect, useState } from 'react'
import { DescriptionContainer } from './styles'

interface IImageUpload extends FileUploaderType {
	name: string
	description: string
	label: string
	onUpload: (event: React.ChangeEvent<HTMLInputElement>, field: string) => void
	onRemove: (field: string, files: File) => void
	currentValue?: ImageFile | null
	message: string
	buttonIntl: string
}

export const FileUploader: React.ForwardRefRenderFunction<React.Ref<HTMLDivElement>, IImageUpload> = ({
	onUpload,
	onRemove,
	name,
	currentValue,
	...props
}) => {
	const [fileImage, setFileImage] = useState<string>()
	const [invalidFile, setInvalidFile] = useState<boolean>(false)
	const [files, setFiles] = useState<FileValidated[]>([])

	const validator = (file: File): CustomValidateFileResponse => {
		if (file.type !== 'image/png' && file.type !== 'image/jpeg' && file.type !== 'image/jpg')
			return { valid: false, errors: ['File type not allowed'] }
		if (file.size > 1000000) return { valid: false, errors: ['File is too big'] }
		return { valid: true, errors: [] }
	}

	const cleanFiles = () => {
		setFiles([])
		setInvalidFile(false)
	}

	const handleDrop = (filesDropped: FileValidated[]) => {
		setFiles(filesDropped)
		if (!filesDropped[0].valid) {
			setInvalidFile(true)
			setFileImage('')
		} else {
			const dataTransfer = {
				files: [filesDropped[0].file],
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
			} as any

			const fileList = dataTransfer.files
			const inputElement: React.ChangeEvent<HTMLInputElement> = {
				currentTarget: {
					files: fileList,
				},
			} as React.ChangeEvent<HTMLInputElement>

			onUpload(inputElement, name)

			const reader = new FileReader()
			reader.onload = (e) => {
				setFileImage(e.target?.result as string)
			}
			reader.readAsDataURL(filesDropped[0].file)
			setInvalidFile(false)
		}
	}

	const handleRemove = (filesRemoved: FileValidated[]) => {
		onRemove(name, filesRemoved[0].file)
		setFileImage('')
	}

	const handleValue = (image?: ImageFile | null): Array<FileValidated> | undefined => {
		if (image?.file) {
			return [
				{
					file: image.file,
					id: '1',
					valid: true,
					errors: [],
				},
			]
		}
		return undefined
	}

	useEffect(() => {
		setFileImage(currentValue?.url ?? '')
	}, [currentValue])

	return (
		<div
			style={{
				display: 'flex',
				flex: 1,
				flexDirection: 'column',
				width: '100%',
			}}
		>
			<Paragraph weight="semibold" size="small">
				{props.label}
			</Paragraph>
			<DescriptionContainer>
				<Paragraph colortype="secondary" size="xsmall">
					{props.description}
				</Paragraph>
			</DescriptionContainer>
			<HexaFileUploader
				{...props}
				imageUrl={fileImage ?? ''}
				value={handleValue(currentValue)}
				onDrop={handleDrop}
				maxFiles={1}
				validator={validator}
				onClean={handleRemove}
				maxFileSize={1000000}
				alt="image-file-uploader"
				imageHeight="315px"
				imageFit="fill"
				error={
					invalidFile
						? {
								action: { action: () => cleanFiles(), name: 'Try again' },
								message: `${files[0].errors}`,
							}
						: undefined
				}
			/>
		</div>
	)
}

export default React.forwardRef(FileUploader)
