import { Badge as _Badge } from '@hexa-ui/components'
import { Dot as _Dot } from '@hexa-ui/icons'
import { styled, theme } from '@hexa-ui/theme'

export const Wrapper = styled('div', {
	display: 'inline-flex',
	alignItems: 'center',
	borderRadius: '16px',
	flexWrap: 'nowrap',
	padding: '2px 12px',
	variants: {
		statusColor: {
			semanticErrorText: {
				border: `1px solid ${theme.colors.semanticErrorText}`,
			},
			semanticSuccessText: {
				border: `1px solid ${theme.colors.semanticSuccessBasis}`,
			},
			semanticInfoText: {
				border: `1px solid ${theme.colors.semanticInfoText}`,
			},
			semanticWarningBackground: {
				border: `1px solid ${theme.colors.semanticWarningBasis}`,
			},
		},
	},
})

export const Badge = styled(_Badge.Status, {
	variants: {
		statusColor: {
			semanticErrorText: {
				backgroundColor: 'transparent !important',
				'& p': {
					color: `${theme.colors.semanticErrorText}`,
				},
			},
			semanticSuccessText: {
				backgroundColor: 'transparent !important',
				'& p': {
					color: `${theme.colors.semanticSuccessBasis}`,
				},
			},
			semanticInfoText: {
				backgroundColor: 'transparent !important',
				'& p': {
					color: `${theme.colors.semanticInfoText}`,
				},
			},
			semanticWarningBackground: {
				backgroundColor: 'transparent !important',
				'& p': {
					color: `${theme.colors.semanticWarningText}`,
				},
			},
		},
	},
})

export const Dot = styled(_Dot, {
	width: '5px !important',
	height: '5px !important',
	variants: {
		statusColor: {
			semanticErrorText: {
				color: theme.colors.semanticErrorText,
			},
			semanticSuccessText: {
				color: theme.colors.semanticSuccessBasis,
			},
			semanticInfoText: {
				color: theme.colors.semanticInfoText,
			},
			semanticWarningBackground: {
				color: theme.colors.semanticWarningBasis,
			},
		},
	},
})
