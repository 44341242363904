import { TransactionResponse, RefundTransaction } from '@/domains/transaction'
import { refund } from '@/services/transaction/TransactionService'

async function execute({
	accountId,
	refundTransaction,
	isToggleRefundRedeemableItemsEnabled,
}: {
	accountId: string
	refundTransaction: RefundTransaction
	isToggleRefundRedeemableItemsEnabled: boolean
}): Promise<TransactionResponse> {
	return refund({ accountId, refundTransaction, isToggleRefundRedeemableItemsEnabled })
}

export { execute }
