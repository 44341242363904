import { useCallback } from 'react'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'

export const useTriggerSegmentsCsvSku = (): {
	handleDownloadCSVClick: () => void
	dispatchTracking: (file: File | null | undefined) => void
} => {
	const userInfo = useGetUserInfo()

	const dispatchTracking = useCallback(
		(file: File | null | undefined) => {
			AnalyticsService.events.fileUploaded({
				file_type: 'CSV',
				file_name: file?.name ?? null,
				form_name: 'Earning Rules - SKUs - Edit - Add - Multiple',
				screen_name: 'Earning Rules - SKUs - Edit - Add - Multiple - Step 3',
				...userInfo,
			})
		},
		[userInfo],
	)

	const handleDownloadCSVClick = useCallback(() => {
		AnalyticsService.events.buttonClicked({
			button_label: 'Download CSV Template',
			button_name: 'Download CSV Template',
			screen_name: 'Earning Rules - SKUs - Edit - Add - Multiple - Step 3',
			...userInfo,
		})
	}, [userInfo])

	return {
		handleDownloadCSVClick,
		dispatchTracking,
	}
}
