import React from 'react'

import CreateExclusionRuleProvider from './context/CreateExclusionListProvider/CreateExclusionListProvider'
import CreateExclusionPageRule from './CreateExclusionPageRule'

const CreateExclusionPageRuleWrapper: React.FC = () => (
	<CreateExclusionRuleProvider>
		<CreateExclusionPageRule />
	</CreateExclusionRuleProvider>
)

export default CreateExclusionPageRuleWrapper
