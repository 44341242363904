import { createStyles, makeStyles } from '@material-ui/core/styles'

const CancelRequestDialogStyle = makeStyles(() =>
	createStyles({
		skuPanel: {
			alignItems: 'center',
			display: 'flex',
			height: '88px',
			justifyContent: 'space-around',
			padding: '1rem',
			background: '#1414140A',
		},
		skuInfo: {
			display: 'flex',
			flexDirection: 'column',
			alignItems: 'center',
			'& h5': {
				marginBottom: '0.5rem',
			},
		},
		divider: {
			padding: '1rem',
			height: '88px',
		},
		heading: {
			marginBottom: '1rem',
		},
		paragraph: {
			marginBottom: '2rem',
		},
		buttons: {
			display: 'flex',
			gap: '1rem',
			justifyContent: 'end',
		},
	}),
)

export default CancelRequestDialogStyle
