import { useDialogContext } from '@/context/DialogContext/DialogContext'
import { ConfirmationModalProps } from './useConfirmationModal.types'

const useConfirmationModal = ({
	confirmLabel,
	cancelLabel,
	closeButtonClearNewInfos,
	title,
	body,
	confirmVariant,
}: ConfirmationModalProps): { showModal: (msg?: string) => Promise<boolean> } => {
	const { showDialog, resetDialog } = useDialogContext()

	const showModal = (msg?: string): Promise<boolean> => {
		return new Promise((resolve) => {
			showDialog({
				title,
				open: true,
				body: msg ?? body,
				buttonLeft: {
					children: confirmLabel,
					variant: confirmVariant ?? 'primary',
					size: 'medium',
					onClick: () => {
						resetDialog()
						resolve(true)
					},
				},
				buttonRight: {
					children: cancelLabel,
					variant: 'secondary',
					size: 'medium',
					onClick: () => {
						resetDialog()
						resolve(false)
					},
				},
				closeButton: {
					children: closeButtonClearNewInfos,
					onClick: () => {
						resetDialog()
						resolve(false)
					},
				},
			})
		})
	}
	return { showModal }
}

export default useConfirmationModal
