import { SettingsLevel } from '@/domains/enums/settings'
import { getDefaultVendorIdOfCurrentZone } from '@/utils/getDefaultVendorId'
import { useMemo } from 'react'
import * as FeatureToggle from '@/utils/featureToggle'
import useCurrentZone from '../zone/useCurrentZone'
import { useFeatureToggleV2 } from '../toggle/useFeatureToggle'

export type SelectedSettings = {
	settingsId: string
	level: SettingsLevel
	vendorId: string
}

const useCurrentSettings = (): SelectedSettings => {
	const toggleConfigByZone = useFeatureToggleV2(FeatureToggle.SETTINGS_CONFIG_BY_ZONE)

	const zone = useCurrentZone()
	const vendorId = getDefaultVendorIdOfCurrentZone()

	const settings = useMemo<SelectedSettings>(
		() => ({
			settingsId: toggleConfigByZone ? zone! : vendorId,
			level: toggleConfigByZone ? SettingsLevel.ZONE : SettingsLevel.VENDOR,
			vendorId,
		}),
		[toggleConfigByZone, zone, vendorId],
	)
	return settings
}

export default useCurrentSettings
