import { createStyles, makeStyles } from '@material-ui/core/styles'

export const useStyles = makeStyles(() =>
	createStyles({
		container: {
			'& [role="dialog"] > div:first-child': {
				marginBottom: '0',
			},
		},
		description: {
			marginTop: '0',
		},
	}),
)
