import * as RedeemablesService from '@/services/redeemablesV2/RedeemablesService'
import { RedeemableItemLimitStoreEvents } from '@/stores/redeemableItems/RedeemableItemLimitStore'
import { GlobalStore } from '@/stores'
import { useLogService as logService } from 'admin-portal-shared-services'

async function execute(redeemablesQuantityLimitCreation: number): Promise<number | null> {
	const log = logService()
	const state = GlobalStore.getState()

	try {
		const response = await RedeemablesService.updateLimit({
			settingId: state.selectedVendorId ?? '',
			tier: state.selectedTierKey ?? '',
			redeemablesQuantityLimitCreation,
		})

		if (response?.redeemablesQuantityLimitCreation) {
			RedeemableItemLimitStoreEvents.updateLimit({
				limit: redeemablesQuantityLimitCreation,
				id: state.selectedVendorId ?? '',
			})
			return response.redeemablesQuantityLimitCreation
		}

		return null
	} catch (error) {
		log.error('FAILED TO LOAD THE REDEEMABLE LIMIT: ', error)
		throw error
	}
}

export { execute }
