import { styled, theme } from '@hexa-ui/theme'
import { Card as HexaCard, Paragraph } from '@hexa-ui/components'

const RuleTypeCard = styled(HexaCard, {
	padding: '16px 16px 16px 24px',
	cursor: 'pointer',
	variants: {
		isSelected: {
			true: {
				boxShadow: `0 0 0 4px ${theme.colors.semanticInfoBasis} !important`,
			},
		},
	},
})

const RuleTypeGap = styled('div', {
	display: 'flex',
	flexDirection: 'column',
	gap: '8px',
})

const RuleTypeTitle = styled(Paragraph, {
	fontSize: `${theme.fontSizes[3]} !important`,
	fontWeight: `400 !important`,
})

const RuleTypeDescription = styled(Paragraph, {
	fontSize: `${theme.fontSizes[2]} !important`,
	color: `${theme.colors.interfaceLabelSecondary} !important`,
})

const RuleTypeContainer = styled(HexaCard, {
	display: 'flex',
	flexDirection: 'column',
	padding: '24px',
	gap: '24px',
	margin: '24px 0 24px 0',
})

export const Styled = {
	RuleTypeCard,
	RuleTypeGap,
	RuleTypeTitle,
	RuleTypeDescription,
	RuleTypeContainer,
}
