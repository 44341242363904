import CreateRedeemableItemRequest from '@/domains/redeemable/CreateRedeemableItemRequest'
import { EditRedeemableItemRequest } from '@/domains/redeemable/EditRedeemableItemRequest'
import { RedeemableItem } from '@/domains/redeemable/RedeemableItem'
import axios from '@/utils/axiosInstance'
import { useLogService as logService } from 'admin-portal-shared-services'
import { UpdateDefaultCpp } from '@/domains/redeemable/UpdateDefaultCpp'
import { UpdateLimitParams, UpdateLimitResponse } from '@/domains/redeemable/RedeemableLimit'

export const baseUrlOneRewardsAdminService = `/rewards-admin-service`
const log = logService()

export async function createRedeemable(data: CreateRedeemableItemRequest[]): Promise<RedeemableItem> {
	const url = `${baseUrlOneRewardsAdminService}/v3/redeemables`

	try {
		const response = await axios.post(url, {
			redeemables: data,
		})
		return response?.data
	} catch (error) {
		log.error(`Error endpoint: ${url}`, error)
		throw error
	}
}

export async function updateCpp(data: EditRedeemableItemRequest, type: string): Promise<unknown> {
	const url = `${baseUrlOneRewardsAdminService}/v1/cpp-transactions`

	try {
		const response = await axios.post(url, {
			...data,
			type,
		})
		return response.data
	} catch (error: any) {
		let status = null
		if (error.response) {
			const errorData = error.response.data
			if (errorData && typeof errorData === 'object' && 'status' in errorData) {
				status = errorData.status
			}
		}

		log.error(`Error endpoint: ${url}, with data: ${JSON.stringify(data)}, status: ${status}`, error)
		throw error
	}
}

export const deleteApprovalCpp = async (transactionId?: string): Promise<unknown> => {
	const url = `${baseUrlOneRewardsAdminService}/v1/cpp-transactions/${transactionId}`

	try {
		const response = await axios.delete(url)
		return response.status
	} catch (error) {
		log.error(`Error endpoint: ${url}`, error)
		throw error
	}
}

export async function getDefaultCpp(vendorId: string): Promise<UpdateDefaultCpp> {
	const url = `${baseUrlOneRewardsAdminService}/v1/cpp-transactions?page=0&pageSize=1&type=DEFAULT&order=DESC&vendorId=${vendorId}`

	try {
		const response = await axios.get(url)
		return response.data.content[0]
	} catch (error) {
		log.error(`Error endpoint: ${url}`, error)
		throw error
	}
}

export async function updateLimit({
	settingId,
	tier,
	redeemablesQuantityLimitCreation,
}: UpdateLimitParams): Promise<UpdateLimitResponse | null> {
	const url = `${baseUrlOneRewardsAdminService}/v1/settings/${settingId}/redeemable-limit/${tier}`

	try {
		const response = await axios.put(url, {
			redeemablesQuantityLimitCreation,
		})

		return response.data
	} catch (error) {
		log.error(`Error endpoint: ${url}`, error)
		throw error
	}
}
