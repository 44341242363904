import { UpdateDefaultCpp } from '@/domains/redeemable/UpdateDefaultCpp'
import * as RedeemablesService from '@/services/redeemablesV2/RedeemablesService'
import { getDefaultVendorIdOfCurrentZone } from '@/utils/getDefaultVendorId'
import { useLogService as logService } from 'admin-portal-shared-services'

async function execute(): Promise<UpdateDefaultCpp> {
	const log = logService()
	const vendorId = getDefaultVendorIdOfCurrentZone()
	try {
		const response = await RedeemablesService.getDefaultCpp(vendorId)
		return response
	} catch (error) {
		log.error('FAILED TO UPDATE DEFAULT CPP: ', error)
		throw error
	}
}

export { execute }
