import React, { useMemo } from 'react'
import SmallSelect from '@/components/form/Select/SmallSelect'
import { useStore } from 'effector-react'
import { GlobalStore } from '@/stores'
import useCurrentVendor from '@/hooks/vendor/useCurrentVendor'
import { useGlobalConfig } from '@/hooks/global/useGlobalConfig'
import { hasMoreElementsThan } from '@/utils/array'
import { useLocation } from 'react-router-dom'
import { useNavigateToRules } from '@/routes/rules/hooks/useNavigateToRules'
import { useGetRoutes } from '@/routes/routes'
import AnalyticsService from '@/services/analytics/AnalyticsService'
import { useGetUserInfo } from '@/hooks/getUserInfo/useGetUserInfo'
import useEditMode from '@/routes/rules/hooks/useEditMode/useEditMode'
import useConfirmationModal from '@/hooks/useConfirmationModal/useConfirmationModal'
import { useTranslation } from 'react-i18next'

const SelectVendor: React.FC = (): JSX.Element | null => {
	const { t } = useTranslation()
	const { vendorOptionsMap } = useStore(GlobalStore)
	const currentVendor = useCurrentVendor()
	const { defineCurrentVendor } = useGlobalConfig()
	const { goToRulesList } = useNavigateToRules()
	const location = useLocation()
	const { routes } = useGetRoutes()
	const { EARNING_RULES_ROUTE } = routes
	const userInfo = useGetUserInfo()
	const { isEditMode } = useEditMode()

	const options = useMemo(
		() =>
			Object.values(vendorOptionsMap).map((item) => ({
				label: item.vendorName,
				value: item.vendorId,
			})),
		[vendorOptionsMap],
	)

	const validateCurrentRoute = () => {
		if (EARNING_RULES_ROUTE.isActive(location?.pathname)) goToRulesList()
	}

	const handleTracking = (value: string) => {
		const selectedOption = options.find((item) => item.value === value)

		AnalyticsService.events.selectInteraction({
			...userInfo,
			screen_name: 'Vendor',
			form_name: 'Vendor',
			selected_value: value,
			select_label: selectedOption?.label ?? '',
			select_name: 'Select Vendor',
		})
	}

	const { showModal: showVendorModal } = useConfirmationModal({
		confirmLabel: t('rules:MODAL_VENDOR.CONFIRM_LABEL'),
		cancelLabel: t('rules:MODAL_VENDOR.CANCEL_LABEL'),
		title: t('rules:MODAL_VENDOR.TITLE'),
		body: t('rules:MODAL_VENDOR.BODY'),
		confirmVariant: 'destructive',
	})

	const handleChange = async (value: string) => {
		if (isEditMode) {
			const confirmed = await showVendorModal()
			if (confirmed) {
				defineCurrentVendor(value)
				validateCurrentRoute()
				handleTracking(value)
			}
		} else {
			defineCurrentVendor(value)
			handleTracking(value)
		}
	}

	return options.length ? (
		<SmallSelect
			options={options}
			onChange={handleChange}
			value={currentVendor?.vendorId}
			disabled={!hasMoreElementsThan(options, 1)}
		/>
	) : null
}

export default SelectVendor
