import React from 'react'
import useEditMode from '@/routes/rules/hooks/useEditMode/useEditMode'
import { SettingsCardEarningRule } from '@/routes/rules/RulesRedesign/Components/SettingsCard/SettingsCardEarningRule'
import { SettingsCardViewEarningRule } from '@/routes/rules/RulesRedesign/Components/SettingsCard/SettingsCardViewEarningRule'
import { useHasPermissionRules } from '@/routes/rules/hooks/useHasPermissionRules/useHasPermissionRules'

export const Settings = () => {
	const { isEditMode } = useEditMode()
	const { hasCreateEarningRulesPermission } = useHasPermissionRules()

	if (isEditMode) {
		if (hasCreateEarningRulesPermission) return <SettingsCardEarningRule />

		return null
	}

	return <SettingsCardViewEarningRule />
}
