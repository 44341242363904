import { RedeemableItemStatus, RedeemableItemType } from '../enums/redeemable'

export type RedeemableItem = RedeemableItemV1 & {
	id: string
	vendorItemId: string
	vendorId: string
	type?: RedeemableItemType
	image?: string
	itemName: string
	status?: RedeemableItemStatus
	fullContainerDescription?: string
	fullPackageDescription?: string
	pricePerPoint?: number
	lastModified?: string
}

export type RedeemableItemV1 = {
	cppLimits?: {
		min: number
		max: number
	}
	currentTransaction?: {
		pricePerPoint?: number
		status?: CurrentTransactionStatusEnum
		transactionId?: string
		reason?: string
	}
}

export enum CurrentTransactionStatusEnum {
	PROCESSING = 'PROCESSING',
	REJECTED = 'REJECTED',
}
