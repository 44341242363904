import React from 'react'
import { useTranslation } from 'react-i18next'
import { Card, Paragraph } from '@hexa-ui/components'
import Rule from '@/domains/rule/Rule'
import EditRuleType from '@/domains/enums/EditRule'
import { useAuthorization, useFeatureToggleV2 } from '@/hooks'
import * as FeatureToggle from '@/utils/featureToggle'
import useStyles from './RuleDescriptionStyle'
import EditButton from '../../../Components/EditButton/EditButton'
import { createEarningRuleButtonEnabled } from '../../../utils/createEarningRuleButtonEnabled'

type RuleDescriptionProps = {
	name?: string
	amount?: string
	points?: number
	description?: string
	rule: Rule
}

const RuleDescriptionView: React.FC<RuleDescriptionProps> = ({
	name,
	amount,
	points,
	description,
	rule,
}: RuleDescriptionProps) => {
	const { t } = useTranslation()
	const { isFeatureAuthorized } = useAuthorization()
	const classes = useStyles()

	const isToggledCreateEarningRulesEnabled = useFeatureToggleV2(FeatureToggle.CREATE_EARNING_RULES)

	const createEarningRuleEnabled = createEarningRuleButtonEnabled({
		isFeatureAuthorized,
		isToggledCreateEarningRulesEnabled: isToggledCreateEarningRulesEnabled!,
	})

	return (
		<Card border="large" elevated="small" className={classes.card}>
			{createEarningRuleEnabled && <EditButton rule={rule} editType={EditRuleType.editRule} />}
			<div className={classes.title}>
				<p className={classes.text}>{t('rules:EDIT_SKU_PAGE.DESCRIPTION')}</p>
				<Paragraph className={classes.typography}>{description ?? name}</Paragraph>
			</div>

			<div className={classes.properties}>
				<div>
					<p className={classes.text}>{t('rules:EDIT_SKU_PAGE.AMOUNT')}</p>
					<Paragraph className={classes.typography}>{amount}</Paragraph>
				</div>

				<div>
					<p className={classes.text}>{t('rules:EDIT_SKU_PAGE.POINTS')}</p>
					<Paragraph className={classes.typography}>{points}</Paragraph>
				</div>
			</div>
		</Card>
	)
}

export default RuleDescriptionView
