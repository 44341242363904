import { ScreenName } from '@/domains'
import { useAnalyticsServiceEventPageViewed } from '@/hooks/analyticsService/useAnalyticsServiceEventPageViewed'
import { useAnalyticsServiceEventPaginationClicked } from '@/hooks/analyticsService/useAnalyticsServiceEventPaginationClicked'
import { useAnalyticsServiceEventTableItemsPerPageChanged } from '@/hooks/analyticsService/useAnalyticsServiceEventTableItemsPerPageChanged'
import { useAnalyticsServiceEventTableViewed } from '@/hooks/analyticsService/useAnalyticsServiceEventTableViewed'
import {
	$transactionStore,
	ILoad,
	transactionInitialState,
	TransactionStoreEvents,
} from '@/stores/transactionV2/TransactionStore'
import * as LoadTransactionsUseCase from '@/usecase/transactionV2/LoadTransactionsUseCase'
import { ILoadRedeemableItemsUseCaseParams } from '@/usecase/redeemableItems/LoadRedeemableItemsUseCase'
import { useStore } from 'effector-react'
import { useCallback, useEffect, useState } from 'react'

export type useTransactionLogListResult = {
	isLoading: boolean
	hasError: boolean
	data: ILoad[]
	onPaginationChanged: (page: number, pageSize: number) => void
}

export const defaultSortby = 'status'
export const defaultSortingOrder = 'DESC'

export const useTransactionLogList = (): useTransactionLogListResult => {
	const { content: contentData, reloadPageAtTime } = useStore($transactionStore)

	const { sendAnalyticsServiceEventPageViewed } = useAnalyticsServiceEventPageViewed()
	const { sendAnalyticsServiceTableViewed } = useAnalyticsServiceEventTableViewed()
	const { sendAnalyticsServiceTableItemsPerPageChanged } = useAnalyticsServiceEventTableItemsPerPageChanged()
	const { sendAnalyticsServicePaginationClicked } = useAnalyticsServiceEventPaginationClicked()

	const [isLoading, setIsLoading] = useState(true)
	const [hasError, setHasError] = useState(false)

	useEffect(() => {
		sendAnalyticsServiceEventPageViewed(ScreenName.PointsManagement)
	}, [sendAnalyticsServiceEventPageViewed])

	const loadItems = useCallback(async () => {
		const { pagination: storePagination } = $transactionStore.getState()

		try {
			setIsLoading(true)
			setHasError(false)

			const params: ILoadRedeemableItemsUseCaseParams = {
				page: storePagination.page,
				pageSize: storePagination.pageSize,
			}

			const { content = [], pagination: resPagination } = await LoadTransactionsUseCase.execute({
				...params,
			})

			sendAnalyticsServiceTableViewed({
				itemsPerPage: String(resPagination.pageSize),
				itemsTotal: String(resPagination.totalElements),
				pageNumber: `${resPagination.page + 1}`,
				screenName: ScreenName.PointsManagement,
				tableName: 'Points management',
			})

			TransactionStoreEvents.setContent(content)
			TransactionStoreEvents.setPagination(resPagination)
		} catch (error) {
			setHasError(true)

			TransactionStoreEvents.setContent([])
			TransactionStoreEvents.setPagination(transactionInitialState.pagination)
		} finally {
			setIsLoading(false)
		}
	}, [sendAnalyticsServiceTableViewed])

	const onPaginationChanged = useCallback(
		(page: number, pageSize: number) => {
			// The initial page on the View (<Table> component) is 1 and on the ms it's 0 based.
			const validPage = Math.max(0, page - 1)
			TransactionStoreEvents.setPagination({ page: validPage, pageSize })
			TransactionStoreEvents.reloadPage()
			sendAnalyticsServicePaginationClicked({
				interactionType: 'Page changed',
				pageNumber: page.toString(),
				screenName: 'Points management',
			})
			sendAnalyticsServiceTableItemsPerPageChanged({
				itemsPerPage: pageSize.toString(),
				itemsTotal: String(contentData.length),
				pageNumber: page.toString(),
				screenName: ScreenName.PointsManagement,
				tableName: 'Points management',
			})
		},
		[contentData, sendAnalyticsServicePaginationClicked, sendAnalyticsServiceTableItemsPerPageChanged],
	)

	useEffect(() => {
		TransactionStoreEvents.setPagination(transactionInitialState.pagination)
		return () => {
			TransactionStoreEvents.reset()
		}
	}, [])

	useEffect(() => {
		loadItems()
	}, [loadItems, reloadPageAtTime])

	return {
		isLoading,
		hasError,
		data: contentData,
		onPaginationChanged,
	}
}
