import React from 'react'
import { Heading } from '@hexa-ui/components'
import { useTranslation } from 'react-i18next'
import { CardAudienceExclusionViewData } from './AudienceExclusionViewData.styles'
import { AudienceCard } from './components/AudienceCard/AudienceCard'

export const AudienceExclusionViewData = () => {
	const { t } = useTranslation()
	return (
		<CardAudienceExclusionViewData border="medium" elevated="small" data-testid="exclusion-rule-page-audience">
			<Heading id="audience-title" size="H3">
				{t('rules:CREATE_EXCLUSION_RULES.AUDIENCE_CARD.TITLE')}
			</Heading>
			<AudienceCard />
		</CardAudienceExclusionViewData>
	)
}
