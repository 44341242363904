import { Button } from '@hexa-ui/components'
import { IconProps } from '@hexa-ui/icons'
import React, { FunctionComponent, useCallback, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useConfirmationModal from '@/hooks/useConfirmationModal/useConfirmationModal'
import * as Styles from './ActionButtonsWrapper.styles'

export type ButtonVariant = 'primary' | 'secondary' | 'destructive' | undefined

export interface ActionButtonsWrapperProps {
	onActionSaveAndExit?: () => void
	onActionCancel?: () => void
	onActionSubmit?: () => void
	buttonLabelActionSaveAndExit?: string
	buttonLabelActionCancel?: string
	buttonLabelActionSubmit?: string
	buttonVariantActionSaveAndExit?: ButtonVariant
	buttonVariantActionCancel?: ButtonVariant
	buttonVariantActionSubmit?: ButtonVariant
	icon?: FunctionComponent<IconProps>
	isSubmitButtonDisabled?: boolean
	isCancelButtonDisabled?: boolean
	isSaveAndExitButtonDisabled?: boolean
	iconLeadingPosition?: boolean
	showLeaveConfirmationModal?: boolean
	isGoBackButtonDisabled?: boolean
}

const QUERY_NAME = 'origin'

const ActionButtonsWrapper: React.FC<ActionButtonsWrapperProps> = ({
	onActionSaveAndExit,
	onActionCancel,
	onActionSubmit,
	buttonLabelActionSaveAndExit,
	buttonLabelActionCancel,
	buttonLabelActionSubmit,
	buttonVariantActionSaveAndExit,
	buttonVariantActionCancel,
	buttonVariantActionSubmit,
	icon,
	isSubmitButtonDisabled,
	isCancelButtonDisabled,
	isSaveAndExitButtonDisabled,
	iconLeadingPosition,
	showLeaveConfirmationModal,
	isGoBackButtonDisabled,
}) => {
	const { t } = useTranslation()
	const history = useHistory()
	const location = useLocation()

	const { showModal: showLeaveModal } = useConfirmationModal({
		confirmLabel: t('rules:MODAL.CONFIRM_LABEL'),
		cancelLabel: t('rules:MODAL.CANCEL_LABEL'),
		title: t('rules:MODAL.TITLE'),
		body: t('rules:MODAL.BODY'),
		confirmVariant: 'destructive',
	})

	const handleRedirect = useCallback(
		async (event) => {
			event.preventDefault()
			const { currentTarget } = event
			const href = currentTarget.getAttribute('href')
			const canRedirect = await showLeaveModal()
			const value = new URLSearchParams(location.search).get(QUERY_NAME)
			if (canRedirect) {
				let url = href
				if (value) {
					url += `?${QUERY_NAME}=${encodeURIComponent(value)}`
				}
				history.push(url, {
					state: {
						mfeReferrer: document.URL,
					},
				})
			}
		},
		[location.search, history, showLeaveModal],
	)

	useEffect(() => {
		const linkHome = document.querySelector('a[aria-label="link to home"]')
		const breadcrumbLinks = document.querySelectorAll('nav a[href]')
		if (showLeaveConfirmationModal) {
			linkHome?.addEventListener('click', handleRedirect)
			breadcrumbLinks.forEach((link) => link.addEventListener('click', handleRedirect))

			return () => {
				linkHome?.removeEventListener('click', handleRedirect)
				breadcrumbLinks.forEach((link) => link.removeEventListener('click', handleRedirect))
			}
		}
		return undefined
	}, [handleRedirect, showLeaveConfirmationModal])

	const handleCancel = useCallback(async () => {
		if (showLeaveConfirmationModal) {
			const canCancel = await showLeaveModal()
			if (canCancel && onActionCancel) {
				onActionCancel()
			}
		} else {
			onActionCancel?.()
		}
	}, [showLeaveConfirmationModal, showLeaveModal, onActionCancel])

	return (
		<Styles.ButtonsContainer>
			{onActionSaveAndExit && (
				<Button
					data-testid="save-and-exit-actions-button"
					variant={buttonVariantActionSaveAndExit}
					disabled={isSaveAndExitButtonDisabled}
					onClick={onActionSaveAndExit}
				>
					{buttonLabelActionSaveAndExit}
				</Button>
			)}

			<Styles.Wrapper>
				{onActionCancel && isGoBackButtonDisabled && (
					<Button
						data-testid="cancel-actions-button"
						variant={buttonVariantActionCancel}
						disabled={isCancelButtonDisabled}
						onClick={handleCancel}
					>
						{buttonLabelActionCancel}
					</Button>
				)}
				{onActionSubmit && (
					<Button
						data-testid="submit-actions-button"
						variant={buttonVariantActionSubmit}
						onClick={onActionSubmit}
						icon={icon}
						disabled={isSubmitButtonDisabled}
						leading={iconLeadingPosition}
					>
						{buttonLabelActionSubmit}
					</Button>
				)}
			</Styles.Wrapper>
		</Styles.ButtonsContainer>
	)
}

export default ActionButtonsWrapper
