import React, { useEffect } from 'react'
import { Table } from '@hexa-ui/components'
import { useFormContext } from 'react-hook-form'
import { SkuRuleItem } from '@/services/rules/RulesService.types'
import { SkuListTableProps } from './SkuListTableV2.types'
import { SkuModal } from '../SkuModal/SkuModal'
import { useTableHandlers } from './hooks/useTableHandlers/useTableHandlers'
import { ToolbarExtra } from './Components/ToolbarExtra/ToolbarExtra'
import { useModal } from './hooks/useModal/useModal'
import { CreateEarningRuleFormData } from '../../Pages/CreateEarningRule/context/CreateEarningRuleProvider/CreateEarningRuleProvider.types'

const SkuListTableV2: React.FC<SkuListTableProps> = (props) => {
	const { addSkuHandler, tableProps } = useTableHandlers({ ...props })
	const { isModalOpened, closeModalHandler, openModalHandler } = useModal()
	const {
		setValue,
		control: { defaultValuesRef, getIsDirty },
	} = useFormContext<CreateEarningRuleFormData>()
	if (props?.isEditMode && props?.hasToolbarButton) {
		tableProps.toolbarExtra = () => <ToolbarExtra handleButton={openModalHandler} />
	}

	useEffect(() => {
		if (!props?.isEditMode && getIsDirty()) {
			const { items, amountSpent, description, points } = defaultValuesRef.current

			setValue('items', items as SkuRuleItem[])
			setValue('points', points)
			setValue('amountSpent', amountSpent)
			setValue('description', description)
		}
	}, [props?.isEditMode, defaultValuesRef, getIsDirty, setValue])

	return (
		<>
			<SkuModal isModalOpened={isModalOpened} closeModal={closeModalHandler} onAddValues={addSkuHandler} />
			<Table {...tableProps} />
		</>
	)
}

SkuListTableV2.displayName = 'SkuListTableV2'

export default SkuListTableV2
